import { useEffect, useState } from 'react';
import type { AddressProps } from '~/models';
import { useAppSelector } from '~/Modules';
import { selectHospitalCoords } from '~/Modules/user';
import { getMapboxAddressOptions, type MapboxFeature } from '~/services/mapbox.service';

type AddressOption = {
  label: string;
  value: AddressProps;
};

function genAddressMapboxOptions(
  mapboxAutoCompleteData: MapboxFeature[],
  config: MapboxSearchConfig
): AddressOption[] {
  const options: AddressOption[] = [];

  for (const feature of mapboxAutoCompleteData) {
    if (feature.relevance < config.relevance) continue;

    if (config.accuracy === 'point' && feature.properties?.accuracy === 'street') {
      continue;
    }

    /** If we get an address with a prefix for a business name then
     * we remove it so that we can run the address through the
     * AddressParser on the backend.
     *
     * @example
     * "CVS Pharmacy at Target, 7100 Santa Monica Blvd, West Hollywood,
     *  California 90038, United States"
     *
     *  => "7100 Santa Monica Blvd, West Hollywood, California 90038, United States"
     */
    const value =
      feature.place_name.indexOf(`${feature.text}, `) === 0
        ? feature.place_name.substring(feature.text.length + 2)
        : feature.place_name;

    options.push({
      label: feature.place_name,
      value: {
        value,
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0],
        type: 'mapbox'
      }
    });
  }

  return options;
}

export type MapboxSearchHook = [
  // Indicates if the search is currently in progress.
  boolean,
  // Array of mapbox address options for the dropdown.
  AddressOption[],
  // Event handler for mapbox input search.
  (inputId: number | undefined, search: string) => void
];

export type MapboxSearchConfig = {
  /** The result relevance filter to apply to the search. Should be a number between 0 and 1.
   * @default 0.75 */
  relevance: number;
  /** The accuracy filter to apply to the search.
   * @default 'point' */
  accuracy: 'point' | 'street';
};

/** A webhook to add mapbox search functionality into a component. Accepts a
 * default address option and returns the search status, options, and search handler.
 *
 * The search status can be used to indicate if we are actively querying and awaiting
 * results for mapbox.
 *
 * The options and search handler are the current set of results and the callback
 * to use when performing a new query. There is no default debounce functionality
 * added to the callback so be sure to include it in your component. */
export default function useMapboxSearch(
  defaultOptions: AddressOption[] = [],
  config: Partial<MapboxSearchConfig> = {}
): MapboxSearchHook {
  const [isSearching, setIsSearching] = useState(false);
  const [options, setOptions] = useState<AddressOption[]>(defaultOptions);
  const hospitalCoords = useAppSelector(state => selectHospitalCoords(state));

  config.relevance ??= 0.75;
  config.accuracy ??= 'point';

  /** Event handler for mapbox input search. */
  function handleMapboxInput(_, search: string) {
    // Minimum of 3 character search
    if (search.length < 3) return;

    setIsSearching(true);

    return getMapboxAddressOptions(search, hospitalCoords)
      .then(
        ({ features }) =>
          features &&
          setOptions(genAddressMapboxOptions(features, config as MapboxSearchConfig))
      )
      .then(() => setIsSearching(false));
  }

  useEffect(() => setOptions(defaultOptions), [defaultOptions]);

  return [isSearching, options, handleMapboxInput] as const;
}
