import React from 'react';
import { Card, TOKENS, Link } from '@SRHealth/frontend-lib';
import type { ProviderPublicModel } from '~/models';
import { publicCardFactory } from '~/models';
import usePublicCard from '~/Pages/RideBooking/Hooks/usePublic';
import PublicCard from './PublicCard';

type PublicTransportsProps = {
  provider: ProviderPublicModel;
};

export default function PublicTransports({ provider }: PublicTransportsProps) {
  const [cardNameOptions, quantityOptions] = usePublicCard();

  const handleCardAdd = () =>
    (provider.cards = provider.cards.concat(publicCardFactory()));

  const handleCardDelete = index => {
    const newCards = provider.cards.toSpliced(index, 1);
    provider.cards = Array.from(newCards);
  };

  return (
    <Card label="Transport - Public Transportation Card" border={false}>
      <div className="flex flex-col gap-[16px]">
        <div
          className={`reset-div border-1 rounded-[4px] shadow-b-wrap border-${TOKENS.BORDER.NEUTRAL_LT}`}
          style={{ padding: '8px 24px' }}
        >
          <div
            className={`flex flex-row nowrap gap-[4px] items-center h-[32px] py-[4px] font-bold`}
          >
            Add Preferred Transit Cards
          </div>
        </div>
        <div
          className={
            `reset-div px-[4px] py-[16px] border-1 rounded-[4px] shadow-b-wrap ` +
            `border-${TOKENS.BORDER.NEUTRAL_LT}`
          }
        >
          <div
            className="reset-div px-[24px] py-[8px]"
            style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
          >
            {provider.cards.map((model, idx) => (
              <PublicCard
                key={idx}
                provider={provider}
                cardModel={model}
                nameOptions={cardNameOptions}
                quantityOptions={quantityOptions}
                onCardDelete={
                  provider.cards.length > 1 ? () => handleCardDelete(idx) : undefined
                }
              />
            ))}
            {provider.cards.length < 10 && (
              <Link
                label="Add Card"
                iconLeft="AddCircleOutline"
                size="sm"
                onClick={handleCardAdd}
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
