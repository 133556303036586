import React, { useState } from 'react';
import { Icon, Input, Link, TOKENS, isEmpty, isString } from '@SRHealth/frontend-lib';
import type { MemberProfileStore } from '~/types';
import { useAppDispatch } from '~/Modules';
import { updateInternalNotesThunk } from '~/Modules/memberProfile/thunks';

interface NotesProps {
  internalNotes: MemberProfileStore['formData']['personalInfo']['internalNotes'];
}

const Notes = ({ internalNotes }: NotesProps) => {
  const [editMode, setEditMode] = useState(false);
  const [notes, setNotes] = useState(internalNotes || '');
  const [hasError, setError] = useState(false);
  const dispatch = useAppDispatch();

  const toggleEditMode = () => setEditMode(prev => !prev);

  const handleNotesUpdate = async () => {
    if (!editMode) {
      toggleEditMode();
      return;
    }

    await dispatch(updateInternalNotesThunk(notes))
      .unwrap()
      .then(response => {
        if (response.passengerId) {
          setError(false);
          toggleEditMode();
        }
      })
      .catch(_ => setError(true));
  };

  return (
    <div
      className={
        `flex flex-col w-[260px] h-full border-l-1 border-solid ` +
        `border-${TOKENS.BORDER.NEUTRAL_MD} bg-${TOKENS.SURFACE.PRIMARY.XLT}`
      }
    >
      <div
        className={
          `heading-lg p-[16px] pb-[12px] text-center text-${TOKENS.FONT.INVERSE} ` +
          `bg-${TOKENS.SURFACE.PRIMARY.XDK} `
        }
      >
        Notes
      </div>
      <div
        className={
          `flex flex-col justify-center items-center ` +
          `px-[16px] py-[12px] text-center shadow-b`
        }
        style={{ marginBottom: '1px' }}
      >
        <Icon
          type="Passenger"
          className="self-center"
          style={{ width: '24px', height: '24px' }}
        />
        <div className={`heading-lg text-${TOKENS.FONT.PRIMARY}`}>Member Note</div>
        <div className={`heading-xsm text-${TOKENS.FONT.PRIMARY}`}>Internal</div>
        <span className={`heading-xsm text-${TOKENS.FONT.ALERT}`}>
          {hasError ? 'Unable to update notes' : ''}
        </span>
      </div>

      <Input
        inputId={0}
        name="test"
        onChange={(_, val) => {
          if (!isEmpty(val) && isString(val)) {
            setNotes(val);
          }
        }}
        placeholder={notes}
        resize={false}
        autoResize={true}
        rounded={false}
        error={hasError}
        type="textarea"
        disabled={!editMode}
        value={notes}
        required
      />

      <div className={`justify-end`}>
        <Link
          href="#"
          label={!editMode ? `Edit` : `Update`}
          onClick={handleNotesUpdate}
        />
      </div>
    </div>
  );
};

export default Notes;
