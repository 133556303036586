import { addDays, isPast } from 'date-fns';
import { find } from 'lodash-es';
import type { Binary } from '~/types';
import type { CyclicRideBenefit } from '~/Modules/memberProfile';
import { isNil, isNumber } from '@SRHealth/frontend-lib';

export const getDropDownFormattedVehicleTypes = vehicle_types =>
  vehicle_types.map(({ modelName: name, vehicle_sub_types, id, comments: tooltip }) => ({
    name,
    vehicle_sub_types,
    id,
    tooltip
  }));

export const showSoftBlockAlert = (rideBenefit = []) => {
  if (!rideBenefit.length) return [];

  // show the soft block only when it is at it's Alert level or closer, also when it is not past it (in case of hard block)
  return rideBenefit.reduce(
    (
      warnings: string[],
      { rideUsage, alertAfter, hardBlock, rideUsageText, rideLimit, memberRideBenefit }
    ) => {
      if (
        (rideUsage >= alertAfter && !hardBlock) ||
        (rideUsage >= alertAfter && rideUsage < rideLimit && hardBlock)
      ) {
        warnings.push(`${rideUsageText} of ${memberRideBenefit} used to date`);
      }

      return warnings;
    },
    []
  );
};

export const showHardBlockAlert = (rideBenefit = []) => {
  // show this of it IS only a hard block and the benefit limit is reached.
  if (!rideBenefit.length) return [];

  return rideBenefit.reduce(
    (
      blocks: string[],
      { rideUsage, hardBlock, rideUsageText, rideLimit, memberRideBenefit }
    ) => {
      if (rideUsage >= rideLimit && hardBlock) {
        blocks.push(`${rideUsageText} of ${memberRideBenefit} used to date`);
        return blocks;
      }

      return blocks;
    },
    []
  );
};

export const vehicleSubTypeRelationships = (
  vehicleTypes,
  vehicleTypeId,
  vehicleSubTypeId
) => {
  const selectedVehicleType = find(vehicleTypes, { id: vehicleTypeId });
  const relevantVehicleSubTypes = selectedVehicleType?.vehicle_sub_types ?? [];
  const selectedVehicleTypeName = selectedVehicleType?.modelName ?? '';
  const selectedVehicleSubType = find(relevantVehicleSubTypes, { id: vehicleSubTypeId });
  const selectedVehicleSubTypeName = selectedVehicleSubType?.name ?? '';

  return {
    selectedVehicleType,
    relevantVehicleSubTypes,
    selectedVehicleTypeName,
    selectedVehicleSubType,
    selectedVehicleSubTypeName
  };
};

/**
 * Determines if the member is eligible for a benefit based on the end date. If the eligible
 * end date is less than 24 hours in the past, then they are considered still eligible.
 *
 * Users with isEliglible = false will always return false.
 *
 * This logic should really be controlled on the BE but this at least lets us keep it
 * consistent in Care.
 * @param isEligible
 * @param eligibleEndDate
 * @returns
 */
export const isMemberEligible = (
  isEligible: Binary,
  eligibleEndDate: null | string | number | Date
): boolean => {
  if (eligibleEndDate === null) return !!isEligible;

  if (!isEligible) return false;

  const _eliglibleEndDate =
    eligibleEndDate instanceof Date ? eligibleEndDate : new Date(eligibleEndDate);

  return isEligible && !isPast(addDays(_eliglibleEndDate, 1));
};

/**
 * Determines if a ride benefit has reached it's max limit. Does
 * not account for cost/year benefits. These will always return false.
 */
export const benefitIsMaxed = (benefit: CyclicRideBenefit): boolean => {
  if (isNil(benefit.rideUsage) || isNil(benefit.rideLimit)) return false;

  if (!isNumber(benefit.rideLimit) || !isNumber(benefit.rideUsage)) return false;

  return benefit.rideUsage >= benefit.rideLimit;
};
