import { createSelector } from '@reduxjs/toolkit';
import { Datetime } from '@SRHealth/frontend-lib';
import type { AppStore } from '~/types';

const selectDate = (s: AppStore) => s.rideBooking.date.date;
const selectDateRestrictions = (s: AppStore) => s.rideBooking.meta.dateRestrictions;

/**
 * Selects the date bounds for the recurring rides section. If the ride date is
 * in the past, the latest date will be yesterday. Otherwise, the latest date
 * will be the latest date in the date restrictions.
 */
export const selectRecurringDateBounds = createSelector(
  [selectDate, selectDateRestrictions],
  (date, dateRestrictions): [DateString, DateString] => {
    if (
      Datetime.isPastDay(
        new Datetime(date),
        new Datetime(undefined, Datetime.runtimeTimezone)
      )
    ) {
      return [date!, new Datetime().subtractDays(1).toDateString()];
    }

    return [date!, dateRestrictions!.latestDate];
  }
);
