import React, { useMemo } from 'react';
import { Alert } from '@SRHealth/frontend-lib';
import { useAppSelector } from '~/Modules';
import { formatUsedUpBenefitPeriodsAlertText } from './UsedUpBenefitPeriodsAlert.utils';
import { selectUsedUpBenefitPeriods, TXT } from '~/Modules/rideBooking';

export default function UsedUpBenefitPeriodsAlert() {
  const dateRestrictions = useAppSelector(
    state => state.rideBooking.meta.dateRestrictions
  );

  const { usedUpBenefitPeriods } = useAppSelector(selectUsedUpBenefitPeriods);

  const usedUpBenefitPeriodsAlertText = useMemo(() => {
    if (
      !usedUpBenefitPeriods?.size ||
      !dateRestrictions?.earliestDate ||
      !dateRestrictions?.latestDate
    ) {
      return '';
    }

    return formatUsedUpBenefitPeriodsAlertText(
      usedUpBenefitPeriods,
      dateRestrictions.earliestDate,
      dateRestrictions.latestDate
    );
  }, [
    usedUpBenefitPeriods,
    dateRestrictions?.earliestDate,
    dateRestrictions?.latestDate
  ]);

  if (!usedUpBenefitPeriodsAlertText) {
    return null;
  }

  return (
    <div className="pb-[12px]">
      <Alert
        type="info"
        label={`${TXT.BENEFIT_PERIOD_LIMIT_HIT} ${usedUpBenefitPeriodsAlertText}.`}
      />
    </div>
  );
}
