import React from 'react';
import { Card, TOKENS } from '@SRHealth/frontend-lib';
import NemtTransport from './NemtTransport';
import {
  providerNemtFactory,
  providerRideshareFactory,
  type ProviderNemtModel,
  type ProviderRideshareModel
} from '~/models';
import { useAppDispatch, useAppSelector } from '~/Modules';
import { setTransportProvider } from '~/Modules/rideBooking';
import ProviderHeader from './ProviderHeader';
import RideshareTransport from './RideshareTransport';

type PrivateTransportsProps = {
  provider: ProviderNemtModel | ProviderRideshareModel;
};

export default function PrivateTransports({ provider }: PrivateTransportsProps) {
  const dispatch = useAppDispatch();
  const supply = useAppSelector(s => s.rideBooking.meta.transportProviders);

  return (
    <Card label="Transport - Private NEMT" border={false}>
      <div className="flex flex-col gap-[16px]">
        <ProviderHeader
          providerName={provider.name}
          hasError={Object.keys(provider.ruleErrors).length > 0}
        />
        <div
          className={
            `reset-div px-[4px] py-[16px] border-1 rounded-[4px] shadow-b-wrap ` +
            `border-${TOKENS.BORDER.NEUTRAL_LT}`
          }
          style={{ overflowY: 'auto', maxHeight: '420px', scrollbarWidth: 'thin' }}
        >
          <div
            className="reset-div px-[24px] py-[8px]"
            style={{
              display: 'grid',
              gridTemplateRows: '1fr',
              gridTemplateColumns: 'repeat(auto-fill, 268px)',
              rowGap: '28px',
              columnGap: '24px'
            }}
          >
            {supply.map((opt, idx) => {
              return 'ratings' in opt ? (
                <NemtTransport
                  key={idx}
                  {...opt}
                  isActive={provider?.id === opt.id}
                  onClick={() => dispatch(setTransportProvider(providerNemtFactory(opt)))}
                />
              ) : (
                <RideshareTransport
                  key={idx}
                  {...opt}
                  isActive={provider.id === opt.id}
                  onClick={() =>
                    dispatch(setTransportProvider(providerRideshareFactory(opt)))
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}
