import { isNumber, type DateString } from '@SRHealth/frontend-lib';
import type {
  BenefitRemaining,
  BenefitsUsageAndLimits,
  ProjectedUsage,
  RideBookingSection,
  RideBookingStore,
  UsageAndLimit
} from './RideBooking.types';
import { RIDE_SECTION_ORDER } from './RideBooking.constants';

/**
 * Determine if the date selected is in the past.
 * @param date
 * @returns
 */
export function isPastBooking(date: RideBookingStore['date']['date']): boolean {
  if (!date) return false;

  const dateArr = date.split('-');

  if (Number(date[0]) > new Date().getFullYear()) return false;

  if (Number(dateArr[1]) > new Date().getMonth()) return false;

  return (
    Number(date[0]) <= new Date().getFullYear() &&
    Number(dateArr[1]) <= new Date().getMonth() + 1 &&
    Number(dateArr[2]) < new Date().getDate()
  );
}

/** Move on to the next section in the RBF, relative to current active section. */
export function toNextSection(meta: RideBookingStore['meta'], skip = 0) {
  meta.previousActiveSection = meta.activeSection;
  meta.activeSection = meta.activeSection + (1 + skip);
}

/** Change the RBF to a specific section. */
export function toSection(meta: RideBookingStore['meta'], section: RideBookingSection) {
  if (meta.activeSection === RIDE_SECTION_ORDER[section]) return;

  meta.previousActiveSection = meta.activeSection;
  meta.activeSection = RIDE_SECTION_ORDER[section];
}

/** Utility to attempt to scroll a section of the RBF into view. */
export function scrollToSection(section: RideBookingSection) {
  return document.querySelector(`[data-testid="section-${section}"]`)?.scrollIntoView();
}

/** Default behavior to execute whenever completing a section of the RBF. */
export function completeSection(section: RideBookingSection, skip?: number) {
  return (state: RideBookingStore) => {
    toNextSection(state.meta, skip);
    scrollToSection(section);
  };
}

/**
 * Get the benefits that have less than the threshold number of remaining rides
 */
export function getBenefitsUnderRemainingThreshold(
  /** Get the benefits that have less than this number of remaining rides */
  remainingThreshold = 0,
  proposedNewRideDates: DateString[],
  benefitsUsageAndLimits: BenefitsUsageAndLimits,
  /** Benefit category IDs of the active treatment. Oftentimes, there won't be any. */
  activeBenefitCategoryIds: number[] = [],
  countOnlyNewUsageIfAlreadyExceeded = false,
  newRidesAreRoundTrips = false
): BenefitRemaining[] {
  // 1. Create maps to track projected usage
  const projectedUsage: ProjectedUsage = {
    all: {
      ridesPerYear: { ...benefitsUsageAndLimits.all.ridesPerYear.usage },
      ridesPerMonth: { ...benefitsUsageAndLimits.all.ridesPerMonth.usage }
    }
  };

  // Initialize benefit category usage if applicable
  for (const categoryId of activeBenefitCategoryIds) {
    const key = `benefit-category-${categoryId}` as const;
    projectedUsage[key] = {
      ridesPerYear: { ...benefitsUsageAndLimits[key]?.ridesPerYear.usage }
    };
  }

  // 2. Add the proposed new rides (if any) to the projected usage
  proposedNewRideDates.forEach(date => {
    const [year, month] = date.split('-');
    const monthKey = `${year}-${month}`;

    // Update all rides usage
    projectedUsage.all.ridesPerYear[year] = incrementUsageCount(
      projectedUsage.all.ridesPerYear,
      year,
      newRidesAreRoundTrips
    );
    projectedUsage.all.ridesPerMonth[monthKey] = incrementUsageCount(
      projectedUsage.all.ridesPerMonth,
      monthKey,
      newRidesAreRoundTrips
    );

    // Update benefit category usage
    for (const categoryId of activeBenefitCategoryIds) {
      const key = `benefit-category-${categoryId}` as const;
      projectedUsage[key].ridesPerYear[year] = incrementUsageCount(
        projectedUsage[key].ridesPerYear,
        year,
        newRidesAreRoundTrips
      );
    }
  });

  // 3. Get the benefits that are projected to have less than the threshold number of remaining rides
  let benefitsUnderThreshold: BenefitRemaining[] = [];

  // Check yearly limits
  benefitsUnderThreshold = addBenefitsUnderThreshold(
    benefitsUnderThreshold,
    projectedUsage.all.ridesPerYear,
    benefitsUsageAndLimits.all.ridesPerYear,
    remainingThreshold,
    countOnlyNewUsageIfAlreadyExceeded,
    'all.ridesPerYear'
  );

  // Check monthly limits
  benefitsUnderThreshold = addBenefitsUnderThreshold(
    benefitsUnderThreshold,
    projectedUsage.all.ridesPerMonth,
    benefitsUsageAndLimits.all.ridesPerMonth,
    remainingThreshold,
    countOnlyNewUsageIfAlreadyExceeded,
    'all.ridesPerMonth'
  );

  // Check benefit category limits
  for (const benefitCategoryId of activeBenefitCategoryIds) {
    const categoryKey = `benefit-category-${benefitCategoryId}` as const;

    benefitsUnderThreshold = addBenefitsUnderThreshold(
      benefitsUnderThreshold,
      projectedUsage[categoryKey].ridesPerYear,
      benefitsUsageAndLimits[categoryKey]?.ridesPerYear,
      remainingThreshold,
      countOnlyNewUsageIfAlreadyExceeded,
      `${categoryKey}.ridesPerYear`,
      benefitCategoryId
    );
  }

  return benefitsUnderThreshold;
}

/** Helper function to check limits and add benefits that are projected to have
 * less than the threshold number of remaining rides */
const addBenefitsUnderThreshold = (
  benefitsUnderThreshold: BenefitRemaining[],
  usageData: Record<string, number>,
  benefitsUsageAndLimit: UsageAndLimit,
  remainingThreshold = 0,
  countOnlyNewUsageIfAlreadyExceeded = false,
  benefit:
    | 'all.ridesPerYear'
    | 'all.ridesPerMonth'
    | `benefit-category-${number}.ridesPerYear`,
  benefitCategoryId?: number
): BenefitRemaining[] => {
  const limit = benefitsUsageAndLimit?.limit;
  // if no limit, then we don't need to check this benefit
  if (!isNumber(limit)) return benefitsUnderThreshold;

  Object.entries(usageData).forEach(([usagePeriod, projectedUsage]) => {
    const currentUsage = benefitsUsageAndLimit.usage[usagePeriod] || 0;

    let remaining = 0;
    const currentRemaining = limit - currentUsage;
    const projectedRemaining = limit - projectedUsage;

    // On recurring rides section, we don't want to be displaying limits they already
    // exceeded before they selected new dates in this booking.  Only nudge them for the rides they
    // can remove from this booking (e.g. while soft blocked)
    if (countOnlyNewUsageIfAlreadyExceeded && currentRemaining <= remainingThreshold) {
      // Get the number of rides they can actually remove
      remaining = projectedRemaining - currentRemaining;
    } else {
      // otherwise, get the projected remaining rides (including any new rides we're booking)
      remaining = projectedRemaining;
    }

    if (remaining < remainingThreshold) {
      const [year, month] = usagePeriod.split('-');

      benefitsUnderThreshold.push({
        remaining,
        year,
        month,
        isHardBlock: benefitsUsageAndLimit.isHardBlock,
        benefitCategoryId,
        benefit
      });
    }
  });

  return benefitsUnderThreshold;
};

/**
 * Increment the usage count for a benefit
 * @param usageMap - The map of usage counts
 * @param key - The key to increment
 * @param newRidesAreRoundTrips - Whether the new rides are round trips
 * @returns The new usage count
 */
const incrementUsageCount = (
  usageMap: Record<string, number>,
  key: string,
  newRidesAreRoundTrips = false
): number => {
  return (usageMap[key] || 0) + (newRidesAreRoundTrips ? 2 : 1);
};

/**
 * Find the benefit remaining that's most pressing/relevant to the user
 * (i.e. hard block limits take precedence over soft block limits,
 * lower remaining rides take precedence over higher remaining rides, etc)
 */
export function findMostRelevantBenefitRemaining(
  benefits: BenefitRemaining[],
  /** Optional param to filter the benefits by the date's year and month */
  date?: DateString
): BenefitRemaining | undefined {
  if (!benefits.length) return undefined;

  const sorted = benefits.sort((a, b) => {
    // Hard block should be considered more pressing
    if (a.isHardBlock !== b.isHardBlock) {
      return a.isHardBlock ? -1 : 1;
    }

    // If both are equally hard or soft,
    // then the one with fewer remaining rides is more pressing
    return a.remaining - b.remaining;
  });

  let finalArr = sorted;

  // If there's a date, then we want to make sure the benefit is for the date's year and month
  if (date) {
    const [year, month] = date.split('-');
    finalArr = sorted.filter(
      benefit => benefit.year === year && (benefit.month ? benefit.month === month : true)
    );
  }

  return finalArr[0];
}
