/** Contains the standardized messaging specific to the Funding Source section. */
export const FUNDING_SOURCE_TXT = Object.freeze({
  /** Messaging for health sub plans that are improperly configured to use treatments but
   * which don't have any. */
  SUB_PLAN_MISSING_TREATMENTS: `It looks like this health sub plan doesn't have any treatments enabled. Please 
    contact your administrator for more information.`,
  /** Base message for soft block warning */
  BENEFIT_SOFT_BLOCK_WARNING_BASE: `Please be advised that the member has used all their benefits`,

  /** Treatment-specific addition for soft block warning */
  BENEFIT_SOFT_BLOCK_WARNING_TREATMENT: ` for this treatment before proceeding.`,

  /** Base message for hard block warning */
  BENEFIT_HARD_BLOCK_WARNING_BASE: `This member has used all their benefits`,

  /** Treatment-specific addition for hard block warning */
  BENEFIT_HARD_BLOCK_WARNING_TREATMENT: ` for this treatment. If applicable, please choose another treatment type.`,
  /** Messaging for passengers that are currently ineligible but can
   * book a past ride. */
  BENEFIT_PAST_BLOCK_WARNING: `This member is no longer eligible for this treatment but may still
     schedule a ride for a past date.`
} as const);
