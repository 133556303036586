import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import { convertISOToDisplayDate } from '~/utilities/timesAndDates';

const selectEligibileStartDate = (state: AppStore) =>
  state.memberProfile.formData.benefits.eligibleStartDate;
const selectEligibleEndDate = (state: AppStore) =>
  state.memberProfile.formData.benefits.eligibleEndDate;

export const selectEligibilityDates = createSelector(
  [selectEligibileStartDate, selectEligibleEndDate],
  (startDate, endDate) => {
    const _startDate = startDate?.split(' ')?.[0] as DateString | undefined;
    const _endDate = endDate?.split(' ')?.[0] as DateString | undefined;

    return {
      startDate: _startDate ? convertISOToDisplayDate(_startDate) : '',
      endDate: _endDate ? convertISOToDisplayDate(_endDate) : ''
    };
  }
);
