import { createTypeGuard, isDateString } from '@SRHealth/frontend-lib';
import type { NemtProvider, RideshareProvider } from 'contracts/src/app-layer/rbf/v1';
import {
  type DateModel,
  type FundingSourceModel,
  type PassengerInfoModel,
  type RideModel,
  type ProviderNemtModel,
  type ProviderRideshareModel,
  type ProviderPublicModel,
  providerNemtFactory,
  providerRideshareFactory,
  providerPublicFactory,
  type RecurringRidePatternModel
} from '~/models';
import type { RECURRING_RIDES_CADENCE } from './RideBooking.constants';

export type RideBookingSection =
  | 'passenger-info'
  | 'funding-source'
  | 'date'
  | 'rides'
  | 'recurring-rides'
  | 'transport-provider';

export interface RideBookingStore {
  meta: {
    /** An unfortunate requirement to indicate that the store's
     * initialize thunk has already been called and completed. Necessary
     * until we can refactor MemberProfile to use the new redux toolkit. */
    isInitialized: boolean;
    /** Track what part of the RBF the user has reached. All sections < active
     * should be considered complete and validated. */
    activeSection: number;
    /** Track the previous active section so that we can roll back to it if user cancels
     * editing a section. */
    previousActiveSection: number;
    /** Date picker restrictions for the date section */
    dateRestrictions?: DateRestrictions;
    /** Time picker restrictions for the rides section */
    timeRestrictions?: {
      arriveBy: TimeRestrictions;
      departAt: TimeRestrictions;
    };
    /** The request id returned by the supply endpoint. */
    rideRequestId?: string;
    transportProviders: [RideshareProvider] | NemtProvider[];
  };
  passengerInfo: PassengerInfoModel;
  fundingSource: FundingSourceModel;
  date: DateModel;
  rides: RideModel[];
  /** A null value means recurring rides has been turned off for this trip. */
  recurringRides?: RecurringRidePatternModel;
  transportProvider: ProviderNemtModel | ProviderRideshareModel | ProviderPublicModel;
}

export type RestrictedDateRange = [DateString, DateString];

export const isRestrictedDateRange = createTypeGuard<RestrictedDateRange>(v => {
  if (Array.isArray(v) && v.length === 2 && v.every(date => isDateString(date))) {
    return v as RestrictedDateRange;
  }

  return null;
});

export type RestrictedDate = DateString | RestrictedDateRange;

export const isRestrictedDate = createTypeGuard<RestrictedDate>(v =>
  isDateString(v) || isRestrictedDateRange(v) ? v : null
);

/** The various types of the PassengerInfoSection */
export type PassengerInfoSection =
  | 'TripType'
  | 'TripPhoneNumber'
  | 'AdditionalPassengers'
  | 'none';

export interface DateRestrictions {
  /** The earliest date that the passenger can book. */
  earliestDate: DateString;
  /** The latest date that the passenger can book. */
  latestDate: DateString;
  /** Additional specific dates the passenger cannot book */
  restrictedDates: RestrictedDate[];
}
export interface TimeRestrictions {
  flex: boolean;
  now: boolean;
  offset: number;
  interval: number;
}

export type BenefitType = 'limited' | 'unlimited' | 'none';

export type RideBenefitsRecord = {
  label: string;
  limit: number;
  usage: number;
  alertAfter: number;
  hardBlock: boolean;
};

export type RideBenefitsUnlimitedRecord = {
  label: string;
  limit: 'Unlimited';
};

export type BlockType = 'Hard' | 'Soft' | 'Past' | undefined;

/** A union of all the valid provider model types. */
export type ProviderModel =
  | ProviderNemtModel
  | ProviderRideshareModel
  | ProviderPublicModel;

export type PrivateProviders = ProviderNemtModel | ProviderRideshareModel;
export type PublicProvider = ProviderPublicModel;

/** A typeguard to assert that the variable is a valid provider. */
export const isProviderModel = createTypeGuard<ProviderModel>(v => {
  if (
    providerNemtFactory.createdModel(v) ||
    providerRideshareFactory.createdModel(v) ||
    providerPublicFactory.createdModel(v)
  ) {
    return v;
  }

  return null;
});

export type UsageAndLimit = {
  usage: { [timePeriod: string]: number };
  limit: number | null;
  isHardBlock: boolean;
};

export type BenefitsUsageAndLimits = {
  all: {
    ridesPerYear: UsageAndLimit;
    ridesPerMonth: UsageAndLimit;
    costPerYear: UsageAndLimit;
  };
  [key: `benefit-category-${number}`]: {
    ridesPerYear: UsageAndLimit;
  };
};

export type ProjectedUsage = {
  all: {
    ridesPerYear: { [year: string]: number };
    ridesPerMonth: { [yearMonth: string]: number };
  };
  [key: `benefit-category-${number}`]: {
    ridesPerYear: Record<string, number>;
  };
};

export type BenefitRemaining = {
  remaining: number;
  year: string;
  month?: string;
  isHardBlock: boolean;
  benefitCategoryId?: number;
  benefit:
    | 'all.ridesPerYear'
    | 'all.ridesPerMonth'
    | `benefit-category-${number}.ridesPerYear`;
};

export type RecurringRideCadence = (typeof RECURRING_RIDES_CADENCE)[number];
