import { Datetime, Link, Pagination, TOKENS } from '@SRHealth/frontend-lib';
import React, { useEffect, useState } from 'react';
import TripCard from './TripCard';
import type { RideModel } from '~/models';
import { RIDE_PAGE_SIZE } from '../RecurringRides';
import { getTripCardLabel } from '../RecurringRides.utils';
import TripCardSummary from './TripCardSummary';

type TripCardsProps = {
  rides: RideModel[];
  initialRideDate: DateString;
  dates: DateString[];
  isEditable?: boolean;
  onEdit?: () => void;
};

export default function TripCards({
  rides,
  initialRideDate,
  dates,
  onEdit
}: TripCardsProps) {
  const [activePage, setActivePage] = useState(1);
  const [activeCard, setActiveCard] = useState<DateString | null>(null);

  const ridePageIndex = (activePage - 1) * RIDE_PAGE_SIZE;

  const timeOne = `${rides[0].time}, ${Datetime.convertToShortTimezone(rides[0].timezone!)}`;
  const timeTwo = rides?.[1]?.time
    ? `${rides[1].time}, ${Datetime.convertToShortTimezone(rides[1].timezone!)}`
    : '';

  /** If total amount of pages has decreased to lower than the active page
   * size then set the active page to the last page. */
  useEffect(() => {
    if (activePage > 1 && dates.length < activePage * RIDE_PAGE_SIZE) {
      setActivePage(Math.ceil(dates.length / RIDE_PAGE_SIZE) || 1);
    }
  }, [dates.length]);

  return (
    <div className="flex flex-col gap-[12px]">
      <div className="flex flex-row">
        <p className="heading-base flex-1">Trips</p>
        {onEdit && (
          <Link href="#" iconLeft="Edit" label="Edit" size="xsm" onClick={onEdit} />
        )}
      </div>
      <div className="flex flex-col gap-[16px]">
        <div
          className={`flex flex-col p-[16px] shadow-b bg-${TOKENS.SURFACE.PRIMARY.NEUTRAL.XXXLT}`}
        >
          <p className="heading-base">Starts On</p>
          <div className="flex flex-row">
            <p className="heading-sm py-[8px]">{getTripCardLabel(initialRideDate)}</p>
          </div>
          <TripCardSummary
            timeOne={timeOne}
            timeTwo={timeTwo}
            initialRideType={rides[0].type}
          />
        </div>

        <div className="flex flex-col gap-[16px] shadow-b">
          {dates.slice(ridePageIndex, ridePageIndex + RIDE_PAGE_SIZE).map(rideDate => (
            <TripCard
              key={rideDate}
              date={rideDate}
              timeOne={timeOne}
              timeTwo={timeTwo}
              initialRideType={rides[0].type}
              isActive={activeCard === rideDate}
              onToggle={setActiveCard}
            />
          ))}
          {dates.length > RIDE_PAGE_SIZE && (
            <Pagination
              className="h-[24px]"
              style={{ justifyContent: 'end' }}
              currentPage={activePage}
              pageCount={Math.ceil(dates.length / RIDE_PAGE_SIZE)}
              onPageChange={setActivePage}
            />
          )}
        </div>
      </div>
    </div>
  );
}
