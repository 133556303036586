import { DATE_API_FORMAT } from '~/constants';
import moment from 'moment-timezone';
import type { DateRange } from '~/Modules/memberProfile';
import { format } from 'date-fns';
import type { RideTimeString } from '~/models';
import { Datetime } from '@SRHealth/frontend-lib';

/**
 * Generates a timestamp object.
 *
 * This function uses Moment.js to get the current date and time in UTC format.
 * The returned object includes the date in 'YYYY-MM-DD HH:mm:ss.SSSSSS' format,
 * the timezone ('UTC'), and a timezone type (3).
 *
 * @returns {Object} An object containing the timestamp data.
 * @returns {string} .date The current date and time in 'YYYY-MM-DD HH:mm:ss.SSSSSS' format.
 * @returns {string} .timezone The timezone, always 'UTC'.
 * @returns {number} .timezone_type The timezone type, always 3.
 */
export const generateTimestamp = () => {
  return {
    date: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
    timezone: 'UTC',
    timezone_type: 3
  };
};

/**
 * Get the start and end time for a ride.
 * @return {object} start and end time
 */
export const getStartEndTime = ride => {
  // get timezone, is in different location based on ride type
  const timeZone = ride?.passengerTimezone ?? '';

  let startTime: string | moment.Moment = '';
  let endTime = '';

  if (!('rideStartTime' in ride)) {
    return { startTime, endTime };
  }

  const rideStartTime = fixUTCDate(
    ride.rideStartTime,
    timeZone,
    'h:mm A',
    DATE_API_FORMAT,
    true
  );

  if (typeof rideStartTime === 'string') {
    return { startTime, endTime };
  }

  startTime = (rideStartTime as moment.Moment).format('h:mm A');
  endTime = '';
  if (ride.appointmentTime === null) {
    // b leg
    const minutesToAdd = moment(ride.estimatedFinishTime, 'HH:mm:ss').format('m');
    endTime = (rideStartTime as moment.Moment).add(minutesToAdd, 'm').format('h:mm A');
  } else {
    // a leg
    endTime = fixUTCDate(
      ride.appointmentTime,
      ride.passengerTimezone,
      'h:mm A',
      DATE_API_FORMAT
    );
  }

  return { startTime, endTime };
};

export function fixUTCDate(
  date: moment.MomentInput,
  zone: string,
  format: string,
  inputtedTimeFormat: string,
  returnAsMoment: true
): moment.Moment;
export function fixUTCDate(
  date: moment.MomentInput,
  zone: string,
  format: string,
  inputtedTimeFormat?: string,
  returnAsMoment?: false
): string;
/**
 * take utc date and return a formatted date based on time zone
 */
export function fixUTCDate(
  date,
  zone,
  format,
  inputtedTimeFormat = '',
  returnAsMoment = false
) {
  if (!date) return '';

  const timeZone = !zone || zone === 'UTC' ? moment.tz.guess() : zone;

  const utcTime = inputtedTimeFormat
    ? moment.utc(date, inputtedTimeFormat)
    : moment.utc(date);

  try {
    return returnAsMoment
      ? moment(utcTime).tz(timeZone)
      : moment(utcTime).tz(timeZone).format(format);
  } catch (e) {
    return '';
  }
}

/**
 * Converts an array of ISO date ranges into a semicolon-separated, formatted string 'M/d/yy'.
 */
export function formatDateRanges(dates: DateRange[]) {
  if (!dates.length) return '';

  return dates
    .map(
      ({ startDate, endDate }) =>
        `${moment.utc(startDate).format('M/D/YY')} - ${moment
          .utc(endDate)
          .format('M/D/YY')}`
    )
    .join('; ');
}

/** Convert the string, number or Date object to the display format of
 * `MM/DD/YYYY`. */
export function convertToDisplayDate(date: string | number | Date): DisplayDate {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  return format(date, 'MM/dd/yyyy') as DisplayDate;
}

/** Converts the ISO 8601 date format to North American `MM/DD/YYYY` or `MM/DD/YY` */
export function convertISOToDisplayDate(date: DateString, yearDigits = 4): DisplayDate {
  const [year, month, day] = date.split('-');

  const _year = yearDigits === 2 ? year.slice(2) : year;

  return `${month}/${day}/${_year}` as DisplayDate;
}

/** Map of short timezone names to their IANA long form (useful for libraries that
 * expect the long form). */
export const shortTimezoneToLongTimezoneMap = {
  EDT: 'America/New_York', // Eastern Daylight Time
  EST: 'America/New_York', // Eastern Standard Time
  CDT: 'America/Chicago', // Central Daylight Time
  CST: 'America/Chicago', // Central Standard Time
  MDT: 'America/Denver', // Mountain Daylight Time
  MST: 'America/Denver', // Mountain Standard Time
  PDT: 'America/Los_Angeles', // Pacific Daylight Time
  PST: 'America/Los_Angeles', // Pacific Standard Time
  AKDT: 'America/Anchorage', // Alaska Daylight Time
  AKST: 'America/Anchorage', // Alaska Standard Time
  HDT: 'Pacific/Honolulu', // Hawaii Daylight Time
  HST: 'Pacific/Honolulu', // Hawaii Standard Time
  AST: 'America/Puerto_Rico', // Atlantic Standard Time (not a continental US time zone)
  ADT: 'America/Puerto_Rico' // Atlantic Daylight Time (not officially observed in the continental US)
};

/** Adds a whole number of minutes to a ride time string. */
export function addRideTimeMinutes(time: RideTimeString, offset: number) {
  const [hours, minutes] = time.substring(0, time.length - 3).split(':', 2);

  const hourOffset = Math.floor(Math.abs(offset) / 60) * Math.sign(offset);
  const minuteOffset = offset % 60;

  let newHour = +hours + hourOffset;
  let newMinute = +minutes + minuteOffset;

  if (newMinute > 59) {
    newMinute -= 60;
    newHour += 1;
  } else if (newMinute < 0) {
    newMinute += 60;
    newHour -= 1;
  }

  /** Flip the meridiem indicator depending on how many times we
   * have passed 12 hours. */
  let meridiem = time.substring(time.length - 2) as 'am' | 'pm';
  if (
    (newHour >= 12 && Math.floor(newHour / 12) % 2) ||
    (newHour < 0 && Math.ceil(newHour / 12) % 2)
  ) {
    meridiem = meridiem === 'am' ? 'pm' : 'am';
  }

  if (newHour > 12) {
    newHour = newHour % 12 || 12;
  } else if (newHour < 1) {
    newHour = (newHour % 12) + 12;
  }

  return `${newHour}:${newMinute.toString().padStart(2, '0')} ${meridiem}` as RideTimeString;
}

/** Return the offset difference between the current user and
 * a target timezone, in minutes. E.g if the user is on the west coast
 * and the target is on the east coast, the difference will be
 * -180 minutes. */
export function timezoneOffsetFromUser(timezone: string): number {
  return new Datetime().getTimezoneOffset() + Datetime.timezoneOffset(timezone, 'MINUTE');
}

/** Return the amount of minutes from now until the given date. If the
 * value is negative the default behavior is to return 0. Optionally you
 * can pass true as the second argument to retrieve the negative value. */
export function minutesUntilDate(date: DateString, returnNegative = false): number {
  const minutes = Math.floor(
    (new Date(`${date} 00:00:00`).valueOf() - Date.now()) / 60000
  );

  return minutes > 0 || returnNegative ? minutes : 0;
}

/** Return the amount of minutes from now to midnight. */
export function minutesToMidnight(): number {
  const now = new Date();

  const hours = 23 - now.getHours();
  const minutes = 59 - now.getMinutes();

  return minutes + hours * 60;
}
