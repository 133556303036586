import { createModelFactory, Is, type ModelType } from '@SRHealth/frontend-lib';
import type { PropertyRuleSignature } from '.';

export type ProviderRideshareProps = {
  id: string;
  name: 'Lyft' | 'Uber' | undefined;
  rideCosts: number[];
  chatEnabled: false;
};

export type ProviderRideshareModel = ModelType<ProviderRideshareProps>;
export type ProviderRideshareRule = PropertyRuleSignature<ProviderRideshareProps>;

const PROVIDER_RIDESHARE_DEFAULT = (): ProviderRideshareProps => ({
  id: '',
  name: undefined,
  rideCosts: [],
  chatEnabled: false
});

export const providerRideshareFactory = createModelFactory<ProviderRideshareModel>(
  PROVIDER_RIDESHARE_DEFAULT(),
  {
    properties: [
      ['is-string', 'id', Is.String.strict],
      [
        'is-not-empty',
        'id',
        (v, _, isCommit) => {
          if (isCommit && !Is.Empty(v)) throw Error('ID is required.');

          return true;
        }
      ],
      ['is-string', 'name', Is.String.strict],
      ['is-not-empty', 'name', v => Is.String.strict(v) && v.length > 0],
      ['is-array', 'rideCosts', Is.JsonArray.strict],
      [
        'has-ride-cost',
        'rideCosts',
        (v: number[], _, isCommit) => {
          if (isCommit && v.length < 1) {
            throw Error('Ride must have cost for at least one leg.');
          }

          return true;
        }
      ],
      [
        'is-numeric-ride-costs',
        'rideCosts',
        (v: number[], _, isCommit) => {
          if (isCommit && v.some(e => !Is.Numeric(e))) {
            throw Error('Ride costs must be numeric.');
          }

          return true;
        }
      ],
      [
        'is-false',
        'chatEnabled',
        v => {
          if (v !== false) {
            throw Error('ChatEnabled must be false.');
          }

          return true;
        }
      ]
    ],
    model: []
  }
);
