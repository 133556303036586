import { createAppAsyncThunk } from '~/Modules';
import { getDuplicateRides } from '~/services/rideBooking.service';
import { getTimeRestrictionsThunk } from './getTimeRestrictions.thunk';
import type { SimplifiedRide } from 'contracts/src/app-layer/rbf/v1/shared';

export const completeDateThunk = createAppAsyncThunk(
  'rideBooking/completeDate',
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  async (checkForDuplicates: boolean = true, { dispatch, getState }) => {
    const { passengerInfo, date: dateModel, rides } = getState().rideBooking;

    if (!passengerInfo.passengerId || !dateModel.date) {
      throw Error('passengerId and date are required to complete the Date section.');
    }

    let duplicateRides: SimplifiedRide[] | null = null;
    if (dateModel.date && dateModel.propertyIsDirty('date') && checkForDuplicates) {
      duplicateRides = (
        await getDuplicateRides(passengerInfo.toJSON(), { date: dateModel.date })
      )?.data;
    }

    const modelWasDirty = dateModel.propertyIsDirty('date');

    await getState()
      .rideBooking.date.commit()
      .then(() => dispatch(getTimeRestrictionsThunk()))
      // Reset the ride times after the date has been updated
      .then(() => {
        if (modelWasDirty) rides.forEach(ride => (ride.time = undefined));
      });

    return duplicateRides;
  }
);
