import { useMemo } from 'react';
import {
  createTypeGuard,
  Datetime,
  DAYS_OF_WEEK,
  type DayOfWeek,
  type DateString
} from '@SRHealth/frontend-lib';
import type { RecurringRideCadence } from '~/Modules/rideBooking';
import type { BenefitsUsageAndLimits } from '~/Modules/rideBooking/RideBooking.types';
import { getBenefitsUnderRemainingThreshold } from '~/Modules/rideBooking/RideBooking.utils';
import { formatProjectedExceededLimitsMessage } from '~/Modules/rideBooking/selectors/selectRecurringRidesLimitAlert.utils';

/** Type guard for days of week DatePicker type. */
export const isDayOfWeek = createTypeGuard((v: unknown) =>
  typeof v === 'string' && DAYS_OF_WEEK.includes(v as DayOfWeek) ? (v as DayOfWeek) : null
);

/** Converts the ride's date time to a weekday identifier
 * that the DatePicker can interpret. */
export function getRideWeekDay(date: DateString): DayOfWeek {
  const datetime = new Datetime(date);

  return DAYS_OF_WEEK[datetime.getUTCDay()];
}

/** A hook to generate the `determineDateType` for the DatePicker
 * component used in RecurringRides. It sets any of the selected
 * weekdays to 'recurring' if the date is a recurring ride.
 *
 * The returned callback is memoized. */
export function useRecurringRideOptions(
  startDate: DateString,
  endDate: DateString,
  daysOfWeek?: DayOfWeek[],
  cadence?: RecurringRideCadence
) {
  const callback = useMemo(() => {
    /** Determine the multiplier */
    function getMultiplier(cadence?: RecurringRideCadence) {
      switch (cadence) {
        case 'weekly':
          return 1;
        case 'everyTwoWeeks':
          return 2;
        case 'everyThreeWeeks':
          return 3;
        case 'everyFourWeeks':
          return 4;
        default:
          return 0;
      }
    }

    const startDateObj = new Datetime(startDate);
    const endDateObj = new Datetime(endDate);
    const multiplier = getMultiplier(cadence);
    const offsets = new Array(7).fill(0).map((_, i) => startDateObj.getUTCDay() - i);

    return (date: DateString) => {
      const dateObj = new Datetime(date);

      if (
        dateObj.valueOf() > endDateObj.valueOf() ||
        dateObj.valueOf() < startDateObj.valueOf()
      ) {
        return 'disabled';
      }

      // The section is in custom mode and does not
      // have a cadence or days of week set.
      if (!cadence || !daysOfWeek) return;

      const daysDiff = Datetime.convertBaseToUnit(
        dateObj.valueOf() - startDateObj.valueOf(),
        'DAY'
      );

      for (const day of daysOfWeek) {
        const dayIndex = DAYS_OF_WEEK.indexOf(day);
        const offset = offsets[dayIndex];

        if ((daysDiff + offset) % (7 * multiplier) === 0) {
          return 'highlighted';
        }
      }

      return 'disabled';
    };
  }, [startDate, endDate, daysOfWeek, cadence]);

  return callback;
}

/** Generate the card label for a trip in a recurring ride series. */
export function getTripCardLabel(date: DateString) {
  const dateObj = new Datetime(date);

  return [
    dateObj.getUTCDay('short'),
    '-',
    dateObj.getUTCMonth('short'),
    dateObj.getUTCDate(),
    dateObj.getUTCFullYear()
  ].join(' ');
}

/** Checks if the selected recurring ride dates are projected to exceed benefit limits */
export function checkRecurringExceededLimits(
  recurringDates: DateString[],
  benefitsUsageAndLimits: BenefitsUsageAndLimits,
  activeBenefitCategoryIds: number[],
  isRoundTrip: boolean
): [string, 'warning' | 'error' | undefined] {
  const projectedExceededLimits = getBenefitsUnderRemainingThreshold(
    0,
    recurringDates,
    benefitsUsageAndLimits,
    activeBenefitCategoryIds,
    true,
    isRoundTrip
  );

  return formatProjectedExceededLimitsMessage(projectedExceededLimits, isRoundTrip);
}
