import { Is, createModelFactory, type ModelType } from '@SRHealth/frontend-lib';
import type { PropertyRuleSignature } from '~/models';

export type DateProps = {
  /** The date that the passenger is booking for. */
  date: DateString | undefined;
};

export type DateModel = ModelType<DateProps>;

export const DATE_DEFAULT = (props: Partial<DateProps> = {}): DateProps => ({
  date: undefined,
  ...props
});

export const dateFactory = createModelFactory<DateModel>(DATE_DEFAULT(), {
  properties: [
    ['is-string', 'date', (v, _m, isCommit) => isCommit && Is.String.strict(v)]
  ],
  model: []
});

export type DatePropertyRule = PropertyRuleSignature<DateProps>;
