import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import { selectActiveBenefitCategoryIds } from './selectActiveBenefitCategoryIds';
import {
  findMostRelevantBenefitRemaining,
  getBenefitsUnderRemainingThreshold
} from '../RideBooking.utils';
import { selectIgnoreBenefitLimits } from './selectIgnoreBenefitLimits';
import { selectBenefitsUsageAndLimits } from './selectBenefitsUsageAndLimits';

const selectDate = (s: AppStore) => s.rideBooking.date.date;

/** Returns the remaining rides allowed for the member and whether the ride limit is a hard block or not. */
export const selectRemainingRidesAllowed = createSelector(
  [
    selectDate,
    selectBenefitsUsageAndLimits,
    selectActiveBenefitCategoryIds,
    selectIgnoreBenefitLimits
  ],
  (
    date,
    benefitsUsageAndLimits,
    benefitCategoryIds,
    ignoreBenefitLimits
  ): [number, boolean] => {
    if (ignoreBenefitLimits) {
      return [Infinity, false];
    }

    /** Setting the default values for the remaining rides and hard block */
    let currentRemainingRides = Infinity;
    let currentHardBlock = false;

    /** Getting the limits that have less than 3 projected remaining rides */
    const projectedUsage = getBenefitsUnderRemainingThreshold(
      3,
      [],
      benefitsUsageAndLimits,
      benefitCategoryIds,
      false
    );

    const mostRelevantBenefit = findMostRelevantBenefitRemaining(projectedUsage, date);

    if (mostRelevantBenefit) {
      currentRemainingRides = mostRelevantBenefit.remaining;
      currentHardBlock = mostRelevantBenefit.isHardBlock;
    }

    return [currentRemainingRides, currentHardBlock];
  }
);
