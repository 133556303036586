import React, { useState, useEffect } from 'react';
import { TOKENS, Input, Icon, Button, useModal } from '@SRHealth/frontend-lib';
import { useMemberPortalManageStyles } from '../styles';
import type { MemberPortalInfo, BenefitsForm } from '~/Modules/memberProfile';
import {
  sendMemberPortalEmail,
  createMemberPortalStatus
} from '~/services/member.service';
import {
  getMemberPortalInfoFromApi,
  updateMemberPortalStatus
} from '~/Modules/memberProfile/actions';
import { isString } from '~/utilities/guards';
import { validateEmail } from '~/utilities/helperFunctions';
import { useAppDispatch } from '~/Modules';

const DeactivationModal = ({ id, handleDeactivation }) => {
  const [deactivationReason, setDeactivationReason] = useState('');
  const [isDeactivating, setIsDeactivating] = useState(false);

  const handleTextAreaChange = (_, value) => {
    setDeactivationReason(value);
  };

  const onDeactivate = async () => {
    setIsDeactivating(true);
    await handleDeactivation(deactivationReason);
    setIsDeactivating(false);
  };

  return (
    <div>
      <Input
        type="textarea"
        inputId={id}
        name="Deactivation Text"
        placeholder="Add a message"
        value={deactivationReason}
        onChange={handleTextAreaChange}
        label="Reason for Deactivation"
        required
        resize
      />
      <Button
        label="Deactivate"
        size="md"
        onClick={onDeactivate}
        disabled={isDeactivating || !deactivationReason.trim()}
      />
    </div>
  );
};

type MemberPortalToggleProps = {
  memberPortalInfo: MemberPortalInfo;
};

function MemberPortalToggle({ memberPortalInfo }: MemberPortalToggleProps) {
  const { isActive, userLogin, id } = memberPortalInfo;
  const [isToggleActive, setIsToggleActive] = useState(isActive);
  const [_, modalActions] = useModal();
  const classes = useMemberPortalManageStyles();
  const dispatch = useAppDispatch();

  const handleToggleChange = async (_, toggleValue: boolean) => {
    return toggleValue
      ? await activateMemberPortal()
      : modalActions.set({
        isOpen: true,
        title: 'Account Deactivation',
        content: <DeactivationModal id={id} handleDeactivation={handleDeactivation} />,
        showBackdrop: true,
        onDismiss: () => {
          modalActions.setIsOpen(false);
          dispatch(getMemberPortalInfoFromApi(id));
        }
      });
  };

  const activateMemberPortal = async () => {
    const status = (await createMemberPortalStatus(id, 1, ''))?.status;

    if (status) {
      setIsToggleActive(true);
      dispatch(getMemberPortalInfoFromApi(id));
      dispatch(updateMemberPortalStatus(true));
    } else {
      setIsToggleActive(false);
    }
  };

  const handleDeactivation = async (deactivationReason: string) => {
    if (!isString(deactivationReason)) return;

    const status = (await createMemberPortalStatus(id, 0, deactivationReason))?.status;

    if (status) {
      modalActions.setIsOpen(false);
      setIsToggleActive(false);
      dispatch(getMemberPortalInfoFromApi(id));
      dispatch(updateMemberPortalStatus(false));
    } else {
      setIsToggleActive(true);
    }
  };

  useEffect(() => setIsToggleActive(isActive), [isActive]);

  return (
    <div className={classes.headerContainer}>
      <div className={`text-xl text-${TOKENS.FONT.PRIMARY}`} style={{ marginTop: '5px' }}>
        Manage
      </div>
      {userLogin ? (
        <div className={classes.toggleContainer}>
          <span className={classes.toggleLabel}>Member Portal</span>
          <Input
            inputId={id}
            type="toggle"
            name="Member Portal"
            onChange={handleToggleChange}
            options={[{ label: '', value: isToggleActive }]}
            size="md"
            value={isToggleActive}
          />
        </div>
      ) : null}
    </div>
  );
}

export type MemberPortalMangageProps = {
  memberPortalInfo: MemberPortalInfo;
  portalSlug: BenefitsForm['memberPortalSlug'];
};

function MemberPortalManage({ memberPortalInfo, portalSlug }: MemberPortalMangageProps) {
  const classes = useMemberPortalManageStyles();
  const memberPortalUrl = portalSlug
    ? `http://${portalSlug}.member.saferidehealth.com/login`
    : '';

  return (
    <div className={classes.container}>
      <MemberPortalToggle memberPortalInfo={memberPortalInfo} />
      <div className="flex flex-col items-start items-stretch gap-[8px]">
        <div className="text-sm font-bold" style={{ fontFamily: 'Inter' }}>
          Website URL
        </div>
        {portalSlug ? (
          <a className={classes.websiteLink} href={memberPortalUrl}>
            {memberPortalUrl}
          </a>
        ) : null}
      </div>
      <MemberPortalEmail
        passengerId={memberPortalInfo.id}
        isActive={memberPortalInfo.isActive}
        userLogin={memberPortalInfo.userLogin}
      />
    </div>
  );
}

const EmailModalContent = ({ email }) => {
  return (
    <>
      <Icon
        type="Mail"
        className="fill-current text-primary-blue-md"
        style={{ alignSelf: 'center' }}
      />
      <p>{`An email will be sent to ${email} with further instructions`}</p>
    </>
  );
};

type MemberPortalEmailProps = {
  passengerId: MemberPortalInfo['id'];
  isActive: MemberPortalInfo['isActive'];
  userLogin: MemberPortalInfo['userLogin'];
};

function MemberPortalEmail({ passengerId, isActive, userLogin }: MemberPortalEmailProps) {
  const [_, modalActions] = useModal();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const disableEmailCTA = Boolean(!isActive && userLogin);

  const handleMemberPortalEmail = (_, value) => {
    setEmail(value);
    setEmailError(validateEmail(value) ? '' : 'Please enter a valid email address');
  };

  const openModal = async () => {
    if (emailError) return;
    const { status } = await sendMemberPortalEmail(Number(passengerId), email);

    if (status) {
      modalActions.set({
        isOpen: true,
        title: 'Email Sent',
        content: <EmailModalContent email={email} />,
        onDismiss: () => modalActions.setIsOpen(false),
        type: 'success'
      });
    } else {
      setEmailError('Failed to send email');
    }
  };

  return (
    <div className="flex flex-col items-start items-stretch" style={{ gap: '12px' }}>
      <div>
        <Input
          className="text-sm font-bold leading-4 text-app-black mb-2.5"
          inputId={passengerId}
          label="Email"
          name="test"
          placeholder="Email"
          type="single"
          onChange={handleMemberPortalEmail}
          error={Boolean(emailError)}
          caption={emailError}
          value={email}
          disabled={disableEmailCTA}
        />
      </div>
      <div className="text-display-base-medium" style={{ marginLeft: '-5px' }}>
        <Button
          iconLeft="Mail"
          id="default"
          label="Send Account Access"
          onClick={openModal}
          size="md"
          disabled={disableEmailCTA}
        />
      </div>
    </div>
  );
}

export default MemberPortalManage;
