import React from 'react';
import { Link } from '@SRHealth/frontend-lib';
import { format, addDays, endOfDay } from 'date-fns';
import { format as formatTZ, utcToZonedTime } from 'date-fns-tz';
import type { DuplicateRide } from '../Date.types';

interface DuplicateRideDetailsProps {
  duplicateRide: DuplicateRide;
  selectedDate: DateString;
}

const DuplicateRideDetails = ({
  duplicateRide,
  selectedDate
}: DuplicateRideDetailsProps) => {
  const timeZone = duplicateRide.timezone || 'UTC';
  const pickupTime =
    duplicateRide.pickupTime === 'Flex Time'
      ? 'Will Call'
      : formatTZ(
        utcToZonedTime(new Date(duplicateRide.pickupTime), timeZone),
        'h:mm a z',
        { timeZone }
      );

  // Convert selectedDate to the last millisecond of the next day for the ride history table range
  const toDate = format(
    addDays(endOfDay(new Date(selectedDate)), 2),
    'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\''
  );

  return (
    <div className="text-base" style={{ paddingTop: '10px' }}>
      <div
        style={{
          display: 'flex',
          marginLeft: '-6px',
          marginBottom: '-6px'
        }}
      >
        <Link
          href={`/ride/reports?id=${duplicateRide.id}&toDate=${toDate}`}
          target="_blank"
          label={`Ride ID: ${duplicateRide.id}`}
          onClick={() => {}}
        />
      </div>
      <div className="text-base">{`Pick up time: ${pickupTime}`}</div>
      <div className="text-base">{`Location: ${duplicateRide.pickupAddress}`}</div>
    </div>
  );
};

export default DuplicateRideDetails;
