import isEmpty from 'lodash/isEmpty';
import type { PersonalInfoForm } from '~/Modules/memberProfile';
import { formatPhoneNumberToE164Standard } from '~/utilities/helperFunctions';

export const optInPhoneType = {
  PRIMARY_PHONE_OPT_OUT: 'member_primary_phone_opt_out',
  SECONDARY_PHONE_OPT_OUT: 'member_secondary_phone_opt_out'
};

export const modalDisplayType = {
  displayOptInModal: 'displayOptInModal',
  displaySecondaryPhoneReminderModal: 'displaySecondaryPhoneReminderModal',
  primaryPhoneCannotAcceptText: 'primaryPhoneCannotAcceptText'
};

type SMSFlags = {
  isCustomTextMessage: boolean | undefined;
  isEligibilityAPI: boolean | undefined;
};

export const getOptInValue = (
  personalInfo: PersonalInfoForm,
  hasPrompt: boolean | undefined,
  { isCustomTextMessage, isEligibilityAPI }: SMSFlags
) => {
  if (!personalInfo || hasPrompt) {
    return {
      version: 0,
      value: ''
    };
  }

  const {
    memberPhoneNumbers = [],
    phone2,
    mobileNo,
    defaultOptOutTextOrAll
  } = personalInfo;

  const formattedNumber = formatPhoneNumberToE164Standard(phone2);
  const primary = memberPhoneNumbers?.find(
    phone =>
      phone?.is_primary &&
      formatPhoneNumberToE164Standard(mobileNo) === phone?.phone_number
  );
  const secondary = memberPhoneNumbers.find(
    phone => !phone?.is_primary && formattedNumber === phone.phone_number
  );

  /**
   * Assuming custom text messaging is turned on...
   * if the primary number is `mobile` and `textable` and `available`,
   * and it has `not` been prompted to opt out,
   * and their default opt out is "Text" or "All",
   * then display the opt in modal
   */
  if (
    isCustomTextMessage &&
    primary?.textable &&
    isEmpty(primary?.opt_out_prompt_timestamp) &&
    defaultOptOutTextOrAll
  ) {
    return {
      version: 2,
      type: modalDisplayType.displayOptInModal,
      value: optInPhoneType.PRIMARY_PHONE_OPT_OUT,
      phone: primary.phone_number
    };
  }

  /**
   * Assuming there is no secondary number...
   * If the primary number is not textable, and they haven't been prompted for
   * any reminders, and the eligibility api is off,
   * then send a reminder to add a mobile number as a secondary number.
   */
  if (primary && !primary.textable && !secondary && !hasPrompt && !isEligibilityAPI) {
    return {
      version: 3,
      type: modalDisplayType.primaryPhoneCannotAcceptText,
      value: optInPhoneType.SECONDARY_PHONE_OPT_OUT
    };
  }

  /**
   * Assuming custom text messaging is turned on...
   * if the primary number is not textable, and
   * the secondary number is mobile and they haven't been prompted to opt in/out
   * and their default opt out is "Text" or "All",
   * then display the opt in modal for the secondary number
   */
  if (
    isCustomTextMessage &&
    !primary?.textable &&
    secondary &&
    secondary.textable &&
    isEmpty(secondary?.opt_out_prompt_timestamp) &&
    defaultOptOutTextOrAll
  ) {
    return {
      version: 2,
      type: modalDisplayType.displayOptInModal,
      value: optInPhoneType.SECONDARY_PHONE_OPT_OUT,
      phone: secondary.phone_number
    };
  }

  /**
   * Assuming custom text messaging is turned on...
   * if the primary number is not textable, and
   * the secondary number is mobile and has been prompted to opt in
   * and their default opt out is "Text" or "All",
   * display the opt in modal to let them know
   * we will use the secondary number as the primary number
   * in the rbf.
   */

  if (
    isCustomTextMessage &&
    !primary?.textable &&
    secondary &&
    secondary.textable &&
    !secondary.opt_out &&
    secondary?.opt_out_prompt_timestamp &&
    defaultOptOutTextOrAll
  ) {
    return {
      version: 4,
      type: modalDisplayType.displaySecondaryPhoneReminderModal,
      value: optInPhoneType.SECONDARY_PHONE_OPT_OUT,
      phone: secondary.phone_number
    };
  }

  return {
    version: 0,
    value: ''
  };
};

export const processOptOutField = ({ optOutField, personalInfo, memberPhoneNumbers }) => {
  const optOutMapping = {
    member_primary_phone_opt_out: {
      field: 'mobileNo',
      optOutId: 'member_primary_phone_opt_outId',
      condition: p => p.is_primary
    },
    member_secondary_phone_opt_out: {
      field: 'phone2',
      optOutId: 'member_secondary_phone_opt_outId',
      condition: p => !p.is_primary
    }
  };

  const currentMapping = optOutMapping[optOutField];

  if (currentMapping) {
    const optInTarget = personalInfo[currentMapping.field];
    const currentOptOutValue = personalInfo[currentMapping.optOutId];
    const memberPhoneNumber = memberPhoneNumbers.find(currentMapping.condition);

    return { optInTarget, currentOptOutValue, memberPhoneNumber };
  }

  return {
    optInTarget: null,
    currentOptOutValue: null,
    memberPhoneNumber: {}
  };
};
