import { Datetime, Input } from '@SRHealth/frontend-lib';
import React, { useMemo, useState } from 'react';
import { isRideTimeString, type RideTime, type RideType } from '~/models';
import { addRideTimeMinutes } from '~/utilities/timesAndDates';
import { useTimeOptions } from '../../../Hooks/useTimeOptions';

export type RidesTimePickerProps = {
  /** Id for the input element. */
  inputId: number;
  /** Is the ride an arrive-by or depart-at ride? This impacts the label
   * of the time picker element. */
  type: RideType;
  date: DateString | undefined;
  /** The selected ride time. */
  time: string | undefined;
  /** The ride timezone. */
  timezone: string | undefined;
  /** Ride time of the previous leg, if applicable. */
  lastRideTime?: RideTime | undefined;
  /** Timezone of the previous leg, if applicable. */
  lastRideTimezone?: string | undefined;
  /** Boolean indicating if there is an error in the input. */
  error: boolean;
  /** The event handler for changes to the selected time. */
  onChange: (inputId: number, time: string) => void;
  /** The input label to display. */
  label?: string;
};

export default function RidesTimePicker({
  inputId,
  type,
  date,
  time,
  timezone,
  lastRideTime,
  lastRideTimezone,
  error,
  onChange,
  label
}: RidesTimePickerProps) {
  const [query, setQuery] = useState('');
  const timeOptions = useTimeOptions(type, timezone, date!);

  // Detect if the last ride time is in a different timezone than the current ride.
  const _lastRidetime = useMemo(() => {
    if (!timezone || timezone === lastRideTimezone) return lastRideTime;

    if (!isRideTimeString(lastRideTime)) return lastRideTime;

    return addRideTimeMinutes(
      lastRideTime!,
      Datetime.timezoneDifference(lastRideTimezone!, timezone, 'MINUTE')
    );
  }, [timezone, lastRideTime, lastRideTimezone]);

  const _timeOptions = useMemo(() => {
    if (!_lastRidetime || !isRideTimeString(_lastRidetime)) return timeOptions;

    const optIndex = timeOptions.findIndex(({ value }) => value === _lastRidetime);
    const withFlex = timeOptions.findIndex(({ value }) => value === 'Flex Time');

    return withFlex > -1 && optIndex > -1
      ? [timeOptions[withFlex]].concat(timeOptions.slice(optIndex + 1))
      : timeOptions.slice(optIndex + 1);
  }, [_lastRidetime, timeOptions]);

  return (
    <Input
      inputId={inputId}
      type="search-dropdown"
      name="time"
      label={label}
      placeholder="Select a time"
      icon="Time"
      loading={!timezone}
      value={time}
      options={_timeOptions.filter(({ label }) => label.includes(query))}
      debounce={650}
      onInput={(_, query) => setQuery(query)}
      onSearch={(_, query) => setQuery(query)}
      onChange={onChange}
      disabled={!timezone}
      error={error}
      required
    />
  );
}
