import { createSelector } from '@reduxjs/toolkit';
import { selectEligibility, selectEligibilityDates } from '~/Modules/memberProfile';
import type { AppStore } from '~/types';
import { minutesUntilDate } from '~/utilities/timesAndDates';
import type { BlockType } from '../RideBooking.types';
import { Datetime } from '@SRHealth/frontend-lib';
import { selectIgnoreBenefitLimits } from './selectIgnoreBenefitLimits';
import { selectActiveBenefitCategoryIds } from './selectActiveBenefitCategoryIds';
import {
  findMostRelevantBenefitRemaining,
  getBenefitsUnderRemainingThreshold
} from '../RideBooking.utils';
import { selectBenefitsUsageAndLimits } from './selectBenefitsUsageAndLimits';

const selectDateRestrictions = (s: AppStore) => s.rideBooking.meta.dateRestrictions;
const selectPastBookingDays = (s: AppStore) => s.user?.pastBookingDays ?? 0;

/**
 * Given the selected treatment and the information we have so far on the funding source section,
 * determines if the passenger is blocked from booking a ride based on their current benefits
 * and if the sub health plan has treatments enabled. This only affects passengers with health
 * sub plans that have a rides per year benefit AND have treatments enabled.
 *
 * If a passenger is no longer eligible but WAS eligible then we have to check
 * the date of their latest eligibility. If it's within the past booking days
 * range then they should be allowed to book a past ride. The date lookup accounts
 * for member eligibility.
 */
export const selectIsFundingSourceBlocked = createSelector(
  [
    selectEligibility,
    selectPastBookingDays,
    selectDateRestrictions,
    selectEligibilityDates,
    selectBenefitsUsageAndLimits,
    selectIgnoreBenefitLimits,
    selectActiveBenefitCategoryIds
  ],
  (
    isEligible,
    pastBookingDays,
    dateRestrictions,
    eligibilityDates,
    benefitsUsageAndLimits,
    ignoreBenefitLimits,
    activeBenefitCategoryIds
  ): [boolean, BlockType] => {
    if (ignoreBenefitLimits) return [false, undefined];

    // Check if eligibility period is more than 1 year
    if (eligibilityDates.startDate && eligibilityDates.endDate) {
      const startDate = new Datetime(eligibilityDates.startDate);
      const endDate = new Datetime(eligibilityDates.endDate);

      // if the end date is more than 1 year from the start date, then don't block yet,
      // we don't know what Date they want yet, so handle this on the Date section.
      if (endDate.valueOf() > startDate.addYears(1).valueOf()) {
        return [false, undefined];
      }
    }

    // If the user is no longer eligible but WAS eligible then we have to check
    // the date of their latest eligibility. If it's within the past booking days
    // range then they should be allowed to book a past ride. The date lookup accounts
    // for member eligibility.
    if (!isEligible) {
      if (!pastBookingDays || !dateRestrictions) return [true, 'Hard'];

      if (minutesUntilDate(dateRestrictions.latestDate) <= pastBookingDays * 24 * 60) {
        return [true, 'Past'];
      }
    }

    const currentYear = new Date().getFullYear().toString();

    const benefitsUnderThreshold = getBenefitsUnderRemainingThreshold(
      1,
      [],
      benefitsUsageAndLimits,
      activeBenefitCategoryIds,
      false
    ).filter(
      // Filter out monthly benefits, only obey the rides per year limit (and for only the
      // current year) on the Funding Source section. We'll check more in later sections.
      b => !b.month && b.year === currentYear
    );

    const mostRelevantBenefit = findMostRelevantBenefitRemaining(benefitsUnderThreshold);

    if (mostRelevantBenefit) {
      return [true, mostRelevantBenefit.isHardBlock ? 'Hard' : 'Soft'];
    }

    // If no limits hit or anything else, then no block
    return [false, undefined];
  }
);
