import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Datetime,
  DayOfWeekSelector,
  Input,
  THEME,
  TOKENS
} from '@SRHealth/frontend-lib';
import { useAppDispatch, useAppSelector } from '~/Modules';
import {
  completeRecurringRidesThunk,
  editRecurringRides,
  resetRecurringRides,
  RIDE_SECTION_ORDER,
  selectActiveBenefitCategoryIds,
  selectBenefitsUsageAndLimits,
  selectIgnoreBenefitLimits,
  selectIsActive,
  selectRecurringDateBounds,
  selectRemainingRidesAllowed,
  toNextSection,
  type RecurringRideCadence
} from '~/Modules/rideBooking';
import { NOOP } from '~/utilities/helperFunctions';
import {
  useRecurringRideOptions,
  checkRecurringExceededLimits
} from './RecurringRides.utils';
import Loader from '../../Loader';
import TripCards from './subcomponents/TripCards';
import useModelSelector from '~/hooks/useModelSelector';
import UsedUpBenefitPeriodsAlert from '../Shared/UsedUpBenefitPeriodsAlert';
import { RECURRING_RIDES_TXT } from './RecurringRides.constants';

export const RIDE_PAGE_SIZE = 4;

const inlineCSS = `
  #section-recurring-rides [data-testid="accordion"] {
    gap: 0px;
  }

  #section-recurring-rides [data-testid^="accordion"] h2 {
    padding: 0 16px;
    border-color: ${THEME.colors['neutral-gray-xxlt']};
  }

  #section-recurring-rides [data-testid^="accordion"] h2 svg {
    fill: ${THEME.colors[TOKENS.ICON.ACTIVE]};
  }

  #section-recurring-rides [data-testid="accordion-content"] {
    padding: 0 16px;
    border-bottom: solid 1px ${THEME.colors['neutral-gray-xxlt']};
  }
`;

const recurringRidesCadenceOptions: { label: string; value: RecurringRideCadence }[] = [
  { label: 'Every Week', value: 'weekly' },
  { label: 'Every 2nd Week', value: 'everyTwoWeeks' },
  { label: 'Every 3rd Week', value: 'everyThreeWeeks' },
  { label: 'Every 4th Week', value: 'everyFourWeeks' }
];

const RecurringRides = () => {
  const appDispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const rides = useAppSelector(s => s.rideBooking.rides);
  const isActive = useAppSelector(state => selectIsActive(state, 'recurring-rides'));
  const { model: recurringRides } = useModelSelector(s => s.rideBooking.recurringRides!);
  const [earliestDate, latestDate] = useAppSelector(s => selectRecurringDateBounds(s));
  const benefitsUsageAndLimits = useAppSelector(selectBenefitsUsageAndLimits);
  const activeBenefitCategoryIds = useAppSelector(selectActiveBenefitCategoryIds);
  const ignoreBenefitLimits = useAppSelector(selectIgnoreBenefitLimits);

  const recurringRideOptions = useRecurringRideOptions(
    earliestDate,
    latestDate,
    recurringRides.daysOfWeek,
    recurringRides.cadence
  );

  const dates = useMemo(() => {
    if (
      !recurringRides.endDate ||
      !recurringRides.daysOfWeek ||
      !recurringRides.cadence
    ) {
      return [];
    }

    const selectedDates: DateString[] = [earliestDate];
    const lastDate = new Datetime(recurringRides.endDate);

    while (lastDate.toISODate() !== earliestDate) {
      const DateString = lastDate.toISODate();
      if (recurringRideOptions(DateString) === 'highlighted') {
        selectedDates.push(DateString);
      }

      lastDate.subtractDays(1);
    }

    return selectedDates.sort();
  }, [recurringRides.endDate, recurringRideOptions]);

  const [remainingRidesAllowed, isHardBlock] = useAppSelector(
    selectRemainingRidesAllowed
  );

  const [projectedExceededLimitsMessage, alertType] = useMemo(() => {
    if (ignoreBenefitLimits) return ['', undefined];

    return checkRecurringExceededLimits(
      dates,
      benefitsUsageAndLimits,
      activeBenefitCategoryIds,
      rides.length === 2
    );
  }, [
    ignoreBenefitLimits,
    dates,
    benefitsUsageAndLimits,
    activeBenefitCategoryIds,
    rides.length
  ]);

  /** Quality of life. When the section is first activated, scroll down for user. */
  useEffect(() => {
    if (isActive) {
      document.getElementById('recurring-trip')!.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isActive]);

  /** Completes the recurring rides. */
  function handleConfirm() {
    if (!recurringRides) return;

    setIsLoading(true);

    appDispatch(completeRecurringRidesThunk())
      .catch(NOOP)
      .finally(() => setIsLoading(false));
  }

  /** Toggles the recurring rides section on and off. */
  function handleToggle() {
    if (!(isActive || recurringRides?.endDate)) return appDispatch(editRecurringRides());

    appDispatch(resetRecurringRides());
    appDispatch(toNextSection());
  }

  return (
    <div
      id="section-recurring-rides"
      data-testid="section-recurring-rides"
      className="reset-div relative flex flex-col p-[16px] items-center"
    >
      <style>{inlineCSS}</style>
      <Loader isLoading={isLoading} />
      <Card id="recurring-trip" label="Recurring Trip" border={false}>
        <div className="reset-div flex flex-row" style={{ gap: '57px' }}>
          <Input
            type="toggle"
            inputId={0}
            name="recurring-trip-toggle"
            style={{ width: '220px' }}
            options={[{ label: 'Make Recurring', value: false }]}
            size="md"
            value={!!(isActive || recurringRides?.endDate)}
            error={false}
            onChange={handleToggle}
            disabled={remainingRidesAllowed <= 2 && isHardBlock}
          />

          {!(isActive || recurringRides?.endDate) && remainingRidesAllowed <= 2 && (
            <Alert
              type={isHardBlock ? 'error' : 'warning'}
              label={RECURRING_RIDES_TXT.RIDE_LIMIT_REACHED}
            />
          )}

          {isActive && (
            <div className="flex flex-col" style={{ gap: '32px' }}>
              <div className="flex flex-col gap-[12px]">
                <div style={{ width: '378px' }}>
                  <UsedUpBenefitPeriodsAlert />
                </div>
                <p className="heading-base">1. Select Repeats On</p>
                <DayOfWeekSelector
                  daysSelected={recurringRides.daysOfWeek}
                  onChange={daysOfWeek => (recurringRides.daysOfWeek = daysOfWeek)}
                />
              </div>
              <div className="flex flex-col gap-[12px]">
                <p className="heading-base">2. Select Frequency</p>
                <Input
                  type="select"
                  inputId={1}
                  name="cadence"
                  size="md"
                  value={recurringRides.cadence}
                  options={recurringRidesCadenceOptions}
                  onChange={(_, cadence: RecurringRideCadence) =>
                    (recurringRides.cadence = cadence)
                  }
                />
              </div>

              <div className="flex flex-col gap-[12px]">
                <p className="heading-base">3. Select End Date</p>
                <DatePicker
                  id="recurring-ride-dates"
                  mode="range"
                  datesSelected={[earliestDate, ...dates]}
                  earliestSelectableDate={earliestDate}
                  latestSelectableDate={latestDate}
                  determineDateType={recurringRideOptions}
                  onDateClick={endDate => (recurringRides.endDate = endDate)}
                />
              </div>

              {projectedExceededLimitsMessage && alertType && (
                <div style={{ width: '378px' }}>
                  <Alert type={alertType} label={projectedExceededLimitsMessage} />
                </div>
              )}
              <TripCards rides={rides} dates={dates} initialRideDate={earliestDate} />

              <div
                data-testid-="recurring-rides-footer"
                className="flex flex-row justify-center gap-[16px]"
              >
                <Button
                  label="Reset"
                  onClick={recurringRides.revert}
                  minWidth="124px"
                  size="sm"
                  alt
                />
                <Button
                  label="Confirm"
                  minWidth="124px"
                  size="sm"
                  onClick={handleConfirm}
                  disabled={!!projectedExceededLimitsMessage}
                />
              </div>
            </div>
          )}

          {!isActive && recurringRides?.endDate && (
            <TripCards
              rides={rides}
              dates={dates}
              initialRideDate={earliestDate}
              onEdit={() => appDispatch(editRecurringRides())}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

RecurringRides.sectionIndex = RIDE_SECTION_ORDER['recurring-rides'];

export { RecurringRides };
