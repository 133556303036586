import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getClassName } from '~/utilities/helperFunctions';
import ReactLoading from 'react-loading';
import SvgBackArrow from '~/Shared/Components/Svgs/SvgBackArrow';
import SvgEdit from '~/Shared/Components/Svgs/SvgEdit';
import { getInvoiceConfig, upsertInvoiceConfig } from '~/Modules/settings';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class PlanInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      showLoader: false,
      planName: '',
      planId: '',
      isEnabled: false,
      memberPortalEnabled: false,
      dsnpEnabled: false
    };
    this.handleSave = this.handleSave.bind(this);
  }

  getDefaultSelectedClaimSubmissionType = () =>
    this.props.selectedPlan?.claim_submission_types?.find(c => c.selected)
      ?.submission_type_id || null;

  componentDidMount() {
    const { selectedPlan, billingParameters } = this.props;

    this.setState({
      planName: selectedPlan.planName,
      planId: selectedPlan.planId,
      isEnabled: selectedPlan.isEnabled,
      memberPortalEnabled: selectedPlan.memberPortalEnabled,
      dsnpEnabled: selectedPlan.d_snp,
      wrapEnabled: selectedPlan.supports_wrap,
      dsnpBenefitsProgram: selectedPlan?.benefits_program ?? null,
      treatmentRestrictionEnabled: selectedPlan.treatmentRestrictionEnabled,
      treatmentsMemberPortalEnabled: selectedPlan.treatmentsMemberPortalEnabled,
      locationRestrictionEnabled: selectedPlan.locationRestrictionEnabled,
      approvedProvidersEnabled: selectedPlan.approvedProvidersEnabled,
      approvedProvidersHardBlockEnabled: selectedPlan.approvedProvidersHardBlockEnabled,
      waiveCancellations: !!selectedPlan?.waive_cancellations,
      selectedClaimSubmissionType: this.getDefaultSelectedClaimSubmissionType(),
      claimSubmissionTypes: selectedPlan.claim_submission_types,
      submissionDeadline: billingParameters.submissionDeadline,
      reviewPeriod: billingParameters.reviewPeriod,
      periodicity: billingParameters.periodicity,
      adjustmentsDeadline: billingParameters.adjustmentsDeadline
    });
  }

  componentDidUpdate(prevProps) {
    const { selectedPlan, billingParametersLastLoad, billingParameters } = this.props;
    const showLoader = this.state.showLoader;
    const prevSelectedPlan = prevProps.selectedPlan || {};
    const prevBillingParametersLastLoad = prevProps.billingParametersLastLoad;

    if (billingParametersLastLoad !== prevBillingParametersLastLoad) {
      this.setState({
        showLoader: false,
        submissionDeadline: billingParameters.submissionDeadline,
        reviewPeriod: billingParameters.reviewPeriod,
        periodicity: billingParameters.periodicity,
        adjustmentsDeadline: billingParameters.adjustmentsDeadline
      });
    }

    if (
      selectedPlan.updatedAt !== prevSelectedPlan.updatedAt &&
      showLoader &&
      billingParametersLastLoad !== prevBillingParametersLastLoad
    ) {
      this.setState({ showLoader: false });
    }
  }

  handleEdit() {
    this.setState({ showEdit: true });
  }

  handleCancel() {
    const selectedPlan = this.props.selectedPlan;

    this.setState({
      showEdit: false,
      planName: selectedPlan.planName,
      planId: selectedPlan.planId,
      isEnabled: selectedPlan.isEnabled,
      selectedClaimSubmissionType: this.getDefaultSelectedClaimSubmissionType()
    });
  }

  handleSave = () => {
    const { selectedPlan, updatePlan, upsertInvoiceConfig, billingParameters } =
      this.props;
    const {
      planName,
      planId,
      isEnabled,
      locationRestrictionEnabled,
      treatmentRestrictionEnabled,
      treatmentsMemberPortalEnabled,
      approvedProvidersEnabled,
      approvedProvidersHardBlockEnabled,
      waiveCancellations,
      memberPortalEnabled,
      dsnpEnabled,
      wrapEnabled,
      dsnpBenefitsProgram,
      selectedClaimSubmissionType,
      submissionDeadline,
      reviewPeriod,
      periodicity,
      adjustmentsDeadline
    } = this.state;

    this.setState(
      {
        showEdit: false,
        showLoader: true
      },
      () => {
        updatePlan({
          id: selectedPlan.id,
          planName,
          planId,
          isEnabled,
          locationRestrictionEnabled,
          treatmentRestrictionEnabled,
          treatmentsMemberPortalEnabled,
          approvedProvidersEnabled,
          approvedProvidersHardBlockEnabled,
          waiveCancellations,
          memberPortal: memberPortalEnabled,
          dsnpEnabled,
          wrapEnabled,
          dsnpBenefitsProgram,
          claimSubmissionTypeId: selectedClaimSubmissionType
            ? selectedClaimSubmissionType
            : null
        });

        upsertInvoiceConfig({
          healthPlanId: this.props.selectedPlan.health_plan_id,
          healthSubPlanId: this.props.selectedPlan.id,
          // eslint-disable-next-line eqeqeq
          ...(submissionDeadline !== undefined &&
            submissionDeadline != billingParameters.submissionDeadline && {
            submissionDeadline: Number(submissionDeadline)
          }),
          // eslint-disable-next-line eqeqeq
          ...(reviewPeriod !== undefined &&
            reviewPeriod != billingParameters.reviewPeriod && {
            reviewPeriod: Number(reviewPeriod)
          }),
          // eslint-disable-next-line eqeqeq
          ...(periodicity != billingParameters.periodicity && { periodicity }),
          // eslint-disable-next-line eqeqeq
          ...(adjustmentsDeadline !== undefined &&
            adjustmentsDeadline != billingParameters.adjustmentsDeadline && {
            adjustmentsDeadline: Number(adjustmentsDeadline)
          })
        });
      }
    );
  };

  render() {
    const { selectedPlan, showList, isSuperAdmin, healthPlan, billingParameters } =
      this.props;
    const {
      showEdit,
      showLoader,
      planName,
      planId,
      isEnabled,
      memberPortalEnabled,
      dsnpEnabled,
      wrapEnabled,
      dsnpBenefitsProgram,
      treatmentRestrictionEnabled,
      treatmentsMemberPortalEnabled,
      locationRestrictionEnabled,
      approvedProvidersEnabled,
      approvedProvidersHardBlockEnabled,
      waiveCancellations,
      selectedClaimSubmissionType,
      claimSubmissionTypes,
      submissionDeadline,
      reviewPeriod,
      periodicity,
      adjustmentsDeadline
    } = this.state;

    return (
      <div className="PlanInfo">
        <h2>
          {selectedPlan.planName}
          <span onClick={() => showList()}>
            <SvgBackArrow />
          </span>
        </h2>
        {showLoader ? (
          <ReactLoading type="spin" className="planLoading" />
        ) : (
          <div className="fields">
            {showEdit ? (
              <form onSubmit={this.handleSave.bind(this)} id="planForm">
                <ul>
                  <li className="toggleContainer">
                    <label>Status</label>
                    <div>
                      <span>{isEnabled ? 'Enabled' : 'Disabled'}</span>
                      <div
                        className={getClassName({
                          toggle: true,
                          enabled: isEnabled
                        })}
                        onClick={() => this.setState({ isEnabled: !isEnabled })}
                      >
                        <div className="toggleSwitch"></div>
                      </div>
                    </div>
                  </li>
                  {healthPlan.member_portal ? (
                    <li className="toggleContainer">
                      <label>Member Portal</label>
                      <div>
                        <span>{memberPortalEnabled ? 'Enabled' : 'Disabled'}</span>
                        <div
                          className={getClassName({
                            toggle: true,
                            enabled: memberPortalEnabled
                          })}
                          onClick={() =>
                            this.setState({ memberPortalEnabled: !memberPortalEnabled })
                          }
                        >
                          <div className="toggleSwitch"></div>
                        </div>
                      </div>
                    </li>
                  ) : null}
                  <li className="toggleContainer">
                    <label>D-SNP PROGRAM</label>
                    <div>
                      <span>{dsnpEnabled ? 'Enabled' : 'Disabled'}</span>
                      <div
                        className={getClassName({ toggle: true, enabled: dsnpEnabled })}
                        onClick={() => this.setState({ dsnpEnabled: !dsnpEnabled })}
                      >
                        <div className="toggleSwitch"></div>
                      </div>
                    </div>
                    {dsnpEnabled ? (
                      <div style={{ display: 'flex' }}>
                        <div>
                          <label
                            className={`checkboxLabel ${
                              dsnpBenefitsProgram ? '' : 'disabled'
                            }`}
                            htmlFor="dsnpEnabledMedicare"
                          >
                            Medicare
                          </label>
                          <input
                            type="radio"
                            name="dsnpEnabledMedicare"
                            className="dsnpEnabledMedicare"
                            checked={dsnpBenefitsProgram === 'medicare'}
                            disabled={!dsnpEnabled}
                            onClick={() =>
                              this.setState({ dsnpBenefitsProgram: 'medicare' })
                            }
                          />
                        </div>
                        <div style={{ paddingLeft: '25px' }}>
                          <label
                            className={`checkboxLabel ${
                              dsnpBenefitsProgram ? '' : 'disabled'
                            }`}
                            htmlFor="dsnpEnabledMedicaid"
                          >
                            Medicaid
                          </label>
                          <input
                            type="radio"
                            name="dsnpEnabledMedicaid"
                            className="dsnpEnabledMedicaid"
                            checked={dsnpBenefitsProgram === 'medicaid'}
                            disabled={!dsnpEnabled}
                            onClick={() =>
                              this.setState({ dsnpBenefitsProgram: 'medicaid' })
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </li>
                  <li className="toggleContainer">
                    <label>Wrap Program</label>
                    <div>
                      <span>{wrapEnabled ? 'Enabled' : 'Disabled'}</span>
                      <div
                        className={getClassName({
                          toggle: true,
                          enabled: wrapEnabled
                        })}
                        onClick={() => this.setState({ wrapEnabled: !wrapEnabled })}
                      >
                        <div className="toggleSwitch"></div>
                      </div>
                    </div>
                  </li>
                  <li className="toggleContainer">
                    <label>Treatments</label>
                    <div>
                      <span>{treatmentRestrictionEnabled ? 'Enabled' : 'Disabled'}</span>
                      <div
                        className={getClassName({
                          toggle: true,
                          enabled: treatmentRestrictionEnabled
                        })}
                        onClick={() =>
                          this.setState({
                            treatmentRestrictionEnabled: !treatmentRestrictionEnabled
                          })
                        }
                      >
                        <div className="toggleSwitch"></div>
                      </div>
                    </div>
                    {memberPortalEnabled ? (
                      <div>
                        <label
                          className={`checkboxLabel ${
                            treatmentRestrictionEnabled ? '' : 'disabled'
                          }`}
                          htmlFor="treatmentsMemberPortal"
                        >
                          Member Portal
                        </label>
                        <input
                          type="checkbox"
                          name="treatmentsMemberPortal"
                          className="approvedProvidersHardBlock"
                          checked={treatmentsMemberPortalEnabled}
                          disabled={!treatmentRestrictionEnabled}
                          onClick={() =>
                            this.setState({
                              treatmentsMemberPortalEnabled:
                                !treatmentsMemberPortalEnabled
                            })
                          }
                        />
                      </div>
                    ) : null}
                  </li>
                  <li className="toggleContainer">
                    <label>Locations</label>
                    <div>
                      <span>{locationRestrictionEnabled ? 'Enabled' : 'Disabled'}</span>
                      <div
                        className={getClassName({
                          toggle: true,
                          enabled: locationRestrictionEnabled
                        })}
                        onClick={() =>
                          this.setState({
                            locationRestrictionEnabled: !locationRestrictionEnabled
                          })
                        }
                      >
                        <div className="toggleSwitch"></div>
                      </div>
                    </div>
                  </li>
                  <li className="toggleContainer">
                    <label>Approved Providers</label>
                    <div>
                      <span>{approvedProvidersEnabled ? 'Enabled' : 'Disabled'}</span>
                      <div
                        className={getClassName({
                          toggle: true,
                          enabled: approvedProvidersEnabled
                        })}
                        onClick={() =>
                          this.setState({
                            approvedProvidersEnabled: !approvedProvidersEnabled
                          })
                        }
                      >
                        <div className="toggleSwitch"></div>
                      </div>
                    </div>
                    <label
                      className={`checkboxLabel ${
                        approvedProvidersEnabled ? '' : 'disabled'
                      }`}
                      htmlFor="approvedProvidersHardBlock"
                    >
                      Hard Block
                    </label>
                    <input
                      type="checkbox"
                      name="approvedProvidersHardBlock"
                      className="checkboxInput"
                      checked={approvedProvidersHardBlockEnabled}
                      disabled={!approvedProvidersEnabled}
                      onClick={() =>
                        this.setState({
                          approvedProvidersHardBlockEnabled:
                            !approvedProvidersHardBlockEnabled
                        })
                      }
                    />
                  </li>
                  <li className="toggleContainer">
                    <label htmlFor="waiveCancellations">
                      Waive Benefit Deduction For Canceled Rides with Cost
                    </label>
                    <div>
                      <span>{waiveCancellations ? 'Enabled' : 'Disabled'}</span>
                      <div
                        className={getClassName({
                          toggle: true,
                          enabled: waiveCancellations
                        })}
                        onClick={() =>
                          this.setState({ waiveCancellations: !waiveCancellations })
                        }
                      >
                        <div className="toggleSwitch"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <label>Plan Name</label>
                    <div>
                      <input
                        type="text"
                        value={planName}
                        onChange={e => this.setState({ planName: e.target.value })}
                      />
                    </div>
                  </li>
                  <li>
                    <label>Plan ID</label>
                    <div>
                      <input
                        type="text"
                        value={planId}
                        onChange={e => this.setState({ planId: e.target.value })}
                      />
                    </div>
                  </li>
                  <li>
                    <label>Claims Submission Type</label>
                    <div>
                      <select
                        value={selectedClaimSubmissionType || 'None'}
                        onChange={e =>
                          this.setState({
                            selectedClaimSubmissionType:
                              e.target.value === 'None' ? null : +e.target.value
                          })
                        }
                      >
                        <option key={-1} value="None">
                          None
                        </option>
                        {claimSubmissionTypes?.map(c => (
                          <option key={c.submission_type_id} value={c.submission_type_id}>
                            {c.submission_type_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </li>
                  <li>
                    {/* THIS IS FOR EDIT VIEW */}
                    <label>CLAIM SUBMISSION DEADLINE</label>
                    <div>
                      <input
                        type="number"
                        value={submissionDeadline}
                        min={0}
                        max={365}
                        onChange={e =>
                          this.setState({ submissionDeadline: e.target.value })
                        }
                      />
                    </div>
                  </li>
                  <li>
                    <label>INVOICE PERIOD</label>
                    <div>
                      <select
                        value={periodicity}
                        onChange={e => this.setState({ periodicity: e.target.value })}
                      >
                        <option value="Weekly">Weekly</option>
                        <option value="Semi-monthly">Semi-monthly</option>
                        <option value="Monthly">Monthly</option>
                      </select>
                    </div>
                  </li>
                  <li>
                    <label>INVOICE REVIEW PERIOD</label>
                    <div>
                      <input
                        type="number"
                        value={reviewPeriod}
                        min={0}
                        max={31}
                        onChange={e => this.setState({ reviewPeriod: e.target.value })}
                      />
                    </div>
                  </li>
                  <li>
                    <label>Adjustments Deadline</label>
                    <div>
                      <input
                        type="number"
                        value={adjustmentsDeadline}
                        min={0}
                        max={365}
                        onChange={e =>
                          this.setState({ adjustmentsDeadline: e.target.value })
                        }
                      />
                    </div>
                  </li>
                </ul>
              </form>
            ) : (
              <ul>
                <li>
                  <label>Status</label>
                  <div>{selectedPlan.isEnabled ? 'Enabled' : 'Disabled'}</div>
                </li>
                {healthPlan.member_portal ? (
                  <li>
                    <label>Member Portal</label>
                    <div>{memberPortalEnabled ? 'Enabled' : 'Disabled'}</div>
                  </li>
                ) : null}
                <li>
                  <label>D-SNP Program</label>
                  <div>{dsnpEnabled ? 'Enabled' : 'Disabled'}</div>
                  {dsnpEnabled ? (
                    <div>
                      <label
                        className={`checkboxLabel ${
                          dsnpBenefitsProgram ? '' : 'disabled'
                        }`}
                        htmlFor="dsnpBenefitsProgram"
                      >
                        {dsnpBenefitsProgram}
                      </label>
                    </div>
                  ) : null}
                </li>
                <li>
                  <label>Wrap Program</label>
                  <div>{wrapEnabled ? 'Enabled' : 'Disabled'}</div>
                </li>
                <li>
                  <label>Treatments</label>
                  <div>
                    {selectedPlan.treatmentRestrictionEnabled ? 'Enabled' : 'Disabled'}
                  </div>
                  {memberPortalEnabled ? (
                    <div>
                      <label
                        className={`checkboxLabel ${
                          treatmentRestrictionEnabled ? '' : 'disabled'
                        }`}
                        htmlFor="treatmentsMemberPortal"
                      >
                        Member Portal
                      </label>
                      <input
                        type="checkbox"
                        name="treatmentsMemberPortal"
                        className="checkboxInput"
                        checked={treatmentsMemberPortalEnabled}
                        disabled
                      />
                    </div>
                  ) : null}
                </li>
                <li>
                  <label>Locations</label>
                  <div>
                    {selectedPlan.locationRestrictionEnabled ? 'Enabled' : 'Disabled'}
                  </div>
                </li>
                <li>
                  <label>Approved Providers</label>
                  <div>
                    {selectedPlan.approvedProvidersEnabled ? 'Enabled' : 'Disabled'}
                  </div>
                  <div>
                    <label
                      className={`checkboxLabel ${
                        approvedProvidersEnabled ? '' : 'disabled'
                      }`}
                      htmlFor="approvedProvidersHardBlock"
                    >
                      Hard Block
                    </label>
                    <input
                      type="checkbox"
                      name="approvedProvidersHardBlock"
                      className="checkboxInput"
                      checked={approvedProvidersHardBlockEnabled}
                      disabled
                    />
                  </div>
                </li>
                <li>
                  <label>Waive Benefit Deduction For Canceled Rides with Cost</label>
                  <div>{selectedPlan?.waive_cancellations ? 'Enabled' : 'Disabled'}</div>
                </li>
                <li>
                  <label>Plan Name</label>
                  <div>{selectedPlan.planName}</div>
                </li>
                <li>
                  <label>Plan ID</label>
                  <div>{selectedPlan.planId}</div>
                </li>
                <li>
                  <label>Claims Submission Type</label>
                  <div>
                    {selectedPlan?.claim_submission_types
                      ? selectedPlan.claim_submission_types.find(
                        c => c.submission_type_id === selectedClaimSubmissionType
                      )?.submission_type_name || ''
                      : ''}
                  </div>
                </li>
                <li>
                  <label>CLAIM SUBMISSION DEADLINE</label>
                  <div className="billing">
                    {billingParameters.submissionDeadline + ' days'}
                    {
                      <span className="incomingFromItems">
                        {billingParameters.default
                          ? 'DEFAULT'
                          : 'FROM ' + billingParameters.incomingFrom?.submissionDeadline}
                      </span>
                    }
                  </div>
                </li>
                <li>
                  <label>INVOICE PERIOD</label>
                  <div className="billing">
                    {billingParameters.periodicity}
                    {
                      <span className="incomingFromItems">
                        {billingParameters.default
                          ? 'DEFAULT'
                          : 'FROM ' + billingParameters.incomingFrom?.periodicity}
                      </span>
                    }
                  </div>
                </li>
                <li>
                  <label>INVOICE REVIEW PERIOD</label>
                  <div className="billing">
                    {billingParameters.reviewPeriod + ' days'}
                    {
                      <span className="incomingFromItems">
                        {billingParameters.default
                          ? 'DEFAULT'
                          : 'FROM ' + billingParameters.incomingFrom?.reviewPeriod}
                      </span>
                    }
                  </div>
                </li>
                <li>
                  <label>Adjustments Deadline</label>
                  <div className="billing">
                    {billingParameters.adjustmentsDeadline + ' days'}
                    {
                      <span className="incomingFromItems">
                        {billingParameters.default
                          ? 'DEFAULT'
                          : 'FROM ' + billingParameters.incomingFrom?.adjustmentsDeadline}
                      </span>
                    }
                  </div>
                </li>
              </ul>
            )}
            {showEdit ? (
              <div className="actionButtons">
                <button className="cancel" onClick={() => this.handleCancel()}>
                  Cancel
                </button>
                <button type="submit" form="planForm" className="save">
                  Save
                </button>
              </div>
            ) : isSuperAdmin ? (
              <div className="actionButtons">
                <span className="edit" onClick={() => this.handleEdit()}>
                  <SvgEdit />
                </span>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

PlanInfo.propTypes = {
  selectedPlan: PropTypes.object,
  showList: PropTypes.func,
  updatePlan: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
  healthPlan: PropTypes.object,
  getInvoiceConfig: PropTypes.func,
  upsertInvoiceConfig: PropTypes.func,
  billingParameters: PropTypes.object,
  billingParametersLastLoad: PropTypes.number
};

PlanInfo.defaultProps = {
  selectedPlan: {},
  showList: () => {},
  updatePlan: () => {},
  isSuperAdmin: false,
  healthPlan: {},
  getInvoiceConfig: () => {},
  upsertInvoiceConfig: () => {},
  billingParameters: {},
  billingParametersLastLoad: null
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInvoiceConfig: data => getInvoiceConfig(data),
      upsertInvoiceConfig: data => upsertInvoiceConfig(data)
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PlanInfo);
