import {
  createModelFactory,
  DAYS_OF_WEEK,
  Is,
  type DateString,
  type DayOfWeek,
  type ModelType
} from '@SRHealth/frontend-lib';
import {
  RECURRING_RIDES_CADENCE,
  type RecurringRideCadence
} from '../Modules/rideBooking';

export type RecurringRidePatternProps = {
  endDate: DateString | undefined;
  cadence: 'weekly' | 'everyTwoWeeks' | 'everyThreeWeeks' | 'everyFourWeeks' | undefined;
  daysOfWeek: DayOfWeek[];
};

export type RecurringRidePatternModel = ModelType<RecurringRidePatternProps>;

export const RECURRING_RIDE_PATTERN_DEFAULT = (
  props: Partial<RecurringRidePatternProps> = {}
): RecurringRidePatternProps => ({
  endDate: undefined,
  cadence: 'weekly',
  daysOfWeek: [],
  ...props
});

export const recurringRidePatternFactory = createModelFactory<RecurringRidePatternModel>(
  RECURRING_RIDE_PATTERN_DEFAULT(),
  {
    properties: [
      ['is-date-string', 'endDate', Is.DateString.strict],
      [
        'is-cadence',
        'cadence',
        (v: RecurringRideCadence) => RECURRING_RIDES_CADENCE.includes(v)
      ],
      ['is-array', 'daysOfWeek', Is.JsonArray.strict],
      [
        'is-day-of-week-array',
        'daysOfWeek',
        (v: DayOfWeek[]) => v.every(d => d && DAYS_OF_WEEK.includes(d))
      ]
    ],
    model: []
  }
);
