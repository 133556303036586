import { Datetime, type InputOption } from '@SRHealth/frontend-lib';
import { useMemo } from 'react';
import type { TimeRestrictions } from '~/Modules/rideBooking';
import type { RideType } from '~/models';
import {
  minutesToMidnight,
  minutesUntilDate,
  timezoneOffsetFromUser
} from '~/utilities/timesAndDates';
import { useAppSelector } from '~/Modules';

/** Calculate the offset from the start of the day, in minutes. Accounts
 * for differences between the target address time zone and the user's
 * time zone.
 * @param timezone This should be returned from a lookup in mapbox
 * @param restrictionOffset This should be the offset value returned
 * from the app-layer-rbf getAvailableTimes endpoint.
 */
function getOffset(timezone: string, date: DateString, restrictionOffset = 0): number {
  const now = new Date();
  const currentTime = now.getMinutes() + now.getHours() * 60;

  const minutesToBookingDate = minutesUntilDate(date, true);
  const diffBetweenTargetAndUser = timezoneOffsetFromUser(timezone);

  // Take the offset and adjust it by the different between target timezone
  // and user timezone.
  let offset = restrictionOffset + diffBetweenTargetAndUser;

  // If the booking date is further out than the offset, return 0.
  if (minutesToBookingDate > Math.abs(offset)) return 0;

  // If the booking date is for more than 24 hours in the past, return 0.
  if (minutesToBookingDate < -1 * 60 * 24) return 0;

  // If the offset is less than a day, we need to add the current time
  if (offset < minutesToMidnight()) offset += currentTime;

  return offset - (minutesToBookingDate < 0 ? 0 : minutesToBookingDate);
}

/** Create an array of intervals for available booking times based on the
 * step size (defaults to 10 minutes), target timezone and buffer offset. */
function getIntervals(offset: number, timezone: string, interval = 10) {
  const formattedTimezone = Datetime.convertToShortTimezone(timezone);

  // Total number of whole hours to offset by.
  let hourOffset = Math.floor(offset / 60);
  // The remaining minutes after the hour offset, divided by the interval.
  let intervalOffset = Math.ceil((offset % 60) / interval);

  // If the interval offset is the max number of intervals in an hour
  // we need to offset by an hour and not an interval step.
  if (intervalOffset === Math.floor(60 / interval)) {
    hourOffset += 1;
    intervalOffset = 0;
  }

  return Array.from(
    /** Create an array of the remaining intervals in the day.
     *
     * First determine how many 60 minutes periods remain in the day.
     *    (24 - hourOffset) * 60 = minutes remaining in the day.
     *
     * Convert the minutes remaining in the day to intervals.
     *    Total Intervals = (minutes in a day) / (minutes in an interval).
     *
     * Then we subtract the interval offset from the total number of intervals.
     *   Starting Interval = Total Intervals - Interval Offset
     *
     */
    Array(((24 - hourOffset) * 60) / interval - intervalOffset),
    (_, i) => {
      const _i = i + intervalOffset;

      const hour = Math.floor(_i / (60 / interval)) + hourOffset;
      const minutes = (_i % (60 / interval)) * 10 || '00';
      const meridiem = hour >= 12 ? 'pm' : 'am';

      let displayHour = hour || '12';
      if (hour > 12) displayHour = hour - 12;

      return {
        label: `${displayHour}:${minutes} ${meridiem}, ${formattedTimezone}`,
        value: `${displayHour}:${minutes} ${meridiem}`
      };
    }
  );
}

export const useTimeOptions = (
  type: RideType,
  timezone: string | undefined,
  date: DateString
) => {
  const timeRestrictions = useAppSelector(s => s.rideBooking.meta.timeRestrictions);

  const options = useMemo(() => {
    const options: InputOption[] = [];

    const restrictions: Partial<TimeRestrictions> =
      timeRestrictions && type ? timeRestrictions[type] : {};

    if (restrictions?.flex) {
      options.push({ label: 'Flexible Pick Up', value: 'Flex Time' });
    }

    if (restrictions?.now) {
      options.push({ label: 'Now', value: 'Now' });
    }

    if (!timezone) return options;

    // The offset is the number of minutes from the start of the booking day.
    const offset = getOffset(timezone, date!, restrictions?.offset);
    const intervals = getIntervals(offset, timezone, restrictions?.interval);

    return options.concat(intervals);
  }, [timeRestrictions, date, type, timezone]);

  return options;
};
