import { put, takeEvery, call } from 'redux-saga/effects';
import _ from 'lodash-es';
import axios from './safeAxios';
import { token } from '~/utilities/auth.helper';
import { cloneDeep } from '~/utilities/helperFunctions';
import {
  getInvoiceConfig as getInvoiceConfigService,
  upsertInvoiceConfig as upsertInvoiceConfigService
} from '~/services/invoiceConfig.service';

export const SETTINGS_CUSTOMFIELDS_GET = 'SETTINGS/customFields/get';
export const SETTINGS_CUSTOMFIELDS_GET_SUCCESS = 'SETTINGS/customFields/get/success';
export const SETTINGS_CUSTOMFIELDS_GET_ERROR = 'SETTINGS/customFields/get/error';
export const SETTINGS_CUSTOMFIELDS_PUT = 'SETTINGS/customFields/put';
export const SETTINGS_CUSTOMFIELDS_PUT_SUCCESS = 'SETTINGS/customFields/put/success';
export const SETTINGS_CUSTOMFIELDS_PUT_ERROR = 'SETTINGS/customFields/put/error';
export const SETTINGS_CUSTOMFIELDS_DELETE = 'SETTINGS/customFields/delete';
export const SETTINGS_CUSTOMFIELDS_DELETE_SUCCESS =
  'SETTINGS/customFields/delete/success';
export const SETTINGS_CUSTOMFIELDS_DELETE_ERROR = 'SETTINGS/customFields/delete/error';
export const SETTINGS_INVOICE_CONFIG_GET = 'SETTINGS/invoiceConfig/get';
export const SETTINGS_INVOICE_CONFIG_GET_SUCCESS = 'SETTINGS/invoiceConfig/get/success';
export const SETTINGS_INVOICE_CONFIG_GET_ERROR = 'SETTINGS/invoiceConfig/get/error';
export const SETTINGS_INVOICE_CONFIG_UPSERT = 'SETTINGS/invoiceConfig/upsert';
export const SETTINGS_INVOICE_CONFIG_UPSERT_SUCCESS =
  'SETTINGS/invoiceConfig/upsert/success';
export const SETTINGS_INVOICE_CONFIG_UPSERT_ERROR = 'SETTINGS/invoiceConfig/upsert/error';

/**
 * Get a list of all custom fields for this plan
 * @param   {Object}  params action dispatch params
 * @returns {Function}  dispatch action
 */
export const getCustomFields = (params = {}) => {
  return dispatch => {
    dispatch({
      type: SETTINGS_CUSTOMFIELDS_GET,
      data: params
    });
  };
};

/**
 * Call to initiate API call to insert/update a custom field
 * @param {Object} params Data for object to create
 * @returns {Function} dispatch action
 */
export const postCustomField = (params = {}) => {
  return dispatch => {
    dispatch({
      type: SETTINGS_CUSTOMFIELDS_PUT,
      data: params
    });
  };
};

/**
 * Call to initiate API call to delete a custom field
 * @param {Object} params
 * @returns {Function} dispatch action
 */
export const deleteCustomField = (params = {}) => {
  return dispatch => {
    dispatch({
      type: SETTINGS_CUSTOMFIELDS_DELETE,
      data: params
    });
  };
};

/**
 * Gets custom fields per sub-plan
 * @param {*} action - Contains sub plan id to get custom fields for
 * @returns {Generator} - yields
 */

function* getCustomFieldsFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'GET',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/custom-fields`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };

    const results = yield axios(config);
    const data = results.data.data.map(record => {
      const newRecord = cloneDeep(record);
      newRecord.loadedAt = Date.now();
      return newRecord;
    });

    yield put({ type: SETTINGS_CUSTOMFIELDS_GET_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: SETTINGS_CUSTOMFIELDS_GET_ERROR, error });
  }
}

/**
 * Updates a custom field in the db via api
 * @param {Object} action - Action with params to update custom field
 * @returns {Generator} - yields
 */
function* putCustomFieldFromApi(action = {}) {
  const params = _.pick(action.data, ['id', 'label', 'field_type', 'selectors']);
  try {
    let config = {};
    if (_.get(action, 'data.id', false) === false) {
      config = {
        method: 'POST',
        url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/custom-fields`,
        data: params,
        headers: {
          Authorization: `Bearer ${token()}`
        }
      };
    } else {
      config = {
        method: 'PATCH',
        url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/custom-fields/${params.id}`,
        data: params,
        headers: {
          Authorization: `Bearer ${token()}`
        }
      };
    }

    const results = yield axios(config);
    const data = results.data.data;

    yield put({ type: SETTINGS_CUSTOMFIELDS_PUT_SUCCESS, data, params });
    yield put({ type: SETTINGS_CUSTOMFIELDS_GET, data: {} }); //Reload custom fields
  } catch (error) {
    yield put({ type: SETTINGS_CUSTOMFIELDS_PUT_ERROR, error });
    yield put({ type: SETTINGS_CUSTOMFIELDS_GET, data: {} }); //Reload custom fields
  }
}

/**
 * Calls API to remove a custom field
 * @param {Object} action - Contains information on which Custom field to delete
 * @returns {Generator} - yields
 */
function* deleteCustomFieldFromApi(action = {}) {
  const params = action?.data ?? {};
  try {
    const config = {
      method: 'DELETE',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/custom-fields/${params.id}`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };

    yield axios(config);
    yield put({ type: SETTINGS_CUSTOMFIELDS_DELETE_SUCCESS, data: {}, params });
    yield put({ type: SETTINGS_CUSTOMFIELDS_GET }); //Reload custom fields
  } catch (error) {
    yield put({ type: SETTINGS_CUSTOMFIELDS_DELETE_ERROR, error });
  }
}

/**
 * Calls service API to get invoice config for the specified health plan or subplan
 * @param {Object} action - Contains information on which health plan or subplan to retrieve
 * @returns {Generator} - yields
 */
function* getInvoiceConfigFromApi(action = {}) {
  const payload = action?.data;

  if (payload) {
    try {
      const response = yield call(getInvoiceConfigService, payload);
      yield put({ type: SETTINGS_INVOICE_CONFIG_GET_SUCCESS, data: response });
    } catch (error) {
      yield put({ type: SETTINGS_INVOICE_CONFIG_GET_ERROR, error });
    }
  }
}

/**
 * Calls service API to upsert invoice config for the specified health plan or subplan
 * @param {Object} action - Contains invoice config data to be added or updated for the health plan or subplan
 * @returns {Generator} - yields
 */
function* upsertInvoiceConfigFromApi(action = {}) {
  const payload = action?.data;

  if (payload) {
    try {
      yield call(upsertInvoiceConfigService, payload);
      yield put({ type: SETTINGS_INVOICE_CONFIG_GET, data: { ...payload } });
    } catch (error) {
      yield put({ type: SETTINGS_INVOICE_CONFIG_UPSERT_ERROR, error });
    }
  }
}

/**
 * Get invoice config for the specified health plan or subplan
 * @param   {Object}  params action dispatch params
 * @returns {Function}  dispatch action
 */
export const getInvoiceConfig = (params = {}) => {
  return dispatch => {
    dispatch({
      type: SETTINGS_INVOICE_CONFIG_GET,
      data: params
    });
  };
};

/**
 * Upsert invoice config for the specified health plan or subplan
 * @param   {Object}  params action dispatch params
 * @returns {Function}  dispatch action
 */
export const upsertInvoiceConfig = (params = {}) => {
  return dispatch => {
    dispatch({
      type: SETTINGS_INVOICE_CONFIG_UPSERT,
      data: params
    });
  };
};

/**
 * Saga generator function for all plans related API calls
 * @returns {undefined}
 */
export function* settingsSaga() {
  yield takeEvery(SETTINGS_CUSTOMFIELDS_GET, getCustomFieldsFromApi);
  yield takeEvery(SETTINGS_CUSTOMFIELDS_PUT, putCustomFieldFromApi);
  yield takeEvery(SETTINGS_CUSTOMFIELDS_DELETE, deleteCustomFieldFromApi);
  yield takeEvery(SETTINGS_INVOICE_CONFIG_GET, getInvoiceConfigFromApi);
  yield takeEvery(SETTINGS_INVOICE_CONFIG_UPSERT, upsertInvoiceConfigFromApi);
}

/**
 * Reducer function for dispatched actions
 * @param   {Object}    state Redux state
 * @param   {Object}    action Redux action
 * @returns {undefined}
 */
export const settingsReducer = (state = {}, action = {}) => {
  const newState = _.cloneDeep(state);

  switch (action.type) {
    case SETTINGS_CUSTOMFIELDS_GET: {
      newState.customFields = [];
      return newState;
    }

    case SETTINGS_CUSTOMFIELDS_GET_SUCCESS: {
      newState.customFields = action.data;
      newState.customFieldsLastLoad = Date.now();
      return newState;
    }

    case SETTINGS_INVOICE_CONFIG_GET_SUCCESS: {
      newState.billingParameters = action.data.data;
      newState.billingParametersLastLoad = Date.now();
      return newState;
    }

    default: {
      return state;
    }
  }
};
