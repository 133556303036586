import { type TransportPreferencesState } from './TransportPreferences';
import type { VehicleCompany } from '~/types';
import type {
  RidesharePreferenceNickName,
  MobilityFormMemberData,
  MobilityForm,
  MemberProfileStore
} from '~/Modules/memberProfile';

/**
 * Utility function to extract current value and possible
 * options from a FormDropdown data structure
 * @param {key} field
 * @param {MobilityFormMemberData} mobilityData
 * @returns {object}
 */
function getSelectedDropdownValue(
  field: keyof MobilityFormMemberData,
  mobilityData: Partial<MobilityForm>
) {
  if (!(field in mobilityData)) return undefined;

  const currentVal = mobilityData?.memberData?.[field];
  const options = mobilityData?.[field]?.values ?? [];

  if (!currentVal || !Array.isArray(options) || !options.length) return;

  return options.find(opt => opt.id === currentVal);
}

/**
 * The backend stores the default mobility as a numeric rather than
 * as one of the values included as options. This converts the
 * stored value to an entry on the MobilityForm's options
 * @param {MobilityFormMemberData} memberData
 * @param {MobilityForm} mobilityData
 * @returns {object | undefined}
 */
export function getDefaultMobility(
  mobilityData: Partial<MobilityForm>
): MobilityForm['member_default_mode']['values'][number] | undefined {
  const transportModes = mobilityData?.member_default_mode?.values ?? [];
  const val = getSelectedDropdownValue('member_default_mode', mobilityData);

  return val || transportModes[0];
}

/**
 * The backend stores the primary preferred nemt as a partial
 * VehicleCompany consisting only of name and id. This converts the
 * incomplete record to it's matching entry on the MobilityForm's
 * options
 * @param {MobilityFormMemberData} memberData
 * @param {VehicleCompany[]} vehicleCompanies
 * @returns {object | undefined}
 */
export function getPrimaryPreferredNEMT(
  memberData: Partial<MobilityFormMemberData>,
  vehicleCompanies: VehicleCompany[]
): TransportPreferencesState['primary_preferred_nemts'] {
  const currentVal = memberData?.primary_preferred_nemts ?? [];

  if (!currentVal.length || !vehicleCompanies.length || !currentVal[0]) return null;

  return vehicleCompanies.find(({ id }) => id === currentVal[0].id) ?? null;
}

/**
 * The backend stores the secondary preferred nemt as a partial
 * VehicleCompany consisting only of name and id. This converts the
 * incomplete record to it's matching entry on the MobilityForm's
 * options
 * @param {MobilityFormMemberData} memberData
 * @param {VehicleCompany[]} vehicleCompanies
 * @returns {object | undefined}
 */
export function getSecondaryPreferredNEMT(
  memberData: Partial<MobilityFormMemberData>,
  vehicleCompanies: VehicleCompany[]
): TransportPreferencesState['secondary_preferred_nemts'] {
  const currentVal = memberData?.secondary_preferred_nemts ?? [];

  if (!currentVal.length || !vehicleCompanies.length || !currentVal[0]) return null;

  return vehicleCompanies.find(({ id }) => id === currentVal[0].id) ?? null;
}

/**
 * The backend stores the preferred rideshare as a partial
 * VehicleCompany consisting only of name and id. This converts the
 * incomplete record to it's matching entry on the MobilityForm's
 * options
 * @param {MobilityFormMemberData} memberData
 * @param {MobilityForm} mobilityData
 * @returns {object | undefined}
 */
export function getRidesharePreference(
  mobilityData: Partial<MobilityForm>
): TransportPreferencesState['member_rideshare_preference'] {
  const currentVal = mobilityData?.memberData?.member_rideshare_preference;
  const options = mobilityData?.member_rideshare_preference?.values ?? [];

  if (!options.length) return;

  if (currentVal === undefined) {
    const defaultVal = mobilityData?.member_rideshare_preference?.default;

    return defaultVal !== undefined ? options[defaultVal] : options[0];
  }

  return options.find(opt => opt.nickName === currentVal);
}

/**
 * The backend stores flagged nemts as a partial VehicleCompany
 * consisting only of name and id. This converts the inclomplete
 * record to it's matching entry on the MobilityForm's options
 * @param {MobilityFormMemberData} memberData
 * @param {VehicleCompany[]} vehicleCompanies
 * @returns {array}
 */
export function getFlaggedNEMTs(
  memberData: Partial<MobilityFormMemberData>,
  vehicleCompanies: VehicleCompany[]
): TransportPreferencesState['flagged_nemts'] {
  const currentVal = memberData?.flagged_nemts ?? [];

  return (
    currentVal
      // eslint-disable-next-line eqeqeq
      .map(flaggedNemt => vehicleCompanies.find(({ id }) => id == flaggedNemt.id))
      .filter(flaggedNemt => flaggedNemt) as VehicleCompany[]
  );
}

/**
 * Extracts the initial state from the Member data and returns
 * an initial state. Populates default values for undefined keys.
 * @param {MemberProfileStore} member
 * @returns
 */
export function createInitialState(
  member: MemberProfileStore,
  vehicleCompanies: VehicleCompany[] = []
): TransportPreferencesState {
  const mobilityData: Partial<MobilityForm> = member?.formData?.mobility ?? {};
  const memberData: Partial<MobilityFormMemberData> = mobilityData?.memberData ?? {};

  const obj = {
    member_default_mode: getDefaultMobility(mobilityData),
    member_flag: getSelectedDropdownValue('member_flag', mobilityData),
    member_ride_alone: getSelectedDropdownValue('member_ride_alone', mobilityData),
    member_manual_schedule: getSelectedDropdownValue(
      'member_manual_schedule',
      mobilityData
    ),
    member_rideshare_preference: getRidesharePreference(mobilityData),
    primary_preferred_nemts: getPrimaryPreferredNEMT(memberData, vehicleCompanies),
    secondary_preferred_nemts: getSecondaryPreferredNEMT(memberData, vehicleCompanies),
    flagged_nemts: getFlaggedNEMTs(memberData, vehicleCompanies)
  };

  return obj;
}

export type ReducedVehicleCompany = {
  id: VehicleCompany['id'];
  name: VehicleCompany['companyName'];
};

/**
 * Condenses the full record down to just the id and companyName as "name"
 * @param {vehicleCompany} vehicle
 * @returns {object}
 */
export function reduceVehicleCompanyRecord(
  vehicle?: VehicleCompany | null
): ReducedVehicleCompany | undefined {
  return vehicle ? { id: vehicle.id, name: vehicle.companyName } : undefined;
}

/**
 * Formatted TransportPreferencesState to be used when the member
 * data over API
 */
export type TransportPreferencesUpdate = {
  member_flag: number | undefined;
  member_ride_alone: number | undefined;
  member_default_mode: number | undefined;
  member_manual_schedule: number | undefined;
  member_rideshare_preference: RidesharePreferenceNickName | undefined;
  primary_preferred_nemts: [ReducedVehicleCompany] | [];
  secondary_preferred_nemts: [ReducedVehicleCompany] | [];
  flagged_nemts: ReducedVehicleCompany[];
};

/**
 * Converts from the standardized vehicle records used in the form
 * @param {TransportPreferencesState} state
 * @returns {object}
 */
export function formatProfileUpdate(state: TransportPreferencesState) {
  return {
    member_flag: state.member_flag?.id,
    member_ride_alone: state.member_ride_alone?.id,
    member_default_mode: state.member_default_mode?.id,
    member_manual_schedule: state.member_manual_schedule?.id,
    member_rideshare_preference: state?.member_rideshare_preference?.nickName,
    primary_preferred_nemts: [
      reduceVehicleCompanyRecord(state.primary_preferred_nemts)
    ].filter(r => r),
    secondary_preferred_nemts: [
      reduceVehicleCompanyRecord(state.secondary_preferred_nemts)
    ].filter(r => r),
    flagged_nemts: state.flagged_nemts.map(reduceVehicleCompanyRecord).filter(r => r)
  } as TransportPreferencesUpdate;
}

/**
 * Takes a default mode id and returns the full option
 * @param {number} id
 * @param {object} mobilityData
 * @returns {object}
 */
export function getDefaultModeValueFromId(
  id: number | undefined,
  mobilityData: MobilityForm
) {
  if (id === undefined) return undefined;

  const options = mobilityData?.member_default_mode?.values ?? [];

  const selected = options.find(opt => opt.id === id);

  return selected ? selected.value : undefined;
}
