import moment from 'moment';
import type { PersonalInfoForm } from '~/Modules/memberProfile';

interface MapPhoneNumberPayload {
  personalInfo: PersonalInfoForm;
  state: {
    phone2: string;
    mobileNo: string;
    opt_out?: 0 | 1;
    opt_out_prompt_timestamp?: undefined;
    member_primary_phone_opt_outId: number;
    member_secondary_phone_opt_outId: number;
  };
}

export const mapMemberPhoneNumberUpdateRequest = ({
  personalInfo,
  state
}: MapPhoneNumberPayload) => {
  const {
    memberPhoneNumbers = [],
    secondaryOptOutValues = [],
    primaryOptOutValues = []
  } = personalInfo;
  const { mobileNo: primaryNumber, phone2: secondaryNumber } = state;

  const timestamp = moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS');
  const localStatePhoneNumbers = [{ phone_number: primaryNumber, is_primary: 1 }].concat([
    { phone_number: secondaryNumber, is_primary: 0 }
  ]);
  const filteredNumbers = localStatePhoneNumbers.map(ph => {
    return {
      ...ph,
      ...memberPhoneNumbers.find(
        mp => mp.phone_number === ph.phone_number && mp.is_primary === ph.is_primary
      )
    };
  });
  return filteredNumbers
    .map(p => {
      if (p.is_primary) {
        const hasPhoneId = p.phone_number === primaryNumber ? p.id : null;
        const primaryStateKey =
          state.member_primary_phone_opt_outId ||
          Object.hasOwn(state, 'member_primary_phone_opt_outTimestamp');
        return {
          id: hasPhoneId || null,
          phone_number: primaryNumber,
          is_primary: 1,
          opt_out:
            (state?.opt_out ??
            primaryOptOutValues.includes(state.member_primary_phone_opt_outId))
              ? 1
              : 0,
          opt_out_prompt_timestamp: primaryStateKey ? timestamp : null
        };
      } else if (state.phone2) {
        const hasPhoneId = p?.phone_number === secondaryNumber ? p.id : null;
        const secondaryStateKey =
          state.member_secondary_phone_opt_outId ||
          Object.hasOwn(state, 'member_secondary_phone_opt_outTimestamp');
        return {
          id: hasPhoneId || null,
          phone_number: secondaryNumber,
          is_primary: 0,
          opt_out:
            (state?.opt_out ??
            secondaryOptOutValues.includes(state?.member_secondary_phone_opt_outId))
              ? 1
              : 0,
          opt_out_prompt_timestamp: secondaryStateKey ? timestamp : null
        };
      }
    })
    .filter(p => p?.phone_number !== null)
    .filter(Boolean);
};
