import React from 'react';
import { Icon, Input, TOKENS, useModel } from '@SRHealth/frontend-lib';
import type { ProviderPublicModel, PublicCardModel } from '~/models';
import type { PublicCardHookProps } from '~/Pages/RideBooking/Hooks/usePublic';

type PublicCardProps = {
  cardModel: PublicCardModel;
  provider: ProviderPublicModel;
  nameOptions: PublicCardHookProps[0];
  quantityOptions: PublicCardHookProps[1];
  onCardDelete?: () => void;
};

export default function PublicCard({
  cardModel,
  nameOptions,
  quantityOptions,
  onCardDelete
}: PublicCardProps) {
  const { model } = useModel(cardModel);

  return (
    <div
      className={
        `flex w-full gap-[16px] py-[16px] ` +
        `border-b-1 border-b-solid border-${TOKENS.BORDER.NEUTRAL_LT}`
      }
    >
      <Input
        name="name"
        inputId={0}
        type="select"
        label="Card type"
        options={nameOptions}
        value={model.name}
        placeholder="Select One"
        style={{ width: '50%' }}
        onChange={(_, val: string) => (model.name = val)}
        error={'name' in model.ruleErrors}
        required
      />

      <Input
        name="cost"
        inputId={1}
        type="single"
        label="Cost"
        value={`$${model.cost}`}
        placeholder="$0.00"
        style={{ width: '20%' }}
        onChange={(_, val: string) => {
          model.cost = '0.00';
          model.cost = val.substring(1);
        }}
        error={'cost' in model.ruleErrors}
        required
      />

      <Input
        name="quantity"
        inputId={2}
        type="select"
        label="Quantity"
        options={quantityOptions}
        value={model.quantity}
        placeholder="1"
        style={{ width: '20%' }}
        onChange={(_, val: number) => (model.quantity = val)}
        error={'quantity' in model.ruleErrors}
        required
      />

      {onCardDelete && (
        <Icon
          type="Close"
          className={`cursor-pointer fill-current w-[24px] text-${TOKENS.ICON.ACTIVE}`}
          onClick={onCardDelete}
        />
      )}
    </div>
  );
}
