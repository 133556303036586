import React from 'react';
import type { RideProps } from '~/models';
import Divider from './Divider';
import { Button, Datetime, Icon, TOKENS } from '@SRHealth/frontend-lib';
import InitialRideSummaryAddress from './InitialRideSummaryAddress';
import RideLayout from './RideLayout';
import { bufferPickupTime } from '../Rides.utils';
import RideLimitWarning from './RideLimitWarning';
import { selectRemainingRidesAllowed } from '~/Modules/rideBooking/selectors/selectRemainingRidesAllowed';
import { useAppSelector } from '~/Modules';

export type InitialRideSummaryProps = {
  /** The ride to render. */
  ride: RideProps;
  onEdit: () => void;
};

export default function InitialRideSummary({ ride, onEdit }: InitialRideSummaryProps) {
  const estimatedPickupTime =
    ride.type === 'departAt' ? ride.time : bufferPickupTime(ride.time, ride.duration!);

  const [remainingRidesAllowed, isHardBlock] = useAppSelector(
    selectRemainingRidesAllowed
  );

  return (
    <RideLayout label="Initial Ride">
      <article
        className="reset-div relative py-[24px] px-[8px] flex flex-col gap-[8px] self-center"
        style={{ width: '436px' }}
      >
        <InitialRideSummaryAddress type="depart" address={ride.departAddress} />
        <InitialRideSummaryAddress type="arrive" address={ride.arriveAddress} />
        <Icon type="PinLine" className="absolute" style={{ left: '19px', top: '62px' }} />
        <RideLimitWarning
          currentRemainingRides={remainingRidesAllowed}
          isHardBlock={isHardBlock}
        />
        <div
          className={`reset-div flex flex-col py-[8px] border-t border-${TOKENS.BORDER.NEUTRAL_MD}`}
        >
          <span className={`heading-sm text-${TOKENS.FONT.PRIMARY}`}>Distance</span>
          <div>{ride.distance} Miles</div>
        </div>
        <div
          className={`reset-div flex flex-row py-[8px] border-t border-${TOKENS.BORDER.NEUTRAL_MD}`}
        >
          <div className="flex-1 flex flex-col gap-[4px]">
            <span className={`heading-sm text-${TOKENS.FONT.PRIMARY}`}>Pickup Time</span>
            <div className="reset-div text-md">
              {`${estimatedPickupTime}, ${Datetime.convertToShortTimezone(ride.timezone!)}`}
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-[4px]">
            <span className={`heading-sm text-${TOKENS.FONT.PRIMARY}`}>
              Appointment Time
            </span>
            <div className="reset-div text-md">
              {ride.type === 'arriveBy' &&
                `${ride.time}, ${Datetime.convertToShortTimezone(ride.timezone!)}`}
            </div>
          </div>
        </div>
        <div
          className={`reset-div flex flex-col py-[8px] border-t border-${TOKENS.BORDER.NEUTRAL_MD}`}
        >
          <span className={`heading-sm text-${TOKENS.FONT.PRIMARY}`}>Ride Note</span>
          <div className="reset-div text-md py-[4px]">{ride.notes || 'None'}</div>
        </div>
      </article>

      <footer
        className="flex flex-col px-[8px] items-center gap-[8px] self-stretch"
        style={{ paddingBottom: '24px' }}
      >
        <Divider />
        <div className={`text-sm-italic text-center text-${TOKENS.FONT.NEUTRAL}`}>
          Editing the initial ride will remove the return ride
        </div>
        <Button
          id="edit-ride"
          label="Edit Ride"
          iconLeft="Edit"
          size="sm"
          onClick={onEdit}
          alt
        />
      </footer>
    </RideLayout>
  );
}
