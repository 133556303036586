import { createAppAsyncThunk } from '~/Modules';

export const completeTransportProviderThunk = createAppAsyncThunk(
  'rideBooking/completeTransportProvider',
  (_, { getState, dispatch: _dispatch }) => {
    const transportProvider = getState().rideBooking.transportProvider;

    if (!transportProvider) throw Error('No transport provider found');
    // TODO: Actually book the ride and navigate throw modal, etc
    return transportProvider.commit();
  }
);
