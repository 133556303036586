import { createSelector } from '@reduxjs/toolkit';
import { selectTreatments } from '~/Modules/patients';
import type { AppStore } from '~/types';

const selectTreatmentId = (s: AppStore) => s.rideBooking.fundingSource.treatmentId;

export const selectActiveTreatment = createSelector(
  [selectTreatments, selectTreatmentId],
  (treatments, treatmentId) =>
    treatmentId ? treatments?.find(t => t.id === treatmentId) : undefined
);
