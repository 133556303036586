import React, { useEffect } from 'react';
import MobilityAssessment from './Forms/MobilityAssessment';
import TransportPreferences from './Forms/TransportPreferences';
import { type FormState } from '~/Shared/Components/Form/Form';
import { submitMobilityAssessment } from '~/services/member.service';
import { useDispatch } from 'react-redux';
import { getMemberRidesharePreferences } from '~/Modules/memberProfile/actions';
import ConfirmMobilityModalContent from './MobilityAssessmentModal/ConfirmMobilityModalContent';
import {
  getDefaultMobility,
  getDefaultModeValueFromId,
  type TransportPreferencesUpdate
} from './Forms/TransportPreferences.helper';
import type { App } from 'contracts/mod';
import type { MemberDefaultMode, VehicleCompany } from '~/types';
import type {
  RidesharePreferenceNickName,
  MobilityFormMemberData,
  MemberProfileStore
} from '~/Modules/memberProfile';
import { getRidesharePreference } from './Forms/TransportPreferences.helper';
import { PERMISSIONS } from '~/Pages/MemberProfiles/MemberProfiles.constants';
import { getAssistanceNeeds } from './Forms/MobilityAssessment.helper';
import useFormTemplate from '~/hooks/useFormTemplate';
import { useModal } from '@SRHealth/frontend-lib';
import { getVehicleRecordByNickName } from '~/utilities/vehicles';
import UnsupportedVehicleModalContent from './MobilityAssessmentModal/UnsupportedVehicleModalContent';

/**
 * _Extreme_ type juggling. Live on the edge.
 *
 * Formats the the member default mode to what the
 * member mobility asseessment endpoint expects.
 * @param {string|undefined} val
 * @returns {string|undefined}
 */
export function formatModeForAssessment(val: MemberDefaultMode | undefined) {
  return val?.toLowerCase() as Lowercase<MemberDefaultMode> | undefined;
}

type MobilityAssessmentUpdate = Partial<
  Pick<MobilityFormMemberData, 'member_default_mode' | 'member_rideshare_preference'>
>;

type MemberMobilityUpdate =
  | MobilityAssessmentUpdate
  | TransportPreferencesUpdate
  | (MobilityAssessmentUpdate & TransportPreferencesUpdate);

export type MemberProfileUpdate = {
  passengerId: string;
  memberProfile: MemberMobilityUpdate & {
    mobilityFormSubmissionId?: string;
    vehicle_type?: number;
  };
};

export type MemberMobilityAssessmentProps = {
  selectedId: string;
  passengerId: number;
  memberProfile: MemberProfileStore;
  vehicleCompanies: VehicleCompany[];
  vehicleTypes: BE.VehicleRecord[];
  updateMember: (params: MemberProfileUpdate) => void;
} & React.PropsWithChildren;

const MemberMobilityAssessment: React.FC<MemberMobilityAssessmentProps> = ({
  selectedId,
  passengerId,
  memberProfile,
  vehicleCompanies,
  vehicleTypes,
  updateMember
}) => {
  const dispatch = useDispatch();
  const [, ModalActions] = useModal();
  const [formId, formTemplate] = useFormTemplate('mobility_assessment');

  const disabled = memberProfile.permission === PERMISSIONS.VIEW;
  const mobilityData = memberProfile.formData.mobility;

  /** Loads the member's rideshare preferences */
  useEffect(() => {
    if (passengerId && !memberProfile.formData.mobility?.member_rideshare_preference) {
      dispatch(getMemberRidesharePreferences(passengerId));
    }
  }, [passengerId, memberProfile]);

  /**
   * Submit event handler for the MobilityAssessment component.
   * Calls the mobility assessment end point and generates a modal
   * which triggers a member update.
   * @param {object} submission
   * @returns {void}
   */
  function updateMobilityAssessment(submission: FormState) {
    const ridesharePref = getRidesharePreference(mobilityData);
    const defaultMode = getDefaultMobility(mobilityData);

    const update = {
      member_default_mode: defaultMode?.id,
      member_rideshare_preference: ridesharePref?.nickName,
      member_default_assistance_needs: getAssistanceNeeds(submission, mobilityData)
    };

    return assessMobility(
      submission,
      ridesharePref?.nickName,
      defaultMode?.value,
      update
    );
  }

  /**
   * Submit event handler for the TransportPreferences component.
   * If the member has completed the mobility assessment then this will
   * cause it to re-run. Otherwise
   * @param {object} update
   * @returns {void}
   */
  function updateTransportPreferences(update: TransportPreferencesUpdate) {
    const form = memberProfile?.mobilityAssessment?.submission;

    if (!form) {
      return updateMember({ passengerId: selectedId, memberProfile: update });
    }

    const defaultMode = getDefaultModeValueFromId(
      update.member_default_mode,
      memberProfile.formData.mobility
    );

    // Perform new Assessment
    return assessMobility(form, update.member_rideshare_preference, defaultMode, update);
  }

  /**
   * Submits the mobility form data to the mobility assessment endpoint
   * and displays the results in a modal. On use confirmation of transport
   * type, a member update is pushed.
   * @param {object} form
   * @param {string} ridesharePref
   * @param {string} defaultMode
   * @param {object} update
   * @returns {void}
   */
  function assessMobility(
    form: FormState,
    ridesharePref: RidesharePreferenceNickName,
    defaultMode: MemberDefaultMode | undefined,
    update: MemberMobilityUpdate
  ) {
    return submitMobilityAssessment(
      passengerId,
      formId!,
      form,
      ridesharePref ?? undefined,
      formatModeForAssessment(defaultMode)
    )
      .then(res =>
        displayAssessmentResults(
          res as App.Member.V1.Forms.SubmitMobilityFormResponse,
          update
        )
      )
      .catch(err =>
        // eslint-disable-next-line no-console
        console.error('Failed to retrieve Mobility Assessment results', err)
      );
  }

  /**
   * Consume the mobility assessment response and generates
   * a modal for users to accept their transport type or prompts them to change it if it's invalid.
   *
   * On confirmation the accepted transport type is pushed to the BE along with
   * whatever member profile fields are included in the update param.
   * @param {object} response
   * @param {object} update
   * @returns {void}
   */
  function displayAssessmentResults(
    response: App.Member.V1.Forms.SubmitMobilityFormResponse,
    update: Omit<MemberMobilityUpdate, 'vehicle_type'>
  ) {
    const performUpdate = (vehicle_type: number) =>
      vehicle_type &&
      updateMember({
        passengerId: selectedId,
        memberProfile: {
          ...update,
          vehicle_type,
          mobilityFormSubmissionId: response.data.id
        }
      });

    const recommendedVehicleTypeNickName = response?.data?.results
      ?.vehicle_type as BE.VehicleTypeNickName;
    const allowedVehicleTypes = memberProfile.formData.mobility.vehicle_types ?? [];
    const recommendedVehicleIsAllowed = allowedVehicleTypes.some(
      ({ nickName }) => nickName === recommendedVehicleTypeNickName
    );

    if (recommendedVehicleIsAllowed) {
      ModalActions.set({
        title: 'Transport Type',
        isOpen: true,
        content: (
          <ConfirmMobilityModalContent
            allowedVehicleTypes={allowedVehicleTypes}
            recommendedVehicleTypeNickName={recommendedVehicleTypeNickName}
            onAccept={selectedVehicleTypeId => {
              performUpdate(selectedVehicleTypeId);
              ModalActions.setIsOpen(false);
            }}
          />
        ),
        onDismiss: () => ModalActions.setIsOpen(false)
      });
    } else {
      const unsupportedVehicleModelName =
        getVehicleRecordByNickName(recommendedVehicleTypeNickName, vehicleTypes)
          ?.modelName || 'the assessed type of';

      ModalActions.set({
        isOpen: true,
        title: 'Transport Type Not Supported',
        type: 'error',
        content: (
          <UnsupportedVehicleModalContent
            unsupportedVehicleModelName={unsupportedVehicleModelName}
            onDismiss={() => ModalActions.setIsOpen(false)}
          />
        ),
        onDismiss: () => ModalActions.setIsOpen(false)
      });
    }
  }

  return (
    <>
      <MobilityAssessment
        isDisabled={disabled}
        member={memberProfile}
        updateMember={updateMobilityAssessment}
        formId={formId}
        formTemplate={formTemplate}
      />
      <TransportPreferences
        isDisabled={disabled}
        member={memberProfile}
        vehicleCompanies={vehicleCompanies}
        updateMember={updateTransportPreferences}
      />
    </>
  );
};

export default MemberMobilityAssessment;
