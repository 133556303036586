import { createAppAsyncThunk } from '~/Modules';
import { getProviderSupplyThunk } from './getProviderSupply.thunk';
import { cacheRecurringRides } from '../RideBooking.slice';

export const completeRecurringRidesThunk = createAppAsyncThunk(
  'rideBooking/completeRecurringThunk',
  (_, { getState, dispatch }) => {
    const recurringRides = getState().rideBooking.recurringRides;

    return recurringRides?.commit().then(() => {
      dispatch(cacheRecurringRides());
      return dispatch(getProviderSupplyThunk());
    });
  }
);
