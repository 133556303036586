import {
  isRideTimeString,
  type AddressProps,
  type RideModel,
  type RideTime,
  type RideType
} from '~/models';
import { getDirections, getLatLngTimezone } from '~/services/mapbox.service';
import type { MapMarker } from './subcomponents/RideBookingMap';
import { Is } from '@SRHealth/frontend-lib';
import { metersToMiles } from '~/utilities/distances';
import { addRideTimeMinutes } from '~/utilities/timesAndDates';

/** Converts the values on an address model to a map marker. */
export function addressToMarker(
  address: AddressProps,
  type: 'depart' | 'arrive'
): MapMarker {
  const marker: MapMarker = {
    lat: address.latitude,
    lng: address.longitude,
    type
  };

  if (address.type === 'venue' && address.entrance) {
    marker.popupContent = address.entrance;
    marker.preOpenedPopup = true;
  }

  return marker;
}

/** Converts the depart and arrive address models/props on a ride
 * to map markers */
export function rideToMarkers(ride: RideModel): { depart: MapMarker; arrive: MapMarker } {
  return {
    depart: addressToMarker(ride.departAddress, 'depart'),
    arrive: addressToMarker(ride.arriveAddress, 'arrive')
  };
}
/** Extract the lat/lng from an address. */
const getAddressLatLng = (address: AddressProps) => ({
  lat: address?.latitude,
  lng: address?.longitude
});

/** Attempts to determine if we can use optional params when querying route
 * date from the mapbox service, for more accurate results. */
const getRideRouteOptions = (ride: RideModel, date?: DateString | null) => {
  const options: Record<string, string | number> = {};

  if (date && ride.time && isRideTimeString(ride.time)) {
    const time: (string | number)[] = ride.time?.split(' ', 1)[0].split(':');

    const meridiemOffset = time[0] === '12' || ride.time?.includes('am') ? 0 : 12;

    time[0] = +time[0] + meridiemOffset;
    if (time[0] < 10) time[0] = `0${time[0]}`;

    const timestamp = `${date}T${time[0]}:${time[1]}`;

    if (ride.type === 'arriveBy') {
      options.arrive_by = timestamp;
    } else if (ride.type === 'departAt') {
      options.depart_at = timestamp;
    }
  }

  return options;
};

/** Obtain the driving distance between depart and arrive addresses. Returns
 * an array with the distance in miles and the duration in seconds. */
export async function getRideRouteData(
  ride: RideModel,
  date?: DateString | null
): Promise<[string, number] | undefined> {
  if (!ride.departAddress || !ride.arriveAddress) return undefined;

  const depart = getAddressLatLng(ride.departAddress.toJSON());
  const arrive = getAddressLatLng(ride.arriveAddress.toJSON());

  if (!depart.lat || !depart.lng || !arrive.lat || !arrive.lng) return undefined;

  // Try to incorporate the optional route params for better route results.
  const options = getRideRouteOptions(ride, date);

  const directions = await getDirections(depart, arrive, options);

  if (!directions) return undefined;

  const distance = metersToMiles(directions?.routes?.[0]?.distance).toFixed(2);
  const duration = directions?.routes?.[0]?.duration / 60;

  return Is.Nil(directions?.routes?.[0]?.distance) ? undefined : [distance, duration];
}

/** Whenever the ride type or address type on a ride changes we need to
 * update the timezone. */
export function onRideTypeChange(ride: RideModel, type: RideType) {
  if (ride.type !== type) return;

  const addressKey = type === 'departAt' ? 'departAddress' : 'arriveAddress';

  if (!ride[addressKey].latitude || !ride[addressKey].longitude) {
    ride.timezone = undefined;
  } else {
    getLatLngTimezone(ride[addressKey].latitude, ride[addressKey].longitude).then(
      timezone =>
        ([ride.time, ride.timezone] = [
          ride.timezone === timezone ? ride.time : undefined,
          timezone
        ])
    );
  }
}

/**
 * Takes the ride's required arrival time and calculates the time the driver
 * should arrive to pick up the passenger.
 */
export function bufferPickupTime(
  rideTime: RideTime | undefined,
  duration: number
): RideTime | undefined {
  if (!rideTime || rideTime === 'Now' || rideTime === 'Flex Time') {
    return rideTime;
  }

  return addRideTimeMinutes(rideTime, -Math.ceil(duration + 10));
}
