import React from 'react';
import moment from 'moment';
import { TOKENS, THEME, Icon, Alert } from '@SRHealth/frontend-lib';
import { useMemberAccountStyles } from '../styles';
import type { MemberPortalInfo } from '~/Modules/memberProfile';

type AccountDetailProps = {
  label: string;
  value?: string;
  classes: Record<string, string>;
};

function AccountDetail({ label, value, classes }: AccountDetailProps) {
  return label && value ? (
    <div className={classes.details}>
      <div className="text-sm font-bold leading-4 text-app-black">{label}</div>
      <div className="text-sm leading-4 text-app-black">{value}</div>
    </div>
  ) : null;
}

type MemberPortalAccountProps = {
  memberPortalInfo: MemberPortalInfo;
};

function MemberPortalAccount({ memberPortalInfo }: MemberPortalAccountProps) {
  const {
    isActive,
    ssoEnabled,
    lastLoginTime,
    deactivatedAt,
    deactivationReason,
    userLogin
  } = memberPortalInfo;
  const classes = useMemberAccountStyles({ isActive });
  const accountBackground = isActive ? '#E7FAEF' : THEME.colors['neutral-gray-xxxlt'];

  const icon = isActive ? 'Verified' : 'ActiveOff';
  const header = isActive ? 'Member Portal Activated' : 'Member Portal Deactivated';

  if (!userLogin) {
    return <Alert label="Member needs to activate account" type="warning" />;
  }

  return (
    <div className={classes.container}>
      <div
        className={classes.detailsContainer}
        style={{ backgroundColor: accountBackground }}
      >
        <div className={classes.header}>
          <div className={`heading-lg text-${TOKENS.FONT.PRIMARY}`}>{header}</div>
          <Icon className={classes.icon} type={icon} />
        </div>
        <AccountDetail
          label="Username"
          value={ssoEnabled ? 'Username not applicable' : userLogin}
          classes={classes}
        />
        <AccountDetail
          label="Last Login Date"
          value={moment(lastLoginTime).format('MM/DD/YYYY')}
          classes={classes}
        />
        {!isActive && (
          <>
            <AccountDetail
              label="Deactivated On"
              value={moment(deactivatedAt).format('MM/DD/YYYY')}
              classes={classes}
            />
            <AccountDetail
              label="Deactivation Reason"
              value={deactivationReason}
              classes={classes}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default MemberPortalAccount;
