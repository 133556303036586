/** Formats (in human readable format) the periods that the user has exceeded their benefit limits for */
export const formatUsedUpBenefitPeriodsAlertText = (
  periods: Set<string>,
  earliestDate: string,
  latestDate: string
): string => {
  if (!periods.size) return '';

  // Filter out periods outside the range of selectable dates
  const [earliestYear, earliestMonth] = earliestDate?.split('-') ?? ['0000', '00'];
  const [latestYear, latestMonth] = latestDate?.split('-') ?? ['9999', '99'];

  /** Filter out months outside the range of selectable dates
   * We don't need to do this for years because unlike months, we should never overfetch them.
   */
  const usedUpPeriodsToDisplay = Array.from(periods).filter(period => {
    if (period.length === 4) {
      return true; // For year format (YYYY), always display
    } else {
      // For month format (YYYY-MM)
      const [exceededPeriodYear, exceededPeriodMonth] = period.split('-');
      return (
        (exceededPeriodYear > earliestYear ||
          (exceededPeriodYear === earliestYear &&
            exceededPeriodMonth >= earliestMonth)) &&
        (exceededPeriodYear < latestYear ||
          (exceededPeriodYear === latestYear && exceededPeriodMonth <= latestMonth))
      );
    }
  });

  if (!usedUpPeriodsToDisplay.length) return '';

  const formatted = usedUpPeriodsToDisplay.map(period => {
    // Check if it's a month (YYYY-MM format) or year
    if (period.length === 7) {
      const [year, month] = period.split('-');
      const date = new Date(parseInt(year), parseInt(month) - 1);
      return date.toLocaleString('default', { month: 'long', year: 'numeric' });
    }
    return period; // Just return the year
  });

  if (formatted.length === 1) {
    return formatted[0];
  }

  if (formatted.length === 2) {
    return `${formatted[0]} and ${formatted[1]}`;
  }

  const last = formatted.pop();
  return `${formatted.join(', ')}, and ${last}`;
};
