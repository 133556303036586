import { Accordion } from '@SRHealth/frontend-lib';
import React from 'react';
import type { RideType } from '~/models';
import { getTripCardLabel } from '../RecurringRides.utils';
import TripCardSummary from './TripCardSummary';

export type TripCardProps = {
  date: DateString;
  timeOne: string;
  timeTwo?: string;
  isActive: boolean;
  initialRideType: RideType;
  // isEditable?: boolean;
  onToggle: (val: DateString | null) => void;
};

export default function TripCard({
  date,
  isActive,
  timeOne,
  timeTwo,
  initialRideType,
  onToggle
}: TripCardProps) {
  const handleToggle = () => (isActive ? onToggle(null) : onToggle(date!));

  return (
    <Accordion isOpen={isActive} onToggle={handleToggle} label={getTripCardLabel(date!)}>
      <TripCardSummary
        timeOne={timeOne}
        timeTwo={timeTwo}
        initialRideType={initialRideType}
      />
    </Accordion>
  );
}
