import { createAppAsyncThunk } from '~/Modules';
import { updateMemberInternalNotes } from '~/services/member.service';

/**
 *  Thunk to update the member internal notes inside the ride booking flow
 */
export const updateInternalNotesThunk = createAppAsyncThunk(
  'rideBooking/updateInternalNotes',
  async (updatedNotes: string, { getState, rejectWithValue }) => {
    const passengerId = getState().rideBooking.passengerInfo.passengerId;

    try {
      if (!passengerId) {
        throw Error('passengerId is required');
      }

      return (await updateMemberInternalNotes(passengerId, updatedNotes))?.data;
    } catch (error) {
      return rejectWithValue({ error: error.response });
    }
  }
);
