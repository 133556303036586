import { selectBenefitsUsageAndLimits } from './selectBenefitsUsageAndLimits';
import { selectActiveBenefitCategoryIds, selectIgnoreBenefitLimits } from '.';
import { createSelector } from '@reduxjs/toolkit';
import { getBenefitsUnderRemainingThreshold } from '../RideBooking.utils';
import {
  buildUsedUpBenefitPeriodsAndRanges,
  type UsedUpBenefitPeriodsAndRanges
} from './selectUsedUpBenefitPeriods.utils';

/** Analyzes all benefit usage data and returns any periods that the user has exceeded their
 * benefit limits (in months in YYYY-MM format or years in YYYY format) along with their hard blocked date ranges */
export const selectUsedUpBenefitPeriods = createSelector(
  [
    selectBenefitsUsageAndLimits,
    selectActiveBenefitCategoryIds,
    selectIgnoreBenefitLimits
  ],
  (
    benefitsUsageAndLimits,
    activeBenefitCategoryIds,
    ignoreBenefitLimits
  ): UsedUpBenefitPeriodsAndRanges => {
    // If ignoreBenefitLimits is true, we don't care about any used up benefit periods
    if (ignoreBenefitLimits) {
      return { usedUpBenefitPeriods: new Set(), hardBlockedBenefitDateRanges: [] };
    }

    const exceededLimits = getBenefitsUnderRemainingThreshold(
      1, // threshold of 1 to find periods with no remaining rides
      [],
      benefitsUsageAndLimits,
      activeBenefitCategoryIds,
      false
    );

    return buildUsedUpBenefitPeriodsAndRanges(exceededLimits);
  }
);
