import React, { useEffect, useReducer } from 'react';
import { Button, DatePicker, Section, Card } from '@SRHealth/frontend-lib';
import { store } from '~/store';
import useModelSelector from '~/hooks/useModelSelector';
import type { DuplicateRides } from './Date.types';
import { useAppDispatch, useAppSelector } from '~/Modules';
import {
  completeDateThunk,
  editDate,
  RIDE_SECTION_ORDER,
  selectIsActive,
  selectUsedUpBenefitPeriods
} from '~/Modules/rideBooking';
import DateSummary from './subcomponents/DateSummary';
import Loader from '~/Pages/RideBooking/Loader';
import { genericCancelBookingHandler } from '../../RideBooking.utils';
import UsedUpBenefitPeriodsAlert from '../Shared/UsedUpBenefitPeriodsAlert';

type DateState = {
  isFocused: boolean;
  isLoading: boolean;
  softBlock: boolean;
  isCompleted: boolean;
  duplicateRides: DuplicateRides;
};

type ReducerAction =
  | { type: 'focusin' }
  | { type: 'focusout' }
  | { type: 'edit' }
  | { type: 'complete' }
  | { type: 'loading'; isLoading: boolean }
  | { type: 'setIsCompleted'; isCompleted: boolean }
  | { type: 'setDuplicateRides'; duplicateRides: DuplicateRides };

const createInitialState = (): DateState => ({
  isFocused: false,
  isLoading: false,
  softBlock: false,
  isCompleted: false,
  duplicateRides: []
});

const reducer: React.Reducer<DateState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'complete':
      return { ...state, isCompleted: true, isLoading: false };
    case 'edit':
      store.dispatch(editDate());
      return { ...state, isCompleted: false, softBlock: false, isLoading: false };
    case 'focusin':
      return state.isFocused ? state : { ...state, isFocused: true };
    case 'focusout':
      return !state.isFocused ? state : { ...state, isFocused: false };
    case 'loading':
      return { ...state, isLoading: action.isLoading };
    case 'setIsCompleted':
      return { ...state, isCompleted: action.isCompleted };
    case 'setDuplicateRides':
      return {
        ...state,
        duplicateRides: action.duplicateRides,
        softBlock: action.duplicateRides.length > 0
      };
  }
};

const DateSection = () => {
  const appDispatch = useAppDispatch();
  const { model } = useModelSelector(state => state.rideBooking.date);
  const dateRestrictions = useAppSelector(
    state => state.rideBooking.meta.dateRestrictions
  );
  const { hardBlockedBenefitDateRanges } = useAppSelector(selectUsedUpBenefitPeriods);

  const isActive = useAppSelector(state => selectIsActive(state, 'date'));

  const [state, localDispatch] = useReducer(
    reducer,
    createInitialState(),
    createInitialState
  );

  /** Validate and commit any outstanding changes to the model. */
  const handleSave = async () => {
    if (!model.isDirty && Object.keys(model.ruleErrors).length) return;

    localDispatch({ type: 'loading', isLoading: true });

    // Check for duplicate rides if user has not already been soft blocked
    return appDispatch(completeDateThunk(!state.softBlock))
      .unwrap()
      .then(duplicateRides => {
        if (duplicateRides) {
          localDispatch({ type: 'setDuplicateRides', duplicateRides });
          throw 'Duplicate rides found';
        }

        localDispatch({ type: 'complete' });
      })
      .catch(() => localDispatch({ type: 'loading', isLoading: false }));
  };

  /**
   * The event handler attached to the RBF parent element. It detects if the user
   * clicks anywhere inside of the RBF element and then determines if the target is
   * within the Date section. If it is, it triggers the focusin event. Otherwise it
   * triggers the focusout event.
   *
   * **Note:** This handler is only refreshed when the formRef changes. Logging state
   * will display data at the time that the handler was created. It will potentially be
   * outdated.
   */
  const handleFocus = ({ target }: PointerEvent) => {
    const oldTarget = document.activeElement;
    const dateElement = document.getElementById('date')!;

    if (target === oldTarget) return;

    if (dateElement?.contains(target as Node)) {
      localDispatch({ type: 'focusin' });
    } else if (dateElement?.contains(oldTarget as Node)) {
      localDispatch({ type: 'focusout' });
    }
  };

  useEffect(() => {
    const rbfElement = document.querySelector('[data-testid="rbf"]');

    if (!rbfElement) {
      throw Error(
        'The data-testid attribute has been altered or removed on the RideBooking component.'
      );
    }

    rbfElement.addEventListener('pointerdown', handleFocus);
    return () => rbfElement.removeEventListener('pointerdown', handleFocus);
  }, []);

  return (
    <Section id="date" icon="DateRange" label="Date">
      <Loader isLoading={state.isLoading} />

      {!isActive || state.softBlock || state.isCompleted ? (
        <DateSummary
          duplicateRides={state.duplicateRides}
          selectedDate={model.date!}
          onEdit={() => localDispatch({ type: 'edit' })}
        />
      ) : (
        <Card label="Select A Date" border={false}>
          <div style={{ marginTop: '32px' }} className="flex flex-col items-center gap-4">
            <UsedUpBenefitPeriodsAlert />
            <DatePicker
              datesSelected={model.date ?? []}
              earliestSelectableDate={dateRestrictions?.earliestDate}
              latestSelectableDate={dateRestrictions?.latestDate}
              excludedDates={[
                ...(dateRestrictions?.restrictedDates ?? []),
                ...hardBlockedBenefitDateRanges
              ]}
              onDateClick={(selectedDate: DateString) => {
                model.date = selectedDate;
              }}
            />
          </div>
        </Card>
      )}

      {isActive && (
        <Section.Footer>
          <div className="flex flex-row items-center justify-center">
            {state.softBlock && (
              <Button
                alt
                label="Cancel Trip"
                onClick={genericCancelBookingHandler}
                minWidth="160px"
              />
            )}
            <Button
              label={state.softBlock ? 'Continue' : 'Save & Continue'}
              onClick={handleSave}
              disabled={!model.date}
              minWidth="160px"
            />
          </div>
        </Section.Footer>
      )}
    </Section>
  );
};

DateSection.sectionIndex = RIDE_SECTION_ORDER.date;

export { DateSection };
