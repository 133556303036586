import React from 'react';
import { Alert } from '@SRHealth/frontend-lib';
import { RIDES_TXT } from '~/Pages/RideBooking/Components/Rides/Rides.constants';

interface RideLimitWarningProps {
  /** The current remaining rides allowed for the member (including rides in the current booking session) */
  currentRemainingRides?: number;
  /** Whether the ride limit is a hard block or not*/
  isHardBlock?: boolean;
  /** Whether the ride is an initial ride */
  isInitialRide?: boolean;
}

export default function RideLimitWarning({
  currentRemainingRides = 0,
  isHardBlock = false,
  isInitialRide = false
}: RideLimitWarningProps) {
  // Check for hard block
  if (isHardBlock && isInitialRide && currentRemainingRides <= 1) {
    return <Alert type="error" label={RIDES_TXT.RIDE_LIMIT_HARD_BLOCK} />;
  }

  // Check for soft block. Note it has a different threshold (0 remaining rides or less) than hard block (1 or less)
  if (currentRemainingRides <= 0) {
    return <Alert type="warning" label={RIDES_TXT.RIDE_LIMIT_SOFT_BLOCK} />;
  }

  return null;
}
