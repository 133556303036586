import React, { useReducer, useEffect } from 'react';
import { Section } from '@SRHealth/frontend-lib';
import { useAppDispatch, useAppSelector } from '~/Modules';
import {
  addReturnRideThunk,
  completeRidesThunk,
  editRides,
  removeReturnRide,
  RIDE_SECTION_ORDER,
  selectIsActive
} from '~/Modules/rideBooking';
import Loader from '~/Pages/RideBooking/Loader';
import RideBookingMap, {
  type MapMarker,
  useRideBookingMap
} from './subcomponents/RideBookingMap';
import RidesSummary from './subcomponents/RidesSummary';
import { NOOP } from '~/utilities/helperFunctions';
import { addressToMarker } from './Rides.utils';
import InitialRideSummary from './subcomponents/InitialRideSummary';
import RideForm from './subcomponents/RideForm';
import type { AddressModel, RideTime } from '~/models';

const inlineCSS = `
  [data-testid="section-rides"] {
    padding-bottom: 0;
  }

  [data-testid="section-rides-content-inner"] {
    padding: 0;
  }
`;

type RidesState = {
  isFocused: boolean;
  isLoading: boolean;
};

type ReducerAction =
  | { type: 'focusin' }
  | { type: 'focusout' }
  | { type: 'loading'; isLoading: boolean };

const createInitialState = (): RidesState => ({
  isFocused: false,
  isLoading: false
});

const reducer: React.Reducer<RidesState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'focusin':
      return state.isFocused ? state : { ...state, time: Date.now(), isFocused: true };
    case 'focusout':
      return !state.isFocused ? state : { ...state, isFocused: false };
    case 'loading':
      return { ...state, isLoading: action.isLoading };
  }
};

const Rides = () => {
  const appDispatch = useAppDispatch();
  const [markers, markerActions] = useRideBookingMap();
  const rides = useAppSelector(state => state.rideBooking.rides);
  const isActive = useAppSelector(state => selectIsActive(state, 'rides'));
  const [state, localDispatch] = useReducer(reducer, createInitialState());

  /** Tracks the active ride by identifying the index of the first model
   * in a dirty state. If none are in a dirty state, the active ride is
   * the last ride in the array. */
  const activeRideIndex = rides.findIndex(r => r.isDirty);
  const activeReturnRide = rides.at(activeRideIndex) !== rides[0];

  /** Event handler for updating a ride address. Necessary to let us grab
   * the timezone for the model. */
  function handleAddressChange(type: 'depart' | 'arrive', address: AddressModel) {
    rides.at(activeRideIndex)![`${type}Address`] = address;

    if (address.latitude && address.longitude) {
      markerActions.setMarkers([addressToMarker(address, type)]);
    }
  }

  /** Flips the depart and arrive addresses. */
  function handleFlipAddresses() {
    const activeRide = rides.at(activeRideIndex)!;
    const newMarkers: MapMarker[] = [];

    if (activeRide.arriveAddress.value) {
      newMarkers.push(addressToMarker(activeRide.arriveAddress, 'depart'));
    }

    if (activeRide.departAddress.value) {
      newMarkers.push(addressToMarker(activeRide.departAddress, 'arrive'));
    }

    markerActions.setMarkers(newMarkers);
    [activeRide.arriveAddress, activeRide.departAddress] = [
      activeRide.departAddress,
      activeRide.arriveAddress
    ];
  }

  /**
   * Handles adding a return ride to the ride booking by first
   * committing any outstanding changes to the initial ride
   * and then updating the RideBooking slice.
   */
  function handleAddRide() {
    localDispatch({ type: 'loading', isLoading: true });

    return appDispatch(addReturnRideThunk())
      .unwrap()
      .then(() =>
        markerActions.setMarkers([
          // We flip the addresses here to ensure the markers are in the correct order
          // for the return ride.
          addressToMarker(rides.at(activeRideIndex)!.departAddress, 'arrive'),
          addressToMarker(rides.at(activeRideIndex)!.arriveAddress, 'depart')
        ])
      )
      .catch(NOOP)
      .finally(() => localDispatch({ type: 'loading', isLoading: false }));
  }

  /** Event handler for updating a ride time. Defined here and passed down to
   * avoid re-rendering the RideTimePicker component whenever the RideCardForm
   * component renders. */
  function handleTimeChange(_: unknown, val: RideTime) {
    rides.at(activeRideIndex)!.time = val;
  }

  /** Handles removing the return ride. This will always cause the
   * rides array to reset to a single leg. */
  function handleRemoveRide() {
    appDispatch(removeReturnRide());
    document
      .querySelector<HTMLButtonElement>('[data-testid="button-edit-ride"]')
      ?.click();
  }

  /** Handles the edit ride event by setting the distance on the
   * target ride to undefined. */
  function handleEditRide(idx: number) {
    if (!isActive) appDispatch(editRides());

    if (idx === 0) appDispatch(removeReturnRide());

    rides[idx].distance = undefined;
    return Promise.resolve();
  }

  /**
   * The event handler attached to the RBF parent element. It detects if the user
   * clicks anywhere inside of the RBF element and then determines if the target is
   * within Funding Source. If it is, it triggers the focusin event. Otherwise it
   * triggers the focusout event.
   *
   * **Note:** This handler is only refreshed when the formRef changes. Logging state
   * will display data at the time that the handler was created. It will potentially be
   * outdated.
   */
  function handleFocus({ target }: PointerEvent) {
    const oldTarget = document.activeElement;
    const ridesElement = document.getElementById('rides')!;

    if (target === oldTarget) return;

    if (ridesElement?.contains(target as Node)) {
      localDispatch({ type: 'focusin' });
    } else if (ridesElement?.contains(oldTarget as Node)) {
      localDispatch({ type: 'focusout' });
    }
  }

  /** Event handler when completing rides section. */
  function handleReviewRide() {
    localDispatch({ type: 'loading', isLoading: true });

    appDispatch(completeRidesThunk())
      .catch(NOOP)
      .finally(() => localDispatch({ type: 'loading', isLoading: false }));
  }

  useEffect(() => {
    const rbfElement = document.querySelector('[data-testid="rbf"]');

    if (!rbfElement) {
      throw Error(
        'The data-testid attribute has been altered or removed on the RideBooking component.'
      );
    }

    rbfElement.addEventListener('pointerdown', handleFocus);
    return () => rbfElement.removeEventListener('pointerdown', handleFocus);
  }, []);

  return (
    <Section id="rides" icon="TransportCar" label="Rides">
      <style>{inlineCSS}</style>
      <Loader isLoading={state.isLoading} />
      {isActive ? (
        <RideBookingMap.Provider value={[markers, markerActions]}>
          <div className="flex flex-row w-full">
            <div className="flex flex-col flex-1 gap-[16px] items-center px-[8px] py-[24px] bg-white">
              {rides.length > 1 && activeRideIndex && (
                <InitialRideSummary
                  ride={rides[0].toJSON()}
                  onEdit={() => handleEditRide(0)}
                />
              )}

              <RideForm
                ride={rides.at(activeRideIndex)!}
                isReturnRide={activeReturnRide}
                onAddRide={handleAddRide}
                onRemoveRide={handleRemoveRide}
                onReviewRide={handleReviewRide}
                onFlipAddresses={handleFlipAddresses}
                onAddressChange={handleAddressChange}
                onTimeChange={handleTimeChange}
              />
            </div>
            <div className="flex-1 shrink-0">
              <RideBookingMap />
            </div>
          </div>
        </RideBookingMap.Provider>
      ) : (
        <RidesSummary rides={rides} onEditRide={handleEditRide} />
      )}
    </Section>
  );
};

Rides.sectionIndex = RIDE_SECTION_ORDER.rides;

export { Rides };
