import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import type { BenefitsUsageAndLimits } from '../RideBooking.types';

const selectBenefitsUsage = (state: AppStore) =>
  state.memberProfile.formData.benefits.benefitsUsage;

const selectBenefitLimits = (state: AppStore) => state.memberProfile.benefitLimits;

/**
 * Builds the benefits usage and limits object based on the benefit usage and limits data.
 * Yes, all this data exists in the store, but this function coheres it instead of needing to know where to find the
 * hardblock, limit, how to distinguish between a benefit category limit and a non-benefit category limit, etc
 * for every single benefit.
 */
export const selectBenefitsUsageAndLimits = createSelector(
  [selectBenefitsUsage, selectBenefitLimits],
  (benefitsUsage, benefitLimits): BenefitsUsageAndLimits => {
    const benefitsUsageAndLimits: BenefitsUsageAndLimits = {
      all: {
        ridesPerYear: {
          usage: benefitsUsage.all.ridesUsedByYear,
          limit: benefitLimits.rides_per_year?.blockLimits?.value ?? null,
          isHardBlock: benefitLimits.rides_per_year?.blockLimits?.bookAfterBlock === 0
        },
        ridesPerMonth: {
          usage: benefitsUsage.all.ridesUsedByMonth,
          limit: benefitLimits.rides_per_month?.blockLimits?.value ?? null,
          isHardBlock: benefitLimits.rides_per_month?.blockLimits?.bookAfterBlock === 0
        },
        costPerYear: {
          usage: benefitsUsage.all.costUsedByYear,
          limit: benefitLimits.cost_per_year?.blockLimits?.value ?? null,
          isHardBlock: benefitLimits.cost_per_year?.blockLimits?.bookAfterBlock === 0
        }
      }
    };

    // Handle benefit categories
    const benefitCategoryLimits = Object.values(benefitLimits).filter(
      benefitLimit => benefitLimit.benefitCategoryId
    );

    for (const benefitCategoryLimit of benefitCategoryLimits) {
      const benefitKey =
        `benefit-category-${benefitCategoryLimit.benefitCategoryId}` as `benefit-category-${number}`;
      const usage = benefitsUsage[benefitKey]?.ridesUsedByYear || {};

      benefitsUsageAndLimits[benefitKey] = {
        ridesPerYear: {
          usage,
          limit: benefitCategoryLimit.blockLimits?.value ?? null,
          isHardBlock: benefitCategoryLimit.blockLimits?.bookAfterBlock === 0
        }
      };
    }

    return benefitsUsageAndLimits;
  }
);
