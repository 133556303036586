import React from 'react';
import { Button, Card, Input, Section, useModel } from '@SRHealth/frontend-lib';
import { useAppDispatch, useAppSelector } from '~/Modules';
import {
  completeTransportProviderThunk,
  RIDE_SECTION_ORDER,
  type PrivateProviders,
  type PublicProvider
} from '~/Modules/rideBooking';
import PrivateTransports from './subcomponents/PrivateTransports';
import PublicTransports from './subcomponents/PublicTransports';

const TransportProvider = () => {
  const dispatch = useAppDispatch();
  const tripType = useAppSelector(s => s.rideBooking.passengerInfo.tripType);
  const selectedProvider = useAppSelector(s => s.rideBooking.transportProvider);
  const confirmationMessage = useAppSelector(s => s.user.confirmationAgreement);

  const [isConfirmed, setIsConfirmed] = React.useState(!confirmationMessage);
  const { model } = useModel(selectedProvider);

  // Does the user need to contact the transport provider via LiveChat?
  const mustContact = 'chatEnabled' in model && model.chatEnabled;

  const isBlocked = !selectedProvider.name || !isConfirmed;

  return (
    <Section id="transport-provider" icon="CarTopView" label="Transport Provider">
      {tripType === 'public' ? (
        <PublicTransports provider={model as PublicProvider} />
      ) : (
        <PrivateTransports provider={model as PrivateProviders} />
      )}

      {confirmationMessage && (
        <Card label="Compliance Agreement">
          <div className="reset-div flex flex-row">
            <Input
              inputId={0}
              name="confirmation"
              type="checkbox"
              label=""
              options={[{ value: true, label: '' }]}
              value={[isConfirmed]}
              onChange={() => setIsConfirmed(!isConfirmed)}
            />
            <div
              className="reset-div flex-1"
              dangerouslySetInnerHTML={{ __html: confirmationMessage }}
            />
          </div>
        </Card>
      )}

      <Section.Footer>
        <div className="flex flex-row items-center justify-center">
          {mustContact ? (
            <Button
              label="Contact Transport"
              size="lg"
              disabled={isBlocked}
              onClick={() => dispatch(completeTransportProviderThunk())}
            />
          ) : (
            <Button
              label="Schedule Trip"
              size="lg"
              disabled={isBlocked}
              onClick={() => dispatch(completeTransportProviderThunk())}
            />
          )}
        </div>
      </Section.Footer>
    </Section>
  );
};

TransportProvider.sectionIndex = RIDE_SECTION_ORDER['transport-provider'];

export { TransportProvider };
