import { createAppAsyncThunk } from '~/Modules';
import { getRideRouteData } from '~/Pages/RideBooking/Components/Rides/Rides.utils';
import { resetRecurringRides } from '../RideBooking.slice';
import { getProviderSupplyThunk } from './getProviderSupply.thunk';

export const completeRidesThunk = createAppAsyncThunk(
  'rideBooking/completeRides',
  (_, { getState, dispatch }) => {
    const lastRide = getState().rideBooking.rides.at(-1);
    const date = getState().rideBooking.date.date;

    if (!lastRide) throw Error('No rides found');

    const shouldClearRecurringRides = lastRide.isDirty;

    return getRideRouteData(lastRide, date)
      .then(data => ([lastRide.distance, lastRide.duration] = [data?.[0], data?.[1]]))
      .then(lastRide?.commit)
      .then(() => {
        if (shouldClearRecurringRides) return dispatch(resetRecurringRides());
      })
      .then(() => dispatch(getProviderSupplyThunk()));
  }
);
