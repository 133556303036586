import type { DateString } from '@SRHealth/frontend-lib';
import type { BenefitRemaining } from '../RideBooking.types';
import moment from 'moment';

export type UsedUpBenefitPeriodsAndRanges = {
  usedUpBenefitPeriods: Set<string>;
  hardBlockedBenefitDateRanges: [DateString, DateString][];
};

/** Process exceeded limits to determine used up benefit periods and hard blocked date ranges */
export function buildUsedUpBenefitPeriodsAndRanges(
  exceededLimits: BenefitRemaining[]
): UsedUpBenefitPeriodsAndRanges {
  const usedUpBenefitPeriods = new Set<string>();
  const hardBlockedBenefitDateRanges: [DateString, DateString][] = [];

  // We know if it's a month or a year limit by checking if the month is there or not.
  const exceededYearLimits = exceededLimits.filter(({ month }) => !month);
  const exceededMonthLimits = exceededLimits.filter(({ month }) => month);

  // 1. Add all exceeded year limits
  for (const { year, isHardBlock } of exceededYearLimits) {
    // For soft blocks, we only want to add them to the periods set
    // For hard blocks, we add to both the set and hard blocked date range array
    usedUpBenefitPeriods.add(year);

    if (isHardBlock) {
      hardBlockedBenefitDateRanges.push([
        `${year}-01-01` as DateString,
        `${year}-12-31` as DateString
      ]);
    }
  }

  // 2. Add month limits only if their year isn't already blocked
  for (const { year, month, isHardBlock } of exceededMonthLimits) {
    // Only add the month if the year isn't already in the set
    if (!usedUpBenefitPeriods.has(year)) {
      usedUpBenefitPeriods.add(`${year}-${month}`);

      // For hard blocks, we add to hard blocked date range array as well
      if (isHardBlock) {
        // Create start and end dates for the month
        const startDate = moment(`${year}-${month}`)
          .startOf('month')
          .format('YYYY-MM-DD') as DateString;
        const endDate = moment(`${year}-${month}`)
          .endOf('month')
          .format('YYYY-MM-DD') as DateString;

        hardBlockedBenefitDateRanges.push([startDate, endDate]);
      }
    }
  }

  return { usedUpBenefitPeriods, hardBlockedBenefitDateRanges };
}
