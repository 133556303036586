import type { BenefitRemaining } from '../RideBooking.types';
import { TXT } from '../RideBooking.constants';

/** Formats the alert message for dates that would exceed member's ride limits */
export function formatProjectedExceededLimitsMessage(
  exceededLimits: BenefitRemaining[],
  newRidesAreRoundTrips = false
): [string, 'warning' | 'error' | undefined] {
  if (!exceededLimits?.length) return ['', undefined];

  // Sort chronologically
  const sortedLimits = exceededLimits.sort((a, b) => {
    const dateA = new Date(parseInt(a.year), a.month ? parseInt(a.month) - 1 : 0);
    const dateB = new Date(parseInt(b.year), b.month ? parseInt(b.month) - 1 : 0);
    return dateA.getTime() - dateB.getTime();
  });

  let aggregateIsHardBlock = false;

  const limitDetails: string[] = [];

  for (const { month, year, remaining, isHardBlock } of sortedLimits) {
    // If the remaining count is positive, it means the limit is not exceeded (we shouldn't get here, but just in case)
    if (remaining >= 0) continue;

    // If any of the limits are hard blocks, the aggregate is a hard block
    aggregateIsHardBlock ||= isHardBlock;

    /** Turn remaining positive. And if new rides are round trips, divide by 2 and get
     * the ceiling (to get the number of calendar dates that need to be removed) */
    const nonNegativeOverage = Math.ceil(
      Math.abs(remaining) / (newRidesAreRoundTrips ? 2 : 1)
    );

    if (!month) {
      limitDetails.push(`${nonNegativeOverage} from ${year}`);
      continue;
    }

    const date = new Date(parseInt(year), parseInt(month) - 1);
    const monthName = date.toLocaleString('default', { month: 'long' });

    limitDetails.push(`${nonNegativeOverage} from ${monthName} ${year}`);
  }

  return [
    `${TXT.REMOVE_DATES_EXCEEDING_RIDES_LIMIT} ${formatList(limitDetails)} ${TXT.TO_MEET_THEIR_LIMIT}`,
    aggregateIsHardBlock ? 'error' : 'warning'
  ];
}

/** Helper function to format lists with proper grammar */
const formatList = (items: string[]): string => {
  if (items.length === 0) return '';
  if (items.length === 1) return items[0];
  if (items.length === 2) return `${items[0]} and ${items[1]}`;

  const last = items.pop();
  return `${items.join(', ')}, and ${last}`;
};
