import React from 'react';
import TextAreaField from '~/Shared/Components/TextAreaField';
import DropDown from '~/Shared/Components/DropDown';
import type { ComplianceRecord } from '~/types';
import type { FreeForm } from '~/Modules/memberProfile';

export interface ComplianceFieldsState {
  complianceSelect?: { [key: number]: string };
  complianceSelectError?: { [key: number]: boolean };
  complianceSelect_?: { [key: number]: string };
}

export type ComplianceFieldsProps = {
  onComplianceChange: (
    type: ComplianceRecord['input_type'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: React.ChangeEvent<any>,
    key?: number,
    id?: number
  ) => void;
  state?: ComplianceFieldsState;
  complianceInfo?: ComplianceRecord[];
  healthPlanCustomFields?: FreeForm;
};

const ComplianceFields: React.FC<ComplianceFieldsProps> = ({
  onComplianceChange,
  state = {},
  complianceInfo = []
}) => {
  return complianceInfo.map((field, i) => {
    const name = field?.input_name ?? '';
    const description = field?.input_description ?? '';
    const fieldDescription = description ? `${name}: ${description}` : name;

    const value = state?.complianceSelect?.[field.id] ?? null;
    const error = state?.complianceSelectError?.[field.id] ?? false;

    switch (field.input_type) {
      case 'text':
        return (
          <div className="inputHolder clearfix" key={i}>
            <TextAreaField
              onChangeCallback={e => onComplianceChange('text', e)}
              fieldName={`complianceSelect_${field.id}`}
              label={fieldDescription}
              placeholder={fieldDescription}
              value={value ?? ''}
              error={error}
            />
          </div>
        );
      case 'select': {
        const items = field.compliance_options.map(({ id, option_value }) => ({
          id,
          name: option_value
        }));

        const selectedItemId = value
          ? items.find(item => item.name === value)?.id
          : false;

        return (
          <div className="inputHolder clearfix" key={i}>
            <DropDown
              key={i}
              customClassName="patientDropDown"
              placeholder={fieldDescription}
              error={error}
              items={items}
              id={selectedItemId ?? undefined}
              dropDownCallback={e => onComplianceChange('select', e, i, field.id)}
            />
          </div>
        );
      }
      case 'hidden':
      default:
        return null;
    }
  });
};

export default ComplianceFields;
