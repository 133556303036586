import { createSelector } from '@reduxjs/toolkit';
import { selectActiveTreatment } from './selectActiveTreatment';
import { selectTreatments } from '~/Modules/patients';
import type { AppStore } from '~/types';
import { selectSubPlan } from '~/Modules/memberProfile';

const selectAllowBenefitOverride = (state: AppStore) =>
  state.memberProfile.formData.benefits.allowBenefitOverride;

/**
 * Given the selected treatment and the information we have so far,
 * determines if we should ignore benefit limits for this member for this ride.
 */
export const selectIgnoreBenefitLimits = createSelector(
  [selectSubPlan, selectAllowBenefitOverride, selectActiveTreatment, selectTreatments],
  (subPlan, allowBenefitOverride, activeTreatment, treatments): boolean => {
    // If the user's role allows them to override benefit limits, then we should ignore those limits
    if (allowBenefitOverride) return true;

    // If treatments exist in this health sub plan but none have been selected yet
    // (e.g. on the Funding Source section), then ignore limits for now
    if (treatments && !activeTreatment) return true;

    // Non-deducting benefit doesn't count against limit
    // Also, if treatments don't exist for this member (e.g. if not enabled on health sub plan),
    // it should be as if they selected a deducting treatment.
    if (activeTreatment?.benefit_deduction === 0) return true;

    // Medicaid doesn't have benefit limits
    if (subPlan?.benefitsProgram === 'medicaid') return true;

    // Default to false
    return false;
  }
);
