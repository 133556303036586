import type { Binary, VehicleSubType } from '~/types';
import type { MemberPhoneNumber, MemberProfileStore } from '~/Modules/memberProfile';
import { setPassengerInfo } from '~/Modules/rideBooking';
import { TRANSPORT_TYPE_CLASSIFICATIONS } from '~/constants/vehicles';
import type { AppThunk } from '~/Modules';
import {
  additionalPassengerFactory,
  isPassengerType,
  PASSENGER_INFO_DEFAULT,
  passengerInfoFactory,
  type PassengerInfoModel,
  type PassengerInfoProps,
  type TripType
} from '~/models';

/** Prepopulates the RBF store using data extracted from the
 * MemberProfiles slice. Any value that cannot be propulated will
 * use a default value. */
export const initializePassengerInfoThunk = (): AppThunk<PassengerInfoModel> => {
  return function (dispatch, getState) {
    const memberProfile = getState().memberProfile;

    const passengerInfo: PassengerInfoProps = PASSENGER_INFO_DEFAULT();

    passengerInfo.passengerId = memberProfile.formData.personalInfo.id;

    passengerInfo.transportType = getMemberTransportType(memberProfile);
    passengerInfo.serviceLevel = getMemberServiceLevel(memberProfile);
    passengerInfo.mobilityDevice = getMemberMobilityDevice(memberProfile);
    passengerInfo.specialCircumstances = getMemberSpecialCircumstances(memberProfile);
    passengerInfo.driverProvisions = getMemberDriverProvisions(memberProfile);

    passengerInfo.tripType = getMemberTripType(
      memberProfile,
      passengerInfo.transportType
    );

    const transportTypeSubTypes = getTransportTypeSubTypes(
      memberProfile,
      passengerInfo.tripType,
      passengerInfo.transportType
    );

    passengerInfo.subTransportType = getMemberSubTransportType(
      memberProfile,
      transportTypeSubTypes
    );

    const memberPhoneNumber = getMemberPhoneNumber(memberProfile);
    passengerInfo.phoneNumber = memberPhoneNumber?.phone_number ?? '';
    passengerInfo.phoneNumberType = memberPhoneNumber?.phone_type ?? undefined;
    passengerInfo.phoneNumberOptOutStatus = (memberPhoneNumber?.opt_out ?? 0) as Binary;

    passengerInfo.additionalPassengers = getMemberAdditionalPassengers(
      memberProfile,
      passengerInfo.transportType
    );

    const passengerInfoModel = passengerInfoFactory(passengerInfo);
    dispatch(setPassengerInfo(passengerInfoModel));

    return passengerInfoModel;
  };
};

/** Check criteria to verify that a phone number is textable. */
function phoneNumberIsTextable(phoneNumber: MemberPhoneNumber | undefined) {
  return (
    phoneNumber &&
    phoneNumber.textable === 1 &&
    phoneNumber.phone_type === 'mobile' &&
    !phoneNumber.opt_out
  );
}

/** Retrieves the ride's primary phone number from the member profile. Prioritizes
 * the primary number unless it is not textable. In that case it takes the secondary
 * number if it is mobile, textable and has not opted out. */
function getMemberPhoneNumber(
  memberProfile: MemberProfileStore
): Partial<MemberPhoneNumber> {
  const memberNumbers = memberProfile.formData.personalInfo.memberPhoneNumbers;

  const primary = memberNumbers.find(({ is_primary }) => is_primary);

  if (phoneNumberIsTextable(primary)) {
    return primary!;
  }

  if (memberProfile.formData.personalInfo.phone2) {
    const secondary = memberNumbers.find(
      p => p.phone_number.substring(2) === memberProfile.formData.personalInfo.phone2
    );

    if (phoneNumberIsTextable(secondary)) {
      return secondary!;
    }
  }

  return primary ?? ({} as Partial<MemberPhoneNumber>);
}

/** Retrieve the member driver provisions. */
function getMemberDriverProvisions(memberProfile: MemberProfileStore) {
  const memberDefaultAssistanceNeeds =
    memberProfile.formData.mobility.memberData?.member_default_assistance_needs ?? {};

  const driverProvisions: string[] = [];
  for (const prov of memberProfile.driverProvisions) {
    if (
      prov.id in memberDefaultAssistanceNeeds &&
      memberDefaultAssistanceNeeds[prov.id] === 1
    ) {
      driverProvisions.push(prov.value as string);
    }
  }

  return driverProvisions;
}

/** Retrieve the special circumstance defaults for the member. */
function getMemberSpecialCircumstances(memberProfile: MemberProfileStore) {
  const memberDefaultAssistanceNeeds =
    memberProfile.formData.mobility.memberData?.member_default_assistance_needs ?? {};

  const specialCircumstances: string[] = [];
  for (const need of memberProfile.assistanceNeeds) {
    if (
      need.id in memberDefaultAssistanceNeeds &&
      memberDefaultAssistanceNeeds[need.id] === 1
    ) {
      specialCircumstances.push(need.value as string);
    }
  }

  return specialCircumstances;
}

/** Extract the passenger's mobility device data. */
function getMemberMobilityDevice(memberProfile: MemberProfileStore) {
  const memberDefaultAssistanceNeeds =
    memberProfile.formData.mobility.memberData?.member_default_assistance_needs ?? {};

  for (const device of memberProfile.mobilityDevices) {
    if (
      device.id in memberDefaultAssistanceNeeds &&
      memberDefaultAssistanceNeeds[device.id] === 1
    ) {
      return device.value as string;
    }
  }

  // User has submitted a mobility assessment but has no mobility device
  if (memberProfile.mobilityAssessment?.submission.length) {
    return 'None';
  }

  return undefined;
}

/** Extract the default TransportType from the member's default transport type. If
 * not present, returns undefined. */
function getMemberTransportType(memberProfile: MemberProfileStore) {
  const mobilityData = memberProfile.formData.mobility;

  const vehicleRecord = mobilityData.vehicle_types.find(
    ({ id }) => id === mobilityData.memberData?.vehicle_type
  );

  return vehicleRecord?.nickName ?? undefined;
}

/** Pull the transport type sub types from a transport type. */
function getTransportTypeSubTypes(
  memberProfile: MemberProfileStore,
  tripType: TripType,
  transportType: string | undefined
) {
  if (!transportType) return [];

  return memberProfile.transportTypes[tripType]![transportType]?.subTypes ?? [];
}

/**
 * Get the member's sub transport type.
 */
function getMemberSubTransportType(
  memberProfile: MemberProfileStore,
  transportSubTypes: VehicleSubType[]
) {
  const mobilityData = memberProfile.formData.mobility;
  const subTypeId = mobilityData.memberData.vehicle_sub_type;

  return transportSubTypes.find(({ id }) => id === subTypeId)?.['value'] ?? undefined;
}

/** Extract a TripType from the member's selected default mode and/or TransportType. If not
 * present, defaults to first available key in TransportTypes. */
function getMemberTripType(
  memberProfile: MemberProfileStore,
  transportType: string | undefined
): TripType {
  if (transportType && transportType in TRANSPORT_TYPE_CLASSIFICATIONS) {
    return TRANSPORT_TYPE_CLASSIFICATIONS[transportType];
  }

  const mobilityData = memberProfile.formData.mobility;

  const defaultVal = mobilityData.member_default_mode.values.find(
    ({ id }) => id === mobilityData.memberData?.member_default_mode
  );

  return (
    (defaultVal?.value.toLowerCase() as TripType) ??
    Object.keys(memberProfile.transportTypes)[0]
  );
}

/** Extract a ServiceLevel from the member's assistance needs. Returns undefined if
 * not present. */
function getMemberServiceLevel(memberProfile: MemberProfileStore) {
  const memberDefaultAssistanceNeeds =
    memberProfile.formData.mobility.memberData?.member_default_assistance_needs ?? {};

  for (const level of memberProfile.serviceLevels) {
    if (
      level.id in memberDefaultAssistanceNeeds &&
      memberDefaultAssistanceNeeds[level.id] === 1
    ) {
      return level.value as string;
    }
  }

  return undefined;
}

/** Extract the additional passenger data from a member profile's attendantInfo
 * section. */
function getMemberAdditionalPassengers(
  memberProfile: MemberProfileStore,
  primaryPassengerTransportType: string | undefined
) {
  const additionalPassengers =
    memberProfile.formData.attendantInfo.memberData?.additionalPassengers ?? [];

  return additionalPassengers.map(passenger => {
    const type = isPassengerType(passenger.ageGroup) ? passenger.ageGroup : 'adult';

    let transportType;
    let subTransportType;

    // If the primary passenger is a Lyft or Uber ride, we restrict the additional
    // passengers to the same transport type.
    if (
      primaryPassengerTransportType === 'LFT' ||
      primaryPassengerTransportType === 'UBR'
    ) {
      transportType = primaryPassengerTransportType;
      subTransportType = undefined;
    } else {
      transportType = memberProfile.formData.mobility.vehicle_types.find(
        ({ id }) => id === passenger.mobilityType
      )?.nickName;

      subTransportType = passenger.subMobilityType ?? undefined;
    }

    return additionalPassengerFactory({
      type: type,
      isAttendant: passenger.isAttendant,
      firstName: passenger.firstName,
      lastName: passenger.lastName,
      transportType: transportType ?? undefined,
      subTransportType
    });
  });
}
