/**
 * The Ride Booking page makes use of the following Redux Modules:
 *
 * user
 * patients
 * memberProfile
 * rideBooking
 */
import React, { useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { SubHeader, Link, TOKENS, Button, useModal } from '@SRHealth/frontend-lib';
import { useAppDispatch, useAppSelector } from '~/Modules';
import PageFrame from '~/Shared/Components/PageFrame/PageFrame';
import { initializeRbfNewThunk, reset } from '~/Modules/rideBooking';
import LoadingComponent from '~/Pages/App/Components/LoadingComponent';
import Notes from './Components/Notes';
import { PassengerInfo } from './Components/PassengerInfo';
import { FundingSource } from './Components/FundingSource';
import { DateSection } from './Components/Date';
import { Rides } from './Components/Rides';
import { RecurringRides } from './Components/RecurringRides/RecurringRides';
import { TransportProvider } from './Components/TransportProvider';
import { getBackLink, getLabel } from './RideBooking.utils';
import history from '~/history';
import Divider from './Components/Rides/subcomponents/Divider';

const inlineCSS = `
  #rbf {
    scroll-behavior: smooth;
    scrollbar-width: thin;

    [data-testid^="section-"][data-testid$="-header"] {
      position: sticky;
      top: 0;
      z-index: 11;
    }

    [data-testid^="section-"][data-testid$="-content"] {
      position: relative;
    }

    [data-testid^="card"] {
      max-width: 960px;
    }
  }
`;

export type RideBookingMode = 'new' | 'edit';

interface RideBookingPropsBase {
  mode: RideBookingMode;
}

interface NewRideBookingProps extends RideBookingPropsBase {
  mode: 'new';
}

interface EditRideBookingProps extends RideBookingPropsBase {
  mode: 'edit';
  rideId: number;
}

type RideBookingURLParams = {
  id: string;
};

export const RideBooking = ({ mode }: NewRideBookingProps | EditRideBookingProps) => {
  const memberProfileStore = useAppSelector(store => store.memberProfile);
  const activeSection = useAppSelector(store => store.rideBooking.meta.activeSection);
  const isInitialized = useAppSelector(store => store.rideBooking.meta.isInitialized);

  const [_, modalActions] = useModal();
  const dispatch = useAppDispatch();
  const { id } = useParams<RideBookingURLParams>();

  const firstName = memberProfileStore.formData.personalInfo.firstName;
  const lastName = memberProfileStore.formData.personalInfo.lastName;
  const internalNotes = memberProfileStore.formData.personalInfo.internalNotes;

  const label = getLabel(mode, firstName, lastName);
  const [backLabel, backHref] = getBackLink(mode, id);

  const handleBookingCancel = () => {
    history.push('/member-profiles');
    modalActions.setIsOpen(false);
  };

  // Fetch initial RBF Data
  useEffect(() => {
    if (mode === 'new' && id) dispatch(initializeRbfNewThunk(id));

    return () => void dispatch(reset());
  }, [id]);

  return (
    <PageFrame>
      <style>{inlineCSS}</style>
      <div id="rbf" data-testid="rbf" className="flex flex-row w-full">
        {!isInitialized ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="flex flex-col flex-1 h-full">
              <SubHeader>
                <div
                  className="relative flex flex-row w-full align-center items-center justify-start"
                  style={{ minHeight: '80px', marginTop: '-12px', marginBottom: '-8px' }}
                >
                  <div className="absolute left-0">
                    <RouterLink to={backHref} className="reset-a">
                      <Link
                        id="to-member-profile"
                        href={backHref}
                        label={backLabel}
                        iconLeft="ChevronLeft"
                      />
                    </RouterLink>
                  </div>
                  <div
                    className={`flex-1 heading-xl text-center text-${TOKENS.FONT.NEUTRAL}`}
                    style={{ minHeight: '23px' }}
                  >
                    {label}
                  </div>
                  <div>
                    <Button
                      alt
                      id="default"
                      label="Cancel Booking"
                      size="md"
                      onClick={() =>
                        modalActions.set({
                          isOpen: true,
                          title: 'Cancel this booking?',
                          content: (
                            <>
                              <div className="heading-lg p-[16px]">
                                This will discard all information for this booking.
                              </div>
                              <Divider />
                              <div className="mt-[8px]">
                                <Button
                                  alt
                                  label="No, continue booking"
                                  onClick={() => modalActions.setIsOpen(false)}
                                />
                                <Button
                                  label="Yes, cancel booking"
                                  onClick={handleBookingCancel}
                                />
                              </div>
                            </>
                          ),
                          onDismiss: () => modalActions.setIsOpen(false)
                        })
                      }
                    />
                  </div>
                </div>
              </SubHeader>

              <div className="overflow-auto flex-1">
                {activeSection >= PassengerInfo.sectionIndex && <PassengerInfo />}
                {activeSection >= FundingSource.sectionIndex && <FundingSource />}
                {activeSection >= DateSection.sectionIndex && <DateSection />}
                {activeSection >= Rides.sectionIndex && <Rides />}
                {activeSection >= RecurringRides.sectionIndex && <RecurringRides />}
                {activeSection >= TransportProvider.sectionIndex && <TransportProvider />}
              </div>
            </div>
            <Notes internalNotes={internalNotes} />
          </>
        )}
      </div>
    </PageFrame>
  );
};
