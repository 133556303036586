import { createSelector } from '@reduxjs/toolkit';
import { selectActiveTreatment } from './selectActiveTreatment';
import { isArray } from '~/utilities/guards';

/**
 * Given the selected treatment and the information we have so far,
 * determines if the passenger is exempted from benefit limits for this ride.
 */
export const selectActiveBenefitCategoryIds = createSelector(
  [selectActiveTreatment],
  (activeTreatment): number[] => {
    try {
      const benefitCategoryIds = JSON.parse(activeTreatment?.benefitCategoryIds || '[]');

      if (!isArray(benefitCategoryIds)) {
        return [];
      }

      return benefitCategoryIds as number[];
    } catch (error) {
      return [];
    }
  }
);
