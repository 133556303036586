import { Alert, Card, Input } from '@SRHealth/frontend-lib';
import type { RuleErrorRecord } from '@SRHealth/frontend-lib/dist/lib/model/ModelValidationError';
import React, { useState } from 'react';
import type { FundingSourceModel } from '~/models';
import type { TreatmentRecord } from '~/Modules/patients';
import { initialized, type BlockType } from '~/Modules/rideBooking';
import type { DependentField as DependentFieldRecord } from '~/Modules/user';
import type { ComplianceRecord } from '~/types';
import ComplianceField from './ComplianceField';
import DependentField from './DependentField';
import { filterTreatments, getAlertText, getAlertType } from '../FundingSource.utils';
import { useAppDispatch } from '~/Modules';
import { FUNDING_SOURCE_TXT } from '../FundingSource.constants';

type TripQuestionsProps = {
  /** The data model for the RBF section. */
  model: FundingSourceModel;
  /** A list of treatments available to the passenger for the
   * selected sub plan. */
  treatments: TreatmentRecord[] | undefined;
  /** A list of hospital network compliance fields that the passenger
   * must fill out. */
  complianceFields?: ComplianceRecord[];
  /** A list of hospital network dependent fields that the passenger
   * must fill out. */
  dependentFields?: DependentFieldRecord;
  /** A list of custom fields that the hospital requires. */
  hospitalCustomFields: BE.HospitalCustomField[];
  /** Indicates if the passenger is blocked from ride booking. Assumes
   * that if this is true, it is because the following criteria have
   * been met:
   * * The passenger sub plan has a rides per year benefit
   * * The passenger has no remaining benefits
   * * The passenger sub plan has treatments enabled
   * * The passenger's treatment is a deducting benefit
   */
  isBlocked: boolean;
  /** Indicates the type of block. If undefined, then isBlocked must be false. */
  blockType: BlockType;
};

export default function TripQuestions({
  model,
  treatments,
  complianceFields,
  dependentFields,
  hospitalCustomFields,
  isBlocked,
  blockType
}: TripQuestionsProps) {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState('');

  /** Filter the treatments based on the input's search string. */
  const _treatments = filterTreatments(treatments, query);
  const alertText = getAlertText(blockType, !!treatments);
  const alertType = getAlertType(blockType);

  return (
    <Card id="trip-questions" label="Trip Questions" border={false}>
      <div
        className="flex flex-col justify-center items-center m-auto p-[12px] gap-[12px]"
        style={{ width: '320px' }}
      >
        {treatments && (
          <>
            <Input
              inputId={0}
              type="search-dropdown"
              name="treatmentId"
              label="Treatment Type"
              dropdownSize={3}
              options={_treatments}
              value={model?.treatmentId ?? ''}
              onSearch={(_id, q) => setQuery(q)}
              onInput={(_id, v: string) => setQuery(v)}
              onChange={(_id, v: FundingSourceModel['treatmentId']) => {
                model.treatmentId = v;
                dispatch(initialized());
              }}
              error={'treatmentId' in model.ruleErrors}
              className="w-full"
              required
            />

            {treatments.length === 0 && (
              <div style={{ paddingInline: '8px' }}>
                <Alert
                  type="warning"
                  label={FUNDING_SOURCE_TXT.SUB_PLAN_MISSING_TREATMENTS}
                />
              </div>
            )}
          </>
        )}

        {isBlocked && (
          <div style={{ paddingInline: '8px' }}>
            <Alert type={alertType} label={alertText} />
          </div>
        )}

        {complianceFields?.map(field => {
          const hasError =
            `compliance-field-${field.id}` in (model.ruleErrors?.complianceInfo ?? {});

          return (
            <ComplianceField
              key={field.id}
              field={field}
              value={model.complianceInfo[field.id]}
              error={hasError}
              onChange={(_id, v) => {
                model.complianceInfo = { ...model.complianceInfo, [field.id]: v };
              }}
            />
          );
        })}

        {dependentFields && model.dependentFields && (
          <DependentField
            field={dependentFields}
            dependentFieldValues={model.dependentFields}
            errors={(model.ruleErrors?.dependentFields ?? {}) as RuleErrorRecord}
            onChange={(id, v) => {
              model.dependentFields = { ...model.dependentFields, [id]: v };
            }}
          />
        )}

        {hospitalCustomFields.map(field => {
          const hasError =
            `${model.facilityId}-custom-field-${field.id}` in
            (model.ruleErrors?.hospitalCustomFields ?? {});

          return (
            <Input
              key={field.id}
              inputId={field.id}
              type="single"
              name={`hospitalCustomFields-${field.id}`}
              label={field.field_label}
              placeholder={field.field_label_desc}
              value={model.hospitalCustomFields[field.id]}
              error={hasError}
              onChange={(id, v: string) => {
                model.hospitalCustomFields = { ...model.hospitalCustomFields, [id]: v };
              }}
              className="w-full"
              required
            />
          );
        })}
      </div>
    </Card>
  );
}
