import { createAppAsyncThunk } from '~/Modules';
import { getSupply } from '~/services/rideBooking.service';
import { selectSupplyRequestData } from '../RideBooking.slice';

export const getProviderSupplyThunk = createAppAsyncThunk(
  'rideBooking/getProviderSupply',
  (_, { rejectWithValue, getState }) => {
    try {
      const supplyRequestData = selectSupplyRequestData(getState());

      return getSupply(supplyRequestData);
    } catch (error) {
      return rejectWithValue({ error: error.response });
    }
  }
);
