import { Icon } from '@SRHealth/frontend-lib';
import React from 'react';
import type { RideType } from '~/models';

type TripCardSummaryProps = {
  initialRideType: RideType;
  timeOne: string;
  timeTwo?: string;
};

export default function TripCardSummary({
  timeOne,
  timeTwo,
  initialRideType
}: TripCardSummaryProps) {
  return (
    <div
      className="flex flex-col"
      style={{ marginTop: '8px', padding: '8px 0', gap: '24px' }}
    >
      <div
        className="text-sm items-center"
        style={{
          display: 'inline-grid',
          gridTemplateColumns: '1fr 1fr 24px 1fr',
          columnGap: '4px',
          rowGap: '12px'
        }}
      >
        <span>Initial Ride</span>
        <span style={{ textAlign: 'right' }}>
          {initialRideType === 'arriveBy' ? 'Appointment' : 'Depart'}
        </span>
        <Icon type="Time" style={{ width: '24px', height: '24px' }} />
        <span>{timeOne}</span>

        {timeTwo && (
          <>
            <span>Return Ride</span>
            <span style={{ textAlign: 'right' }}>Depart</span>
            <Icon type="Time" style={{ width: '24px', height: '24px' }} />
            <span>{timeTwo}</span>
          </>
        )}
      </div>
    </div>
  );
}
