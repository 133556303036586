import React from 'react';
import { Icon, Rating, THEME, TOKENS } from '@SRHealth/frontend-lib';
import { type ProviderNemtProps } from '~/models';
import { convertCentsToDollars } from '~/utilities/currency';

type NemtTransportProps = ProviderNemtProps & {
  isActive: boolean;
  onClick: (NemtTransportProviderProps) => void;
};

export default function NemtTransport({
  name,
  ratings,
  rideCosts,
  isActive,
  onClick,
  isPrimary = false,
  isSecondary = false,
  chatEnabled: _chatEnabled = false
}: NemtTransportProps) {
  const borderClass = isActive
    ? `border-2 border-${TOKENS.BORDER.BLUE} bg-${TOKENS.DISPLAY.SURFACE.BLUE_LT}`
    : `border-1 border-${TOKENS.BORDER.NEUTRAL_LT}`;

  return (
    <div
      onClick={onClick}
      style={isActive ? undefined : { margin: '1px' }}
      className={
        `reset-div cursor-pointer flex flex-col rounded-[8px] py-[16px] px-[24px] gap-[4px] ` +
        `box-border relative shadow-b ${borderClass}`
      }
    >
      {isPrimary && (
        <div
          className={`absolute top-0 left-0 text-${TOKENS.ICON.ACTIVE}`}
          style={{ transform: 'translate(-50%, -30%)' }}
        >
          <Icon type="AwardOne" className="fill-current" />
        </div>
      )}

      {isSecondary && (
        <div
          className={`absolute top-0 left-0 text-${TOKENS.ICON.ACTIVE}`}
          style={{ transform: 'translate(-50%, -30%)' }}
        >
          <Icon type="AwardTwo" className="fill-current" />
        </div>
      )}

      <div
        style={{ height: '40px' }}
        className="flex py-[4px] self-stretch content-center text-base"
      >
        {name}
      </div>

      <div className="reset-div" style={{ paddingBottom: '4px' }}>
        <Rating
          value={ratings}
          className="items-start h-[20px] gap-[4px]"
          style={{ width: '116px' }}
        />
      </div>

      <div className="reset-div flex flex-row py-[4px] text-sm">
        <div className="flex-1">Ride 1:</div>
        <div className="text-right">{convertCentsToDollars(rideCosts[0])}</div>
      </div>

      {rideCosts.length > 1 && (
        <div className="reset-div flex flex-row py-[4px] text-sm">
          <div className="flex-1">Ride 2:</div>
          <div className="text-right">{convertCentsToDollars(rideCosts[1])}</div>
        </div>
      )}

      <div
        className="reset-div flex flex-row py-[4px] heading-sm"
        style={{ borderTop: `1px solid ${THEME.colors[TOKENS.BORDER.NEUTRAL_MD]}` }}
      >
        <div className="flex-1">Total:</div>
        <div className="text-right">
          {convertCentsToDollars(rideCosts.reduce((acc, cost) => acc + cost, 0))}
        </div>
      </div>
    </div>
  );
}
