/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
  Checkbox,
  Typography,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Divider,
  Box,
  Container,
  Grid,
  Button,
  FormGroup,
  FormControlLabel
} from '@material-ui/core';
import { CheckBoxOutlineBlankOutlined, CheckBox } from '@material-ui/icons';
import type { VehicleCompany } from '~/types';
import type {
  MobilityFormMemberData,
  MobilityForm,
  MemberProfileStore
} from '~/Modules/memberProfile';
import useFormStyles from '~/Shared/Components/Form/Form.theme';
import useMemberProfilesStyles from './formsTheme';
import {
  type TransportPreferencesUpdate,
  createInitialState,
  formatProfileUpdate
} from './TransportPreferences.helper';
import type { UserStore } from '~/Modules/user';

const icon = <CheckBoxOutlineBlankOutlined fontSize="small" />;
const iconChecked = <CheckBox fontSize="small" />;

export type TransportPreferencesState = {
  primary_preferred_nemts: UserStore['vehicleCompanies'][number] | null;
  secondary_preferred_nemts: UserStore['vehicleCompanies'][number] | null;
  member_default_mode: MobilityForm['member_default_mode']['values'][number] | undefined;
  member_flag: MobilityForm['member_flag']['values'][number] | undefined;
  member_ride_alone: MobilityForm['member_ride_alone']['values'][number] | undefined;
  member_manual_schedule:
    | MobilityForm['member_manual_schedule']['values'][number]
    | undefined;
  member_rideshare_preference:
    | MobilityForm['member_rideshare_preference']['values'][number]
    | undefined;
  flagged_nemts: MobilityFormMemberData['flagged_nemts'];
};

export type TransportPreferencesProps = {
  member: MemberProfileStore;
  isDisabled: boolean;
  vehicleCompanies: VehicleCompany[];
  updateMember: (params: TransportPreferencesUpdate) => void;
};

function TransportPreferences({
  member,
  isDisabled,
  updateMember,
  vehicleCompanies = []
}: TransportPreferencesProps) {
  const formClasses = useFormStyles();
  const classes = useMemberProfilesStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [state, setState] = useState<TransportPreferencesState>(
    createInitialState(member, vehicleCompanies)
  );

  const mobility = member?.formData?.mobility ?? {};

  const {
    member_default_mode: transportMode = {} as Partial<
      MobilityForm['member_default_mode']
    >,
    member_rideshare_preference: ridesharePreferences = {} as Partial<
      MobilityForm['member_rideshare_preference']
    >
  } = mobility;

  transportMode.values ??= [];
  ridesharePreferences.values ??= [];

  // Prevent users from selecting an NEMT that is flagged or a secondary preference
  const primaryNemtOptions = vehicleCompanies.filter(
    c => state.secondary_preferred_nemts !== c && !state.flagged_nemts.includes(c)
  );

  // Prevent users from selecting an NEMT that is flagged or a primary preference
  const secondaryNemtOptions = vehicleCompanies.filter(
    c => state.primary_preferred_nemts !== c && !state.flagged_nemts.includes(c)
  );

  // Prevent users from selecting an NEMT that is a primary or secondary preference
  const flaggedNemtOptions = vehicleCompanies.filter(
    c => state.primary_preferred_nemts !== c && state.secondary_preferred_nemts !== c
  );

  // Default state values are controlled in createInitialState
  useEffect(() => setState(createInitialState(member, vehicleCompanies)), [member]);

  /**
   * Cancel Editing event handler. Sets form isEditing to
   * false and resets form state to initial state.
   * @returns {void}
   */
  function handleCancel() {
    setIsEditing(false);
    setState(createInitialState(member));
  }

  /**
   * Edit button click event handler. Sets isEditing
   * to true.
   * @returns {void}
   */
  function handleEdit() {
    if (isDisabled) return;

    setIsEditing(true);
  }

  /**
   * Submit button event handler. Sets isEditing to
   * false and calls the updateMember prop method.
   * @returns {void}
   */
  function handleSubmit() {
    if (isDisabled) return;

    const update = formatProfileUpdate(state);
    updateMember(update);
    setIsEditing(false);
  }

  /**
   * Handles updates to the form fields.
   * @param {string} key
   * @param {mixed} val
   * @returns {void}
   */
  function handleChange<K extends keyof TransportPreferencesState>(
    key: K,
    val: TransportPreferencesState[K]
  ) {
    setState({ ...state!, [key]: val });
  }

  /**
   * Handles updates specifically for checkbox input fields
   * @param {React.ChangeEvent<HTMLInputElement>} e
   * @returns {void}
   */
  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    const key = e.target.name as keyof TransportPreferencesState;
    const checkStr = e.target.checked ? 'Yes' : 'No';

    const options = member.formData.mobility[key]?.values ?? [];

    return options[0].value === checkStr
      ? handleChange(key, options[0])
      : handleChange(key, options[1]);
  }

  return (
    <Container
      id="member-mobility-assessment-transport-preferences"
      className={`parentTab mma ${classes.parentTab}`}
    >
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Box flex={1}>
          <Typography variant="h6">Transport Preferences</Typography>
        </Box>

        {!isDisabled && (
          <>
            <CancelButton
              isEditing={isEditing}
              className={classes.cancelButton}
              onClick={handleCancel}
            />
            <SubmitButton
              isEditing={isEditing}
              className={classes.submitButton}
              onEdit={handleEdit}
              onSubmit={handleSubmit}
            />
          </>
        )}
      </Grid>

      <Divider className={formClasses.divider} />

      <form className={formClasses.root} name={'transport-preferences'}>
        <Grid container item spacing={2}>
          <Grid container item direction="column" xs={4}>
            <fieldset className={formClasses.fieldset} style={{ height: 'unset' }}>
              <legend className={formClasses.label}>Transport Mode</legend>
              <Divider className={formClasses.divider} />
              <FormControl
                variant="outlined"
                disabled={!isEditing}
                className={formClasses.select}
              >
                <Select
                  value={state.member_default_mode}
                  data-testid="select-member_default_mode"
                >
                  {transportMode.values.map((opt, i) => (
                    <MenuItem
                      key={i}
                      // @ts-ignore MenuItem incorrectly refuses an object as a valid value
                      value={opt}
                      onClick={() => handleChange('member_default_mode', opt)}
                    >
                      {opt.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </fieldset>

            <fieldset className={formClasses.fieldset} style={{ height: 'unset' }}>
              <legend className={formClasses.label}>Rideshare Preference</legend>
              <Divider className={formClasses.divider} />
              <FormControl
                variant="outlined"
                disabled={!isEditing || !ridesharePreferences.values.length}
                className={formClasses.select}
              >
                <Select
                  data-testid="select-member_rideshare_preference"
                  value={state.member_rideshare_preference || {}}
                  displayEmpty={true}
                >
                  {ridesharePreferences.values.map((opt, i) => (
                    <MenuItem
                      key={i}
                      // @ts-ignore MenuItem incorrectly refuses an object as a valid value
                      value={opt}
                      onClick={() => handleChange('member_rideshare_preference', opt)}
                    >
                      {opt.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </fieldset>
          </Grid>

          <Grid container item direction="column" xs={4}>
            <fieldset className={formClasses.fieldset} style={{ height: 'unset' }}>
              <legend className={formClasses.label}>NEMT Preference</legend>
              <Divider className={formClasses.divider} />
              <FormControl
                variant="outlined"
                disabled={!isEditing}
                className={formClasses.select}
              >
                <Autocomplete
                  size="small"
                  disabled={!isEditing}
                  data-testid="autocomplete-primary_preferred_nemts"
                  options={primaryNemtOptions}
                  value={state.primary_preferred_nemts}
                  getOptionLabel={({ companyName }) => companyName}
                  onChange={(_e: never, value) =>
                    handleChange('primary_preferred_nemts', value)
                  }
                  renderInput={params => <TextField {...params} variant="outlined" />}
                />
              </FormControl>
            </fieldset>

            <fieldset className={formClasses.fieldset} style={{ height: 'unset' }}>
              <legend className={formClasses.label}>NEMT Secondary Preference</legend>
              <Divider className={formClasses.divider} />
              <FormControl
                variant="outlined"
                disabled={!isEditing}
                className={formClasses.select}
              >
                <Autocomplete
                  size="small"
                  disabled={!isEditing}
                  data-testid="autocomplete-secondary_preferred_nemts"
                  options={secondaryNemtOptions}
                  value={state.secondary_preferred_nemts}
                  getOptionLabel={({ companyName }) => companyName}
                  onChange={(_e: never, value: VehicleCompany) =>
                    handleChange('secondary_preferred_nemts', value)
                  }
                  renderInput={params => <TextField {...params} variant="outlined" />}
                />
              </FormControl>
            </fieldset>

            <fieldset className={formClasses.fieldset} style={{ height: 'unset' }}>
              <legend className={formClasses.label}>Flagged NEMTs (do not book)</legend>
              <Divider className={formClasses.divider} />
              <FormControl variant="outlined" className={formClasses.select}>
                <Autocomplete
                  size="small"
                  multiple
                  disableCloseOnSelect
                  limitTags={2}
                  disabled={!isEditing}
                  data-testid="autocomplete-flagged_nemts"
                  getOptionLabel={({ companyName }) => companyName}
                  options={flaggedNemtOptions}
                  value={state.flagged_nemts}
                  onChange={(_e: never, value) => handleChange('flagged_nemts', value)}
                  renderInput={params => <TextField {...params} variant="outlined" />}
                  renderOption={(opt, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        color="primary"
                        checkedIcon={iconChecked}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {opt.companyName}
                    </>
                  )}
                />
              </FormControl>
            </fieldset>
          </Grid>

          <Grid container item direction="column" xs={4}>
            <fieldset className={formClasses.fieldset}>
              <legend className={formClasses.label}>Special Exceptions</legend>
              <Divider className={formClasses.divider} />
              <FormControl variant="outlined" disabled={!isEditing}>
                <FormGroup className={formClasses.checkbox}>
                  <FormControlLabel
                    label="Manual Schedule"
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        onChange={handleCheck}
                        name="member_manual_schedule"
                        data-testid="checkbox-member_manual_schedule"
                        checked={state.member_manual_schedule?.value === 'Yes'}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Ride Alone"
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        onChange={handleCheck}
                        name="member_ride_alone"
                        data-testid="checkbox-member_ride_alone"
                        checked={state.member_ride_alone?.value === 'Yes'}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Flagged Member (for members with issues)"
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        name="member_flag"
                        onChange={handleCheck}
                        data-testid="checkbox-member_flag"
                        checked={state.member_flag?.value === 'Yes'}
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            </fieldset>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

type SubmitButtonProps = {
  isEditing: boolean;
  isNew?: boolean;
  className?: string;
  onSubmit: () => void;
  onEdit: () => void;
};

function SubmitButton({
  isEditing,
  className,
  onSubmit,
  onEdit,
  isNew = false
}: SubmitButtonProps) {
  let label = isNew ? 'Assess' : 'Edit';
  let onClick = onEdit;

  if (isEditing) {
    label = 'Submit';
    onClick = onSubmit;
  }

  return (
    <Button className={className} onClick={onClick}>
      {label}
    </Button>
  );
}

type CancelButtonProps = {
  isEditing: boolean;
  className?: string;
  onClick?: () => void;
};

function CancelButton({ isEditing, className, onClick }: CancelButtonProps) {
  return isEditing ? (
    <Button className={className} onClick={onClick}>
      Cancel
    </Button>
  ) : null;
}

export default TransportPreferences;
