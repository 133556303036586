import React, { useMemo } from 'react';
import { useAppSelector } from '~/Modules';
import type { PublicCardType } from '~/types';

type PublicCardOption<V extends string | number> = {
  value: V;
  label: JSX.Element;
};

export type PublicCardHookProps = [
  PublicCardOption<string>[],
  PublicCardOption<number>[]
];

const quantityOptions = Array.from(new Array(10), (_, i) => ({
  value: i + 1,
  label: (
    <span>
      <span className="font-bold">{`${i + 1}`}</span>
    </span>
  )
}));

function createCardNameOptions(cardTypes?: PublicCardType[]): PublicCardOption<string>[] {
  if (!cardTypes) return [];

  return cardTypes.map(type => ({
    value: type.name,
    label: (
      <span>
        <span className="font-bold">{type.name}</span>
      </span>
    )
  }));
}

export default function usePublicCard(): PublicCardHookProps {
  const cardTypes = useAppSelector(s => s?.user?.publicCardData?.types);

  const cardNameOptions = useMemo(() => createCardNameOptions(cardTypes), [cardTypes]);

  return [cardNameOptions, quantityOptions];
}
