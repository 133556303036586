import Benefits from './FormTemplates/Benefits/Benefits';
import CustomFields from './FormTemplates/CustomFields/CustomFields';
import General from './FormTemplates/General/General';
import Notes from './FormTemplates/Notes/Notes';
import Uploads from './FormTemplates/Uploads/Uploads';
import MemberAudit from './FormTemplates/MemberAudit/MemberAudit';
import type { MemberProfileForm } from './MemberProfiles';
import type { MobilityFormMemberData, MobilityForm } from '~/Modules/memberProfile';

/**
 * Converts the Form string to it's component function
 * @param {string} page
 * @returns {Function}
 */
export function resolveMemberProfileForm(page: MemberProfileForm) {
  switch (page) {
    case 'general':
      return General;

    case 'benefits':
      return Benefits;

    case 'custom-fields':
      return CustomFields;

    case 'notes':
      return Notes;

    case 'uploads':
      return Uploads;

    case 'audit-log':
      return MemberAudit;
  }
}

export type NavOption = {
  label: string;
  dataTip: string;
  parentForm: string;
  childForm?: string;
  route: string;
};

/**
 * Retrieve the navigation options to display as top-level tabs
 * @param {string} selectedId
 * @param {string} urlParams
 * @returns {object}
 */
export function getNavOptions(selectedId: string, urlParams: string) {
  urlParams &&= `?${urlParams}`;

  const options: NavOption[] = [
    {
      label: 'General',
      dataTip: 'General',
      parentForm: 'general',
      route: `/member-profiles/${selectedId}/general${urlParams}`
    },
    {
      label: 'Benefits',
      dataTip: 'Benefits',
      parentForm: 'benefits',
      route: `/member-profiles/${selectedId}/benefits${urlParams}`
    },
    {
      label: 'Custom Fields',
      dataTip: 'Custom Fields',
      parentForm: 'custom-fields',
      childForm: 'freeform',
      route: `/member-profiles/${selectedId}/custom-fields${urlParams}`
    },
    {
      label: 'Notes',
      dataTip: 'Notes',
      parentForm: 'notes',
      route: `/member-profiles/${selectedId}/notes${urlParams}`
    },
    {
      label: 'Uploads',
      dataTip: 'Uploads',
      parentForm: 'uploads',
      route: `/member-profiles/${selectedId}/uploads${urlParams}`
    },
    {
      label: 'Audit Log',
      dataTip: 'Audit Log',
      parentForm: 'audit-log',
      route: `/member-profiles/${selectedId}/audit-log${urlParams}`
    }
  ];

  return options;
}

/**
 * Utility function to extract current value and possible
 * options from a FormDropdown data structure
 * @param {key} field
 * @param {MobilityFormMemberData} mobilityData
 * @returns {object}
 */
export function getSelectedDropdownValue(
  field: keyof MobilityFormMemberData,
  mobilityData: Partial<MobilityForm>
) {
  if (!(field in mobilityData)) return undefined;

  const currentVal = mobilityData?.memberData?.[field];
  const options = mobilityData?.[field]?.values ?? [];

  if (!currentVal || !Array.isArray(options) || !options.length) return;

  return options.find(opt => opt.id === currentVal);
}

/**
 * Returns a new object with the initial state for the MemberProfiles component.
 * Avoids the need to worry about cloning by returning a new object every time.
 */
export const initialMemberProfilesState = () =>
  ({
    parentForm: 'general',
    childForm: '',
    selectedId: '',
    showLoading: false,
    searchQuery: '',
    fromQuery: '',
    isEventFired: false,
    page: 1,
    enableRedirect: true,
    urlParams: new URLSearchParams(),
    editButton: undefined,
    errorMsg: undefined,
    successMsg: undefined,
    handleSelectionCallback: undefined
  }) as const;
